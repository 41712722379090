import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";
import { handleResetIframe } from "../../../actions/headerMenu/headerMenu";
import { useCookies } from "react-cookie";
const ApplicationPreviewMasterlink = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [src, setSrc] = useState("");
  const [loading, setLoading] = useState(true);
  const [cookies] = useCookies(["token"]);
  const myCookieValue = cookies.token;
  useEffect(() => {
    window.addEventListener("message", handleIframeTask);

    window.onpageshow = () => {
      dispatch(handleResetIframe(false));
    };
    const iframe = document.querySelector("iframe");
    iframe.contentWindow.postMessage("Show", "*");
  }, []);

  const websiteLink = window.location.origin;
  useEffect(() => {
    if (location.state) {
      if (location.state.customerId) {
        const src = `https://gemmasterlink.brainvire.net/#/ALLDepartments/${
          loginDetials.responseData.dealerId
        }/create-new-password/${location.state.customerId}?token=${
          location.state.token
        }&emailDealerId=${location.state.dealerId}&vendorStatusId=${
          loginDetials?.responseData?.vendorStatusID || 0
        }`;
        setSrc(src);
      } else if (location.state.successfullverification) {
        const src = `https://gemmasterlink.brainvire.net/#/ALLDepartments/${
          loginDetials.responseData.dealerId
        }/successfullverification?dealerCompany=${
          loginDetials.responseData.dealerCompany
        }&vendorStatusId=${loginDetials?.responseData?.vendorStatusID || 0}`;
        setSrc(src);
      } else if (location.state.customerLogin) {
        const src = `https://gemmasterlink.brainvire.net/#/ALLDepartments/${
          loginDetials.responseData.dealerId
        }/login?vendorStatusId=${
          loginDetials?.responseData?.vendorStatusID || 0
        }`;
        setSrc(src);
      } else if (location.state.productId) {
        const src = `https://gemmasterlink.brainvire.net/#/ALLDepartments/${
          loginDetials.responseData.dealerId
        }/ProductDetails/${location.state.productId}?emailDealerId=${
          location.state.emailDealerId
        }&vendorStatusId=${loginDetials?.responseData?.vendorStatusID || 0}`;
        setSrc(src);
      } else {
        const src = `https://gemmasterlink.brainvire.net/#/ALLDepartments/${
          loginDetials.responseData.dealerId
        }?BaseUrl=${websiteLink}&gemfindLoggedInUserName=${
          loginDetials.responseData.userName
        }&vendorStatusId=${loginDetials?.responseData?.vendorStatusID || 0}`;
        setSrc(src);
      }
    } else {
      if (window.location.href.indexOf("viewCatalog") > -1) {
        window.open(
          `https://gemmasterlink.brainvire.net/#/ALLDepartments/${
            loginDetials.responseData.dealerId
          }?BaseUrl=${websiteLink}&gemfindLoggedInUserName=${
            loginDetials.responseData.userName
          }&vendorStatusId=${
            loginDetials?.responseData?.vendorStatusID || 0
          }&clientSecret=${myCookieValue}`,
          "__blank"
        );
      } else {
        const src = `https://gemmasterlink.brainvire.net/#/ALLDepartments/${
          loginDetials.responseData.dealerId
        }?BaseUrl=${websiteLink}&gemfindLoggedInUserName=${
          loginDetials.responseData.userName
        }&vendorStatusId=${loginDetials?.responseData?.vendorStatusID || 0}
        &clientSecret=${myCookieValue}`;
        setSrc(src);
      }

      //  Mgr Masterlink

      // if (window.location.href.indexOf("viewCatalog") > -1) {
      //   window.open(
      //     `https://jc2-mlink.mgr.brainvire.net/#/ALLDepartments/${loginDetials.responseData.dealerId}?BaseUrl=${websiteLink}&gemfindLoggedInUserName=${loginDetials.responseData.userName}`,
      //     "__blank"
      //   );
      // } else {
      //   const src = `https://jc2-mlink.mgr.brainvire.net/#/ALLDepartments/${loginDetials.responseData.dealerId}?BaseUrl=${websiteLink}&gemfindLoggedInUserName=${loginDetials.responseData.userName}`;
      //   setSrc(src);
      // }
    }
  }, []);
  const handleIframeTask = (e) => {
    if (e.data === "submitted form") {
      document.getElementsByClassName("content__area")[0].scroll(0, 0);
    }
  };
  const hideSpinner = () => {
    setLoading(false);
  };

  useEffect(() => {
    const handleMessage = (event) => {
      // Check the origin of the message (replace with the actual origin of the Angular app)
      if (event.origin !== "https://gemmasterlink.brainvire.net") {
        return;
      }

      const data = event.data;

      // Handle the received message
      if (data.action === "scrollToTop") {
        // Scroll to the top when the 'scrollToTop' message is received
        window.scrollTo(0, 0);
      }
    };

    // Add the event listener when the component mounts
    window.addEventListener("message", handleMessage, false);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []); // Empty dependency array ensures that the effect runs only once during component mount

  return (
    <React.Fragment>
      {/* <h4 className="mr-3">Application Preview</h4> */}
      <Spin spinning={loading}>
        <iframe
          id="masterlinkApplicationPreview"
          onLoad={() => hideSpinner()}
          width="100%"
          className="application__preview"
          //height="1000px"
          src={src}
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-modals"
        ></iframe>
      </Spin>
    </React.Fragment>
  );
};

export default ApplicationPreviewMasterlink;
