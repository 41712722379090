import React, { useEffect, useState } from "react";
import { Select, Button, Modal, Spin } from "antd";
import ResizeLogo from "../../../assets/images/ResizeLogo.jfif";
import OwlCarousel from "react-owl-carousel";
import AdminToolsDataService from "../../../services/admin-tools.service";
import { useDispatch, useSelector } from "react-redux";
import LoginService from "../../../services/login.service";
import { NotificationManager } from "react-notifications";
import { BlobToDownloadFile } from "../../../helper/commanFunction.js";
import { useTranslation } from "react-i18next";
import {
  setNewLoginData,
  setAdminLoginData,
  setCurrency,
  setCurrencySign,
} from "../../../actions/login/login";
import {
  setImpersonateHeaderMenuData,
  setAdminHeaderMenuData,
  setOpenKeyHeaderMenuData,
  setSelectSideMenu,
  setSelectedLanguage,
} from "../../../actions/headerMenu/headerMenu";
import { useNavigate } from "react-router-dom";
import { setChecklinkHeaderMenuData } from "../../../actions/headerMenu/headerMenu";
import LoginDataService from "../../../services/login.service";
import adminManageLanguageService from "../../../services/admin-manageLanguage.service";
import {
  setALLfilterValuesubmit,
  setFilterValuesubmit,
} from "../../../actions/manageCollection/manageCollection";
import NoImage from "../../../assets/images/No_Image.jpg";
import { useCookies } from "react-cookie";

const { Option } = Select;

const Administrationtools = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const initialState = {
    clientTypeList: [
      {
        id: 1,
        value: "",
        label: t("All"),
      },
      {
        id: 2,
        value: "vendordiamond",
        label: t("Diamond Dealers"),
      },
      {
        id: 3,
        value: "retailer",
        label: t("Retailers"),
      },
      {
        id: 4,
        value: "vendor",
        label: t("Vendors"),
      },
    ],
    clientType: "",

    clientName: null,
    clientToolsData: {},
    userList: [],
    resetPasswordModal: false,
    viewProfileModal: false,
    viewProfileData: {},
    resetPasswordData: {
      dealerID: 0,
      name: "",
      id: "",
      userName: "",
      passwordHash: "",
      email: "",
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    resetPasswordError: {},
  };
  const [state, setState] = useState(initialState);
  const [viewProfileModal, setViewProfileModal] = useState(
    initialState.viewProfileModal
  );
  const [Data, setData] = useState([]);
  const [imageNameData, setImageNameData] = useState([]);
  const [resetPasswordModal, setResetPasswordModal] = useState(
    initialState.resetPasswordModal
  );
  const [clientNameList, setClientNameList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [loginDetails, setLoginDetails] = useState({});
  const [UserlistLoading, setUserlistLoading] = useState(false);
  const [impersonateLoading, setImpersonateLoading] = useState(false);
  const loginDetials = useSelector((state) => state.loginReducer);

  const AdminDetails = useSelector(
    (state) => state.headerMenuReducer.AdminMenuDetails
  );
  const ManageCollectionDetails = useSelector(
    (state) => state.manageCollectionReducer
  );
  const [viewProfileModalNoData, setViewProfileModalNoData] = useState(false);
  const [DealerId, setDealerId] = useState(null);
  const [mainSliderLoading, setMainSliderLoading] = useState(false);
  const [langcookies, setLangcookies] = useCookies(["lngCookie"]);
  const expiryTime = new Date();
  expiryTime.setTime(expiryTime.getTime() + 3 * 60 * 60 * 1000); ///3 hours expiry time
  const domainSite = extractDomain();
  function extractDomain() {
    const url = window.location.href;
    const domainMatch = url.match(/:\/\/(www[0-9]?\.)?([^/:]+)/i);

    if (domainMatch && domainMatch.length > 2) {
      const fullDomain = domainMatch[2];
      const parts = fullDomain.split(".");
      if (parts.length >= 2) {
        const domain = "." + parts.slice(-2).join(".");
        return domain;
      }
    }

    return null;
  }
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setLoginDetails(loginDetials.loginDetials.responseData);
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetUsersList(
            loginDetials.loginDetials.responseData.dealerId,
            ""
          );
        }
      }
    }
  }, []);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      clientTypeList: [
        {
          id: 1,
          value: "",
          label: t("All"),
        },
        {
          id: 2,
          value: "vendordiamond",
          label: t("Diamond Dealers"),
        },
        {
          id: 3,
          value: "retailer",
          label: t("Retailers"),
        },
        {
          id: 4,
          value: "vendor",
          label: t("Vendors"),
        },
      ],
    }));
  }, [t]);

  // show Password
  const handlePassShowHide = () => {
    setShowPassword(!showPassword);
  };
  const handlePassShowHide1 = () => {
    setShowPassword1(!showPassword1);
  };

  const handleGetUsersList = (id, type) => {
    let inputData = {
      clientType: type || "",
      dealerId: id,
    };
    setUserlistLoading(true);
    try {
      AdminToolsDataService.GetUsersList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;

          if (message === "Success") {
            setClientNameList(responseData);
            setUserlistLoading(false);
          } else {
            setClientNameList([]);
            setUserlistLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setUserlistLoading(false);
        });
    } catch (error) {
      console.log(error);
      setUserlistLoading(false);
    }
  };
  const handleGetUsersListByDealerId = async () => {
    let inputData = {
      dealerID: state.clientName,
    };
    try {
      await AdminToolsDataService.GetUsersListByDealerId(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              var tempState = state;
              tempState["clientToolsData"] = responseData[0];

              setState((prevState) => ({
                ...prevState,
                ...tempState,
              }));
            }
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleImporsonateToJewelCloud = () => {
    let inputData = {
      dealerID: state.clientName,
    };
    try {
      AdminToolsDataService.ImporsonateToJewelCloud(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          setLoading(false);
          if (message === "Success") {
            var tempState = state;

            tempState["userList"] = responseData;
            tempState["resetPasswordData"] = responseData
              ? responseData[0]
              : null;

            tempState["resetPasswordData"]["newPassword"] = "";
            tempState["resetPasswordData"]["confirmPassword"] = "";
            setState((prevState) => ({
              ...prevState,
              ...tempState,
            }));
          } else {
            tempState["userList"] = null;
            tempState["resetPasswordData"] = null;
            setState((prevState) => ({
              ...prevState,
              ...tempState,
            }));
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  function onChange(e, name) {
    let tempState = state;

    if (e) {
      if (e.target) {
        if (name === "newPassword" || name == "confirmPassword") {
          tempState["resetPasswordData"][name] = e.target.value;
          tempState["resetPasswordError"][name] = "";
        } else {
          tempState[name] = e.target.value;
        }
      } else {
        if (name == "userId") {
          tempState["resetPasswordData"] = tempState.userList.filter(
            (x) => x.id == e
          )[0];
        } else if (name == "clientType") {
          tempState[name] = e;
          tempState["clientToolsData"] = null;
          tempState["clientName"] = null;
          setClientNameList([]);
        } else {
          tempState[name] = e;
        }
      }
    } else {
      tempState[name] = e;
      tempState["clientToolsData"] = null;
      tempState["clientName"] = null;
    }
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    if (name == "clientType") {
      handleGetUsersList(DealerId, e);
    }
    if (name == "clientName") {
      setLoading(true);
      handleGetUsersListByDealerId();
      handleImporsonateToJewelCloud();
    }
    localStorage.setItem("AdminDetails", JSON.stringify(loginDetails));
    localStorage.setItem("FilterValue", ManageCollectionDetails.filterValue);
    localStorage.setItem(
      "AllFilterValue",
      ManageCollectionDetails.allFilterValue
    );
  }
  const handleImpersonateJewelCloud = () => {
    handleIdentifyLogin();
  };

  let navigate = useNavigate();

  const handleIdentifyLogin = async () => {
    let inputData = {
      userName: state.resetPasswordData.userName,
      password: state.resetPasswordData.newPassword,
      userId: state.resetPasswordData.id,
      userEmail: state.resetPasswordData.email,
      isImporsonate: true,
    };
    setImpersonateLoading(true);
    AdminToolsDataService.AdminToolsLogin(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        setLoading(false);
        if (message == "True") {
          localStorage.setItem("AdminDetails", JSON.stringify(loginDetails));
          var data = { responseData };
          localStorage.setItem(
            "HeaderMenuDetails",
            JSON.stringify(AdminDetails)
          );

          dispatch(setNewLoginData(data));
          handleGetFirstTierMenu(state.resetPasswordData.dealerID);
          handleAddLoginDetails(state.resetPasswordData.userName);
          handleCurrency(state.resetPasswordData.dealerID);

          handleLanguage(state.resetPasswordData.dealerID);
          setImpersonateLoading(false);
        } else {
          NotificationManager.error(t("Impersonating Data Failed"));
          setImpersonateLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setImpersonateLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleAddLoginDetails = (username) => {
    try {
      let inputData = {
        userName: username,
      };
      AdminToolsDataService.AddLoginDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleCurrency = (id) => {
    let inputData = {
      delarId: id,
    };
    LoginDataService.currency(inputData).then((response) => {
      if (response.data.message === "Success") {
        let responseData = response.data.responseData;
        dispatch(setCurrency(responseData.currencyType));
        dispatch(setCurrencySign(responseData.currencysign));
      }
    });
  };
  const languageCodes = {
    Spanish: "es",
    English: "en",
    Arabic: "ar",
  };
  const handleLanguage = (id) => {
    let inputData = {
      delarId: id,
    };
    adminManageLanguageService
      .GetSelectlanguageForDelar(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            const getLangValue = languageCodes[responseData.language] || null;
            dispatch(setCurrency(responseData.currencyType));
            dispatch(setSelectedLanguage(getLangValue));
            setLangcookies("lngCookie", getLangValue, {
              path: "/",
              expires: new Date(Date.now() + 86400000),
              domain: domainSite,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetFirstTierMenu = (id) => {
    let inputData = {
      dealerId: id.toString(),
    };
    AdminToolsDataService.GetAllMenuDtlsByPermission(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          dispatch(setFilterValuesubmit("All"));
          dispatch(setALLfilterValuesubmit(""));
          dispatch(setImpersonateHeaderMenuData(responseData));
          dispatch(setChecklinkHeaderMenuData("Dashboard"));
          var url = window.location.origin + "/dashboard";
          window.open(url, "_blank");
        } else {
          NotificationManager.error(t("Impersonating Header Menu Failed"));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleReturnAdmin = () => {
    var responseData = JSON.parse(localStorage.getItem("AdminDetails"));
    var headerDetails = JSON.parse(localStorage.getItem("HeaderMenuDetails"));
    var filterValue = localStorage.getItem("FilterValue");
    var allFilterValue = localStorage.getItem("AllFilterValue");
    dispatch(setFilterValuesubmit(filterValue));
    dispatch(setALLfilterValuesubmit(allFilterValue));
    var data = { responseData };
    handleCurrency(responseData.dealerId);
    handleLanguage(responseData.dealerId);

    dispatch(setAdminLoginData(data));
    dispatch(setAdminHeaderMenuData(headerDetails));
    dispatch(setChecklinkHeaderMenuData("Admin"));
    handleGetUsersList(DealerId, "");
    setState((prevState) => ({
      ...prevState,
      ...initialState,
    }));
  };
  const handleAdminResetPassword = () => {
    if (handleResetPasswordValidation()) {
      let inputData = {
        userName: state.resetPasswordData.userName,
        password: state.resetPasswordData.newPassword,
      };
      LoginService.AdminResetPassword(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message == "True") {
            NotificationManager.success(
              t("Password Has Been Reset Successfully")
            );
            handleResetModalCancel();
          } else {
            NotificationManager.error(t("Password Not Reset."));
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleResetPasswordValidation = () => {
    var error = state.resetPasswordError;
    var resetData = state.resetPasswordData;
    var isValid = true;

    const strongRegex =
      /^(?=.*[a-z])(?!.* )(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,15})/;
    if (!resetData.newPassword) {
      error["newPassword"] = t("Please Enter Password.");
      isValid = false;
    } else if (!resetData.newPassword.match(strongRegex)) {
      isValid = false;
      error["newPassword"] = t(
        "Password Must contain Minimum 6 characters, Maximum 15 characters, at least one uppercase letter, one number and one special character and should not contain blank spaces"
      );
    } else if (resetData.newPassword.length > 15) {
      isValid = false;
      error["newPassword"] = t(
        "Password Must contain Minimum 6 characters, Maximum 15 characters, at least one uppercase letter, one number and one special character and should not contain blank spaces"
      );
    } else {
      error["newPassword"] = "";
    }
    if (!resetData.confirmPassword) {
      error["confirmPassword"] = t("Please Enter Confirm Password.");
      isValid = false;
    } else {
      error["confirmPassword"] = "";
    }
    if (resetData.confirmPassword && resetData.newPassword) {
      if (resetData.confirmPassword !== resetData.newPassword) {
        error["confirmPassword"] = t(
          "Password And Confirm Password Do Not Match."
        );
        isValid = false;
      } else {
      }
    }
    setState((prevState) => ({
      ...prevState,
      resetPasswordError: error,
    }));
    return isValid;
  };

  const handleResetModalCancel = () => {
    var tempState = state;
    tempState["resetPasswordData"]["newPassword"] = "";
    tempState["resetPasswordData"]["confirmPassword"] = "";
    tempState["resetPasswordError"] = {};

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    setResetPasswordModal(false);
    setShowPassword(false);
    setShowPassword1(false);
  };
  const handleGetAdminDealerProfile = () => {
    let inputData = {
      dealerId: parseInt(state.clientName),
      vendorId: parseInt(state.clientName),
      dealerType: "",
    };
    setMainSliderLoading(true);

    AdminToolsDataService.GetAdminDealerProfile(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setData(responseData);
          setImageNameData(responseData.adminDealerRetailerStoreImages);
          setViewProfileModal(true);
          setMainSliderLoading(false);
        } else {
          setData([]);
          setViewProfileModalNoData(true);
          setMainSliderLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setMainSliderLoading(false);
      })
      .finally(() => {
        setMainSliderLoading(false);
      });
  };
  const handleAdminToolsDownloadInventoryFile = () => {
    setLoading(true);
    let inputData = {
      dealerId: state.resetPasswordData.dealerID,
      dealerCompany: state.clientToolsData.dealerCompany,
    };
    AdminToolsDataService.AdminToolsDownloadInventoryFile(inputData)
      .then((response) => {
        var fileName =
          state.clientToolsData.dealerCompany +
          " ( " +
          state.clientToolsData.dealerId +
          " )" +
          "_" +
          new Date().toJSON().slice(0, 10).replace(/-/g, "") +
          ".xlsx";

        BlobToDownloadFile(response.data, fileName);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  /*Owl carousel options starts*/
  const options = {
    margin: 20,
    loop: true,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };

  const openInNewTab = (websiteAddress) => {
    var url = websiteAddress;
    if (websiteAddress.includes("https")) {
      window.open(websiteAddress, "_blank");
    } else if (websiteAddress.includes("http")) {
      window.open(websiteAddress, "_blank");
    } else {
      window.open("https://" + websiteAddress, "_blank");
    }
  };
  /*Owl carousel options ends*/

  // Redirection
  const handleRedirection = () => {
    navigate("/vendorProfile", {
      state: {
        key: "Inventory",
        id: parseInt(state.clientName),
        checkTypeOfInventoryPage: "manageInventoryPage",
      },
    });
    dispatch(setChecklinkHeaderMenuData("My Catalog"));
    var MenuData = [`${"Data"}`, `${"DataConnection"}`];
    dispatch(setOpenKeyHeaderMenuData(MenuData));
  };

  const handleRedirectionLocation = () => {
    navigate("/companyLocation", {
      state: {
        id: parseInt(state.clientName),
      },
    });
    dispatch(setChecklinkHeaderMenuData("Profile"));
    var MenuData = [`${"company"}`];
    dispatch(setOpenKeyHeaderMenuData(MenuData));
    dispatch(setSelectSideMenu(["companyLocation"]));
  };

  const handleRedirectionBrands = () => {
    navigate("/manageBrands", {
      state: {
        id: parseInt(state.clientName),
      },
    });
    dispatch(setChecklinkHeaderMenuData("Profile"));
    var MenuData = [`${"company"}`];
    dispatch(setOpenKeyHeaderMenuData(MenuData));
    dispatch(setSelectSideMenu(["brand"]));
  };
  return (
    <React.Fragment>
      <div className="admintools__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="workarea__heading mt-0 mb-3">
                {t("Administration Tools")}
              </h4>
            </div>
            <div className="col-md-12">
              <span> {t("Client Tools")} </span>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="input__block">
                <label>
                  {" "}
                  {t("Select Client Type")} <span>*</span>
                </label>
                <Select
                  showSearch
                  className="border__grey"
                  placeholder={t("Select Client Type")}
                  optionFilterProp="children"
                  value={state.clientType}
                  onChange={(e) => {
                    onChange(e, "clientType");
                  }}
                >
                  {state.clientTypeList.map((item, i) => {
                    return <Option value={item.value}>{item.label}</Option>;
                  })}
                </Select>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="input__block">
                <label>
                  {" "}
                  {t("Client Name")} <span>*</span>
                </label>
                <Select
                  showSearch
                  className="border__grey"
                  placeholder={t("Select Client Name")}
                  optionFilterProp="children"
                  value={state.clientName}
                  onChange={(e) => {
                    onChange(e, "clientName");
                  }}
                  loading={UserlistLoading}
                >
                  <Option key={0} value="">
                    {t("Select Client Name")}
                  </Option>
                  {clientNameList.map((item) => {
                    return (
                      <Option value={item.dealerID}>
                        {item.dealerCompany + " (" + item.dealerID + ")"}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
            <Spin spinning={loading} size={"large"}>
              {state.clientToolsData ? (
                state.clientToolsData.dealerCompany ? (
                  <React.Fragment>
                    <div className="col-lg-12">
                      <p className="selctclient__txt">
                        {t("Selected Client Tools")}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-12 mt-2">
                          <p className="name__txt">
                            {t("Name")} :{" "}
                            <span>
                              {state.clientToolsData.dealerCompany +
                                " ( " +
                                state.clientToolsData.dealerId +
                                " )"}
                            </span>
                          </p>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <p className="phone__txt">
                            {t("Phone")} :{" "}
                            <span>{state.clientToolsData.dealerPhone}</span>
                          </p>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <p className="type__txt">
                            {t("Type")} :{" "}
                            <span>
                              {" "}
                              {state.clientToolsData.vendorMembershipType}
                            </span>
                          </p>
                        </div>
                        <div className="col-lg-12 mt-2">
                          <p className="verify__txt">
                            {t("Verified")} :{" "}
                            <span>
                              {" "}
                              {state.clientToolsData.verified ? "Yes" : "No"}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-lg-12">
                          <p className="approve__txt">
                            {t("Approved Apps")} :{" "}
                            <span>{state.clientToolsData.asApprovedApps}</span>
                          </p>
                        </div>
                        <div className="col-lg-6">
                          <div className="input__block">
                            <label>
                              {" "}
                              {t("Users")}: <span>*</span>
                            </label>
                            <Select
                              showSearch
                              className="border__grey"
                              placeholder={t("Select Users")}
                              optionFilterProp="children"
                              value={state.resetPasswordData.id}
                              onChange={(e) => {
                                onChange(e, "userId");
                              }}
                            >
                              {state.userList.map((item, i) => {
                                return (
                                  <Option key={item.id} value={item.id}>
                                    {item.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <p className="uname__txt">
                            {t("Username")} :{" "}
                            <span> {state.resetPasswordData.userName}</span>
                          </p>
                          <p className="psd__txt">
                            {t("Password")} : <span> {"***********"}</span>
                            <span
                              className="edit__txt"
                              onClick={() => setResetPasswordModal(true)}
                            >
                              {t("Edit")}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <p
                        className="viewp__txt linkText"
                        onClick={() => handleGetAdminDealerProfile()}
                      >
                        {t("View Profile")}
                      </p>
                      <a
                        href="#"
                        className="dwnload__txt linkText"
                        onClick={() => handleAdminToolsDownloadInventoryFile()}
                      >
                        {t("Download Inventory")}
                      </a>
                    </div>
                    <div className="col-lg-8">
                      <Button
                        className="primary-btn"
                        onClick={handleImpersonateJewelCloud}
                        loading={impersonateLoading}
                        target="_blank"
                      >
                        {" "}
                        {t("Impersonate JewelCloud")}
                      </Button>
                      <span
                        className="returnjewel__txt linkText"
                        onClick={handleReturnAdmin}
                      >
                        {t("Return to JewelCloud as Dealer 720")}
                      </span>
                    </div>
                  </React.Fragment>
                ) : null
              ) : null}
            </Spin>
            <div className="col-lg-12">
              <Modal
                title={Data.dealerName}
                className="modal__section view__profile"
                centered
                visible={viewProfileModal}
                onCancel={() => setViewProfileModal(false)}
                width={1200}
                footer={[
                  <Button key="back" onClick={() => setViewProfileModal(false)}>
                    {t("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={() => setViewProfileModal(false)}
                  >
                    {t("Ok")}
                  </Button>,
                ]}
              >
                {!mainSliderLoading && (
                  <div className="col-lg-12">
                    <div className="modal__body">
                      <div className="col-lg-12">
                        <div className="row">
                          <div className="col-lg-3 mb-4">
                            {/* <h6>{Data.dealerCompany}</h6> */}
                            <div className="modal__imgdiv">
                              <div className="companyProfile-slider-sp mb-3">
                                <div className="slider__section">
                                  {imageNameData == null ? (
                                    <OwlCarousel
                                      className="owl-theme"
                                      {...options}
                                    >
                                      <div class="item">
                                        <div className="slider__item">
                                          <div className="bgimage">
                                            <img
                                              src={NoImage}
                                              className="img-fluid"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </OwlCarousel>
                                  ) : (
                                    <OwlCarousel
                                      className="owl-theme"
                                      {...options}
                                    >
                                      {imageNameData &&
                                        imageNameData.map((item, i) => {
                                          return (
                                            <div class="item">
                                              <div className="slider__item">
                                                <div className="bgimage">
                                                  <img
                                                    src={
                                                      item.imageName ||
                                                      ResizeLogo
                                                    }
                                                    className="img-fluid"
                                                    alt=""
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </OwlCarousel>
                                  )}
                                </div>
                              </div>
                              <p>
                                {t("Updated")} | {Data.modifiedDate}
                              </p>
                              <span>
                                {t("Data Rating")} | {Data.dataReting}
                              </span>
                              <span className="mr-1">
                                {Data.adminDealrProfileLocations
                                  ? Data.adminDealrProfileLocations.length
                                  : ""}{" "}
                                {t("Location")}
                              </span>
                              <br />
                              <span
                                className="my-hover-pointer linkText"
                                onClick={() => handleRedirectionLocation()}
                              >
                                <p className="your-text-element">
                                  {Data.adminDealrProfileLocations
                                    ? Data.adminDealrProfileLocations.map(
                                        (x) => {
                                          return (
                                            <>
                                              <span>{x.locationName}</span>
                                            </>
                                          );
                                        }
                                      )
                                    : ""}
                                </p>
                              </span>
                            </div>
                          </div>
                          <div className="col-lg-9">
                            <div className="row">
                              <div className="col-lg-12">
                                <h6 className="workarea__heading">
                                  {Data.dealerCompany}
                                </h6>
                                <div className="company-about-section">
                                  <p>
                                    {Data.companyAbout &&
                                      Data.companyAbout.replace(
                                        /(<([^>]+)>)/gi,
                                        ""
                                      )}
                                  </p>
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <p className="favdiamond__txt">
                                  {state.viewProfileData.companyAbout}
                                </p>
                              </div>
                              <div className="col-lg-4">
                                <p className="activ__txt">{t("Active")}</p>
                                <p className="prod__txt">
                                  {Data.productLovesCount} {t("Product Loves")}
                                </p>
                                <p
                                  onClick={() => handleRedirection()}
                                  className="prod__txt my-hover-pointer"
                                >
                                  {Data.collectionsCount} {t("Collections")}
                                </p>
                                <p
                                  onClick={() => handleRedirection()}
                                  className="prod__txt my-hover-pointer"
                                >
                                  {Data.itemsCount} {t("Items")}
                                </p>
                              </div>
                              <div className="col-lg-4">
                                <p className="cont__txt">{t("Contact Info")}</p>
                                <a
                                  className="mailid__txt"
                                  href="mailto:sales3@amipi.com"
                                >
                                  {Data.email}
                                </a>
                                <p className="contnum__txt">
                                  {Data.dealerPhone}
                                </p>
                                <span
                                  className="linkText"
                                  onClick={() =>
                                    openInNewTab(Data.websiteAddress)
                                  }
                                >
                                  {" "}
                                  {Data.websiteAddress}
                                </span>
                              </div>
                              <div className="col-lg-4">
                                <p className="vendtag__txt">{t("Brands")}</p>
                                <span
                                  onClick={() => handleRedirectionBrands()}
                                  className="notag__txt my-hover-pointer"
                                >
                                  {Data.brandNames}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Modal>

              {/* No Information */}

              <Modal
                title={"Selected User Profile"}
                className="modal__section"
                centered
                visible={viewProfileModalNoData}
                onCancel={() => setViewProfileModalNoData(false)}
                footer={null}
                width={500}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-12">
                          <h3 className="subheading text-center">
                            {t("No information available for selected user.")}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>

              {/* End */}

              <Modal
                title={t("Reset Password")}
                className="modal__section"
                centered
                visible={resetPasswordModal}
                onOk={handleAdminResetPassword}
                onCancel={() => handleResetModalCancel()}
                width={400}
                footer={[
                  <Button key="back" onClick={handleResetModalCancel}>
                    {t("Cancel")}
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    onClick={handleAdminResetPassword}
                  >
                    {t("Save")}
                  </Button>,
                ]}
              >
                <div className="col-lg-12">
                  <div className="modal__body">
                    <div className="form__fields border-0 m-0 p-0">
                      <div className="col-lg-12">
                        <div className="input__block">
                          <label>{t("User Name")} :</label>
                          <input
                            type="text"
                            onChange={(e) => {
                              onChange(e, "resetUsername");
                            }}
                            disabled={true}
                            value={state.resetPasswordData.userName}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input__block">
                          <label>{t("Password")}:</label>
                          <input
                            autocomplete="new-password"
                            type={showPassword ? "text" : "password"}
                            onChange={(e) => {
                              onChange(e, "newPassword");
                            }}
                            value={state.resetPasswordData.newPassword}
                          />
                          <span class="p-viewer" onClick={handlePassShowHide}>
                            <i
                              class={
                                showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                              }
                              aria-hidden="true"
                            ></i>
                          </span>
                          {state.resetPasswordError["newPassword"] ? (
                            <span className="error-color-red">
                              {state.resetPasswordError["newPassword"]}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input__block">
                          <label>{t("Confirm Password")}:</label>
                          <input
                            type={showPassword1 ? "text" : "password"}
                            onChange={(e) => {
                              onChange(e, "confirmPassword");
                            }}
                            value={state.resetPasswordData.confirmPassword}
                          />
                          <span class="p-viewer" onClick={handlePassShowHide1}>
                            <i
                              class={
                                showPassword1 ? "fa fa-eye" : "fa fa-eye-slash"
                              }
                              aria-hidden="true"
                            ></i>
                          </span>
                          {state.resetPasswordError["confirmPassword"] ? (
                            <span className="error-color-red">
                              {state.resetPasswordError["confirmPassword"]}
                            </span>
                          ) : null}
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Administrationtools;
