import React, { useState, useEffect } from "react";
import { Input, Select, Button, Table, Modal, Spin, Popconfirm } from "antd";
import { useTranslation } from "react-i18next";
import PurchaseOrderServices from "../../services/purchaseOrder.service";
import Edit_icon from "../../assets/images/icons/edit_icon.svg";
import { useSelector, useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
import { useNavigate, useLocation } from "react-router-dom";
import {
  setChecklinkHeaderMenuData,
  setOpenKeyHeaderMenuData,
  setSelectSideMenu,
  setSelectedVendor,
} from "../../actions/headerMenu/headerMenu";
import CompanyLocationService from "../../services/company-location.service";
import AdminToolsDataService from "../../services/admin-tools.service";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AdminManageLanguageService from "../../services/admin-manageLanguage.service";

const { Option } = Select;

const initialAddressState = {
  Address: "",
  City: "",
  Country: "",
  EmailID: "",
  Phone: "",
  State: "",
  Website: "",
  Zip: "",
};

const initialAddressValidationState = {
  LocationAddressVal: "",
  LocationCityVal: "",
  LocationStateVal: "",
  LocationZipVal: "",
  LocationCountryVal: "",
  LocationEmailVal: "",
  LocationPhoneVal: "",
  LocationWebsiteVal: "",
};

export const WorkSheetDetails = (props) => {
  const { t } = useTranslation();
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [visible5, setVisible5] = useState(false);
  const [workSheetData, setWorkSheetData] = useState([]);
  const [dealerId, setDealerId] = useState(0);
  const [workSheetLoading, setWorkSheetLoading] = useState(false);
  const [generateOrderModalVisible, setGenerateOrderModalVisible] =
    useState(false);
  const [workSheetId, setWorkSheetId] = useState(0);
  const [workSheetName, setWorkSheetName] = useState("");
  const [vendorDynamicName, setVendorDynamicName] = useState({
    vendorName: "",
  });
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [vendorList, setVendorList] = useState([]);
  const [vendorListLoading, setVendorListLoading] = useState(false);
  const [productQty, setProductQty] = useState("");
  const [workSheetDetailID, setWorkSheetDetailID] = useState(0);
  const [selected, setSelected] = useState(false);
  const [checkValue, setCheckValue] = useState(false);
  const [addressData, setAddressData] = useState({});
  const [vendorID, setVendorID] = useState(0);
  const [createWorksheetData, setCreateWorksheetData] = useState({
    PoNumber: "",
    Notes: "",
    vendor: "",
    referenceNumber: "",
  });
  const [orderNotesData, setOrderNotesData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [workSheetNamedata, setWorkSheetNamedata] = useState({
    workSheetName: "",
  });
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteByIdLoading, setDeleteByIdLoading] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteData, setDeleteData] = useState({
    id: "",
  });
  const [createPOLoading, setCreatePOLoading] = useState(false);
  const [Count, setCount] = useState(0);

  const location = useLocation();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [allLocationData, setAllLocationData] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [addressModal, setAddressModal] = useState(false);
  const [locationModal, setLocationModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(initialAddressState);
  const [validations, setValidations] = useState(initialAddressValidationState);
  const [completeAddress, setCompleteAddress] = useState("");
  const [countryDD, setCountryDD] = useState([]);
  const [updatedLocationName, setUpdatedLocationName] = useState("");
  const [locationId, setLocationId] = useState("");
  const [saveAddressLoading, setSaveAddressLoading] = useState(false);
  const [scaList, setScaList] = useState([]);
  const [selectedSCA, setSelectedSCA] = useState("");
  const [accNo, setAccNo] = useState("");
  const [error, setError] = useState(false);
  const [listerr, setListerr] = useState(false);
  const [poNo, setPoNo] = useState("");
  useEffect(() => {
    if (location.state) {
      if (location.state.id) {
        setWorkSheetId(location.state.id);

        handleGetWorksheetDetailsById(
          location.state.id,
          loginDetials.loginDetials.responseData.dealerId
        );
      }
      if (location.state.name) {
        setWorkSheetName(location.state.name);
      }
      if (location.state.vendorDynamicName) {
        setVendorDynamicName({
          vendorName: location.state.vendorDynamicName,
        });
      }
    }
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          // handleGetLocationDetails(
          //   loginDetials.loginDetials.responseData.dealerId
          // );
          handleGetLocationDropdownDetailsByCountry();
        }
      }
    }
  }, []);

  useEffect(() => {
    handleGetLocationDetails(
      loginDetials.loginDetials.responseData.dealerId,
      "fromSave"
    );
  }, [addressData]);

  const handleCancelDeleteModal = () => {
    setVisible3(false);
  };

  const handleDeleteModal = (row) => {
    setVisible3(true);
  };

  const handleEditWorksheetModal = (row) => {
    setVisible5(true);
  };

  const handleCancelEditModal = () => {
    setWorkSheetNamedata({
      workSheetName: "",
    });
    setVisible5(false);
  };

  const handleCancelDeleteModalById = () => {
    setDeleteModalVisible(false);
    setDeleteData({
      id: "",
    });
  };

  const handleDeleteModalById = (item) => {
    setDeleteModalVisible(true);
    setDeleteData({
      id: item.workSheetDetailID,
    });
  };

  const handleCreateModal = (row) => {
    handleGetVendorListForSelectProduct();
    handleCreatePoNo();
    setVisible2(true);
  };

  const handleCancelCreateWorksheetModal = () => {
    setVisible2(false);
    setCreateWorksheetData({
      PoNumber: "",
      Notes: "",
      vendor: "",
      referenceNumber: "",
    });
    setPoNo("");
  };

  const handleCancelGenerateOrderModal = () => {
    setGenerateOrderModalVisible(false);
    setListerr(false);
  };
  const handleGetSCAList = async (id) => {
    try {
      await AdminManageLanguageService.getSCADrpdown(id)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setScaList(responseData || []);
            }
          } else {
            setScaList([]);
          }
        })
        .catch((error) => {
          setScaList([]);
        });
    } catch (error) {
      setScaList([]);
    }
  };
  const handleSelectChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setSelectedSCA(e);
    }
  };

  const handleGetWorksheetDetailsById = (id, dealerID) => {
    let inputData = {
      dealerID: dealerID ? dealerID : dealerId,
      workSheetID: id ? id : 0,
      intRecordNo: "1",
      intNoOfRecords: "1000",
      searchVendor: "all",
    };
    setWorkSheetLoading(true);
    try {
      PurchaseOrderServices.GetWorksheetDetailsById(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setWorkSheetData(responseData);
            setWorkSheetLoading(false);

            let bool = [];
            const testBoolean = responseData.forEach((m) => {
              if (m.selected == true) {
                bool.push(true);
                setVendorID(m.dealerID);
              }
            });

            if (bool.length > 0) {
              setCheckValue(true);
            } else {
              setCheckValue(false);
            }

            let total = responseData.reduce(
              (sum, item) => sum + parseFloat(item.totalEstimate),
              0
            );

            total = parseFloat(total.toFixed(2));

            setPrice(total);
            const quantityData = [];

            responseData.forEach((element) => {
              if (element.selected == true) {
                quantityData.push(Number(element.quantity));
              }
            });

            var totalQuantity = 0;
            for (var i in quantityData) {
              totalQuantity += quantityData[i];
            }

            setQuantity(totalQuantity);
            setCount(1);
          } else {
            setWorkSheetData([]);
            setWorkSheetLoading(false);
            setCount(1);
          }
        })
        .catch((error) => {
          console.log(error);
          setWorkSheetLoading(false);
          setCount(1);
        });
    } catch (error) {
      console.log(error);
      setWorkSheetLoading(false);
      setCount(1);
    }
  };

  const handleGetVendorListForSelectProduct = () => {
    let inputData = {
      dealerID: dealerId ? dealerId : 0,
      workSheetID: workSheetId ? workSheetId : 0,
    };
    setVendorListLoading(true);
    try {
      PurchaseOrderServices.GetVendorListForSelectProduct(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData.length > 0) {
              setVendorListLoading(false);
              setVendorList(responseData);

              const vendorID = responseData[0].dealerID;
              setCreateWorksheetData({
                vendor: vendorID,
              });
            } else {
              setVendorListLoading(false);
              setVendorList([]);
            }
          } else {
            setVendorListLoading(false);
            setVendorList([]);
          }
        })
        .catch((error) => {
          console.log(error);
          setVendorListLoading(false);
        });
    } catch (error) {
      console.log(error);
      setVendorListLoading(false);
    }
  };

  // Update Status and Qty

  const handleStatusOnChange = (e, item) => {
    const temp = workSheetData.map((x) => {
      if (x.workSheetDetailID == item.workSheetDetailID) {
        return {
          ...x,
          selected: e.target.checked == true ? true : false,
        };
      } else return x;
    });
    setWorkSheetData(temp);
    handleUpdateWorkSheetDetailsById(
      dealerId,
      item.workSheetDetailID,
      item.quantity,
      e.target.checked
    );
  };

  const handleQuantityOnChange = (e, item) => {
    const temp = workSheetData.map((x) => {
      if (x.workSheetDetailID == item.workSheetDetailID) {
        return {
          ...x,
          quantity: e.target.value,
        };
      } else return x;
    });
    setWorkSheetData(temp);

    setProductQty(e.target.value);
    setWorkSheetDetailID(item.workSheetDetailID);
    setSelected(item.selected);
  };

  // For Status

  const handleUpdateWorkSheetDetailsById = (
    dealerId,
    workSheetID,
    productQty,
    selected
  ) => {
    let inputData = {
      dealerId: dealerId ? dealerId : 0,
      workSheetId: workSheetID ? workSheetID : 0,
      retailerStyle: "",
      retailerSku: "",
      productQty: productQty ? productQty : 0,
      selected: selected,
    };
    setVendorListLoading(true);
    try {
      PurchaseOrderServices.UpdateWorkSheetDetailsById(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            NotificationManager.success(t("Updated Successfully."));
            handleGetWorksheetDetailsById(workSheetId, dealerId);
          } else {
            NotificationManager.error(t("Not Updated."));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateWorkSheetDetailsByIdQty = (
    dealerId,
    workSheetID,
    productQty,
    selected
  ) => {
    let inputData = {
      dealerId: dealerId ? dealerId : 0,
      workSheetId: workSheetID ? workSheetID : 0,
      retailerStyle: "",
      retailerSku: "",
      productQty: productQty ? productQty : 0,
      selected: selected,
    };
    setVendorListLoading(true);
    try {
      PurchaseOrderServices.UpdateWorkSheetDetailsById(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            NotificationManager.success(t("Updated Successfully."));
            handleGetWorksheetDetailsById(workSheetId, dealerId);
          } else {
            NotificationManager.error(t("Not Updated."));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // For Second Modal Data

  const handleGetGetConfirmOrderDetailByID = () => {
    const vendorID = createWorksheetData.vendor;
    let inputData = {
      dealerID: dealerId ? dealerId : 0,
      workSheetID: workSheetId ? workSheetId : 0,
      vendorID: vendorID ? vendorID : 0,
      poName: createWorksheetData.PoNumber
        ? createWorksheetData.PoNumber.toString()
        : "",
    };
    setVendorListLoading(true);
    try {
      PurchaseOrderServices.GetGetConfirmOrderDetailByID(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData == "Name Exists") {
              NotificationManager.error(t("PO name Already Exists."));
              setVendorListLoading(false);
            } else if (responseData == "Please Enter Acc No for this Vendor") {
              NotificationManager.error(
                t("Kindly Add Account Number for this Vendor.")
              );
              setVendorListLoading(false);
            } else if (responseData == "Vendor Retailer Not connected") {
              NotificationManager.error(
                t(
                  "Your connection to this vendor is no longer active. Please request access to this vendor again before placing orders. If you believe this is a mistake please contact GemFind support."
                )
              );
              setVendorListLoading(false);
            } else {
              setAddressData(responseData);
              setGenerateOrderModalVisible(true);
              setVendorListLoading(false);
              handleGetWorksheetDetailsSelectedValue();
            }
          } else {
            setAddressData({});
            setVendorListLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setVendorListLoading(false);
        });
      handleGetSCAList(vendorID);
    } catch (error) {
      console.log(error);
      setVendorListLoading(false);
    }
  };
  // to create Unique Po Number
  const handleCreatePoNo = () => {
    let inputData = {
      dealerID: dealerId ? dealerId.toString() : "",
    };
    try {
      PurchaseOrderServices.CreatePONumber(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setCreateWorksheetData({
              PoNumber: responseData,
            });
            setPoNo(responseData);
          } else {
            setCreateWorksheetData({
              PoNumber: "",
            });
            setPoNo("");
          }
        })
        .catch((error) => {
          console.log(error);
          setCreateWorksheetData({
            PoNumber: "",
          });
          setPoNo("");
        });
    } catch (error) {
      console.log(error);
      setCreateWorksheetData({
        PoNumber: "",
      });
      setPoNo("");
    }
  };

  // Onchange

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setCreateWorksheetData({
        ...createWorksheetData,
        [name]: e,
      });
    } else {
      setCreateWorksheetData({
        ...createWorksheetData,
        [e.target.name]: e.target.value,
      });
    }
  };

  // Modal Table Data

  const handleGetWorksheetDetailsSelectedValue = () => {
    const vendorID = createWorksheetData.vendor;
    let inputData = {
      dealerID: dealerId ? dealerId : 0,
      workSheetID: workSheetId ? workSheetId : 0,
      intRecordNo: "1",
      intNoOfRecords: "1000",
      searchVendor: vendorID ? vendorID : 0,
    };
    setTableLoading(true);
    try {
      PurchaseOrderServices.GetWorksheetDetailsSelectedValue(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setOrderNotesData(responseData);
            //alert(responseData[0]?.ediAccNumber);
            setAccNo(responseData[0]?.ediAccNumber);
            if (
              responseData[0]?.ediAccNumber === "" ||
              responseData[0]?.ediAccNumber === null ||
              responseData[0]?.ediAccNumber === undefined
            ) {
              setError(true);
            } else {
              setError(false);
            }
            setTableLoading(false);
          } else {
            setOrderNotesData([]);
            setTableLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setTableLoading(false);
        });
    } catch (error) {
      console.log(error);
      setTableLoading(false);
    }
  };

  // Update worksheet Name

  const handleWorksheetOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setWorkSheetNamedata({
        ...workSheetNamedata,
        [name]: e,
      });
    } else {
      setWorkSheetNamedata({
        ...workSheetNamedata,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleUpdateWorkSheetName = () => {
    let inputData = {
      dealerId: dealerId ? dealerId : 0,
      workSheetId: workSheetId ? workSheetId : 0,
      workSheetName: workSheetNamedata.workSheetName
        ? workSheetNamedata.workSheetName
        : "",
    };
    setUpdateLoading(true);
    try {
      PurchaseOrderServices.UpdateWorkSheetName(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData == "Worksheet Already Exist") {
              NotificationManager.error(t("Worksheet Name Already Exists."));
              setUpdateLoading(false);
              handleCancelEditModal();
            } else {
              NotificationManager.success(
                t("Worksheet Name Has Been Updated Successfully.")
              );
              setWorkSheetName(workSheetNamedata.workSheetName);
              handleCancelEditModal();
              setUpdateLoading(false);
            }
          } else {
            NotificationManager.error(
              t("Worksheet Name Has Not Been Updated.")
            );
            handleCancelEditModal();
            setUpdateLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setUpdateLoading(false);
          handleCancelEditModal();
        });
    } catch (error) {
      console.log(error);
      setUpdateLoading(false);
      handleCancelEditModal();
    }
  };

  // Delete WorkSheet
  let navigate = useNavigate();

  const handleRemoveWorkSheetName = () => {
    let inputData = {
      dealerID: dealerId ? dealerId : 0,
      workSheetID: workSheetId ? workSheetId : 0,
    };
    setDeleteLoading(true);
    try {
      PurchaseOrderServices.RemoveWorkSheetName(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setDeleteLoading(false);
            handleCancelDeleteModal();
            NotificationManager.success(
              t("WorkSheet Has Been Deleted Successfully.")
            );
            navigate("/workSheet");
          } else {
            setDeleteLoading(false);
            handleCancelDeleteModal();
            NotificationManager.error(t("WorkSheet Has Not Been Deleted."));
          }
        })
        .catch((error) => {
          console.log(error);
          setDeleteLoading(false);
          handleCancelDeleteModal();
        });
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
      handleCancelDeleteModal();
    }
  };

  // Remove Worksheet By ID

  const handleRemoveWorkSheetDetailsById = () => {
    let inputData = {
      dealerID: dealerId ? dealerId : 0,
      workSheetID: deleteData.id ? deleteData.id : 0,
    };
    setDeleteByIdLoading(true);
    try {
      PurchaseOrderServices.RemoveWorkSheetDetailsById(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setDeleteByIdLoading(false);
            NotificationManager.success(
              t("Product Has Been Deleted Successfully.")
            );
            handleCancelDeleteModalById();
            handleGetWorksheetDetailsById(workSheetId, dealerId);
          } else {
            setDeleteByIdLoading(false);
            NotificationManager.error(t("Product Has Not Been Deleted."));
            handleCancelDeleteModalById();
          }
        })
        .catch((error) => {
          console.log(error);
          setDeleteByIdLoading(false);
          handleCancelDeleteModalById();
        });
    } catch (error) {
      console.log(error);
      setDeleteByIdLoading(false);
      handleCancelDeleteModalById();
    }
  };

  // Create PO

  const handleGeneratePOForSelectedVendor = () => {
    if (!selectedAddress) {
      NotificationManager.error(t("Please Select A Shipping Address"));
      return;
    } else if (selectedSCA <= 0) {
      setListerr(true);
      return;
    }
    //let locationIds = selectedAddress.split(",");
    //console.log(createWorksheetData, "data");
    let inputData = {
      dealerID: dealerId ? dealerId.toString() : 0,
      workSheetID: workSheetId ? workSheetId.toString() : 0,
      vendorID: createWorksheetData.vendor
        ? createWorksheetData.vendor.toString()
        : "",
      poName: poNo,
      poNote: createWorksheetData.Notes
        ? createWorksheetData.Notes.toString()
        : "",
      locationID1: Number(selectedAddress),
      locationID2: 0,
      referenceNumber: createWorksheetData.referenceNo
        ? createWorksheetData.referenceNo.toString()
        : "",
      scaccode: selectedSCA ? selectedSCA.toString() : "",
    };
    setCreatePOLoading(true);
    try {
      PurchaseOrderServices.GeneratePOForSelectedVendor(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setCreatePOLoading(false);
            NotificationManager.success(t("PO Has Been Created Successfully."));
            handleCancelGenerateOrderModal();
            handleCancelCreateWorksheetModal();
            navigate("/myPurchaseOrder");
          } else {
            setCreatePOLoading(false);
            NotificationManager.error(t("PO Has Not Been Created."));
            handleCancelGenerateOrderModal();
            handleCancelCreateWorksheetModal();
          }
        })
        .catch((error) => {
          console.log(error);
          setCreatePOLoading(false);
        });
    } catch (error) {
      console.log(error);
      setCreatePOLoading(false);
    }
  };

  const dispatch = useDispatch();
  const handleRedirection = () => {
    dispatch(setSelectedVendor(vendorDynamicName.vendorName));
    dispatch(setChecklinkHeaderMenuData("My Catalog"));
    var MenuData = [`${"Products"}`, `${"ProductsJewelry"}`];
    dispatch(setOpenKeyHeaderMenuData(MenuData));
    dispatch(setSelectSideMenu(["sharedProduct"]));
    navigate("/sharedProduct");
  };

  const handleItemDetailsRedirection = (dealerID, gfInventoryID) => {
    dispatch(setSelectedVendor(vendorDynamicName.vendorName));
    dispatch(setChecklinkHeaderMenuData("My Catalog"));
    var MenuData = [`${"Products"}`, `${"ProductsJewelry"}`];
    dispatch(setOpenKeyHeaderMenuData(MenuData));
    dispatch(setSelectSideMenu(["sharedProduct"]));

    let item = {
      dealerID,
      gfInventoryID,
    };
    navigate("/productDetails", {
      state: {
        productData: item,
      },
    });
  };

  const handleGetLocationDetails = (id, cameFrom) => {
    debugger;
    try {
      let inputData = {
        dealerID: id,
      };
      CompanyLocationService.GetPOLocationDetails(inputData).then(
        (response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          //console.log(responseData.Table, "Table");
          if (message === "Success") {
            if (responseData.Table.length > 0) {
              if (cameFrom === "fromSave") {
                let displayedAddress = responseData.Table.filter(
                  (add) => add.LocationId == Number(locationId)
                );
                if (displayedAddress) {
                  let tempAddress = addressData;
                  //console.log(addressData, "Address");
                  tempAddress.shippingAddress[0].dealerAddress =
                    displayedAddress[0]?.Address;
                  tempAddress.shippingAddress[0].dealerCity =
                    displayedAddress[0]?.City;
                  tempAddress.shippingAddress[0].dealerState =
                    displayedAddress[0]?.STATE;
                  tempAddress.shippingAddress[0].dealerZIP =
                    displayedAddress[0]?.Zip;
                  tempAddress.shippingAddress[0].dealerphone =
                    displayedAddress[0]?.Phone;
                  tempAddress.shippingAddress[0].dealeremail =
                    displayedAddress[0]?.EmailID;
                  setAddressData(tempAddress);
                }
                setSelectedAddress(displayedAddress[0]?.LocationId.toString());
              } else {
                setSelectedAddress(responseData.Table[0].LocationId.toString());
              }
              setAllLocationData(responseData.Table);
            }
            //console.log(allLocationData, "Data");
          }
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeAddress = (e, name) => {
    let selectedId = e;
    setSelectedAddress(selectedId);
    //const numbers = selectedId.split(",");

    let displayedAddress;
    // if (numbers[0] > "0") {
    //   displayedAddress = allLocationData.filter(
    //     (location) => location.LocationId == Number(numbers[0])
    //   );
    // } else {
    displayedAddress = allLocationData.filter(
      (location) => location.LocationId == Number(selectedId)
    );
    // }
    if (displayedAddress && displayedAddress[0].Address.length > 0) {
      let tempAddress = addressData;
      tempAddress.shippingAddress[0].dealerAddress =
        displayedAddress[0].Address;
      tempAddress.shippingAddress[0].dealerCity = displayedAddress[0].City;
      tempAddress.shippingAddress[0].dealerState = displayedAddress[0].STATE;
      tempAddress.shippingAddress[0].dealerZIP = displayedAddress[0].Zip;
      tempAddress.shippingAddress[0].dealerphone = displayedAddress[0].Phone;
      tempAddress.shippingAddress[0].dealeremail = displayedAddress[0].EmailID;
      setAddressData(tempAddress);
    }
  };

  const openAddressModal = () => {
    setAddressModal(true);
    setSelectedLocation(initialAddressState);
    setValidations(initialAddressValidationState);
    setCompleteAddress("");
  };

  const closeAddressModal = () => {
    setAddressModal(false);
  };
  const closeLocationModal = () => {
    setLocationModal(false);
  };

  const openLocationModal = () => {
    setLocationModal(true);
    setUpdatedLocationName("");
  };

  const formChangeHandler = (e, name, validationName) => {
    let value = "";

    if (name === "Country") {
      value = e;
    } else {
      value = e.target.value;
    }

    if (name === "Address") {
      setValidations((prevState) => ({
        ...prevState,
        LocationAddressVal: "",
      }));
      setCompleteAddress(value);
    } else {
      if (name == "City") {
        setValidations((prevState) => ({
          ...prevState,
          LocationCityVal: "",
        }));
      }
      if (name == "State") {
        setValidations((prevState) => ({
          ...prevState,
          LocationStateVal: "",
        }));
      }
      if (name == "Zip") {
        setValidations((prevState) => ({
          ...prevState,
          LocationZipVal: "",
        }));
      }
      if (name == "Country") {
        setValidations((prevState) => ({
          ...prevState,
          LocationCountryVal: "",
        }));
      }
      if (name == "EmailID") {
        setValidations((prevState) => ({
          ...prevState,
          LocationEmailVal: "",
        }));
      }
      if (name == "Phone") {
        setValidations((prevState) => ({
          ...prevState,
          LocationPhoneVal: "",
        }));
      }
      if (name == "Website") {
        setValidations((prevState) => ({
          ...prevState,
          LocationWebsiteVal: "",
        }));
      }
      setSelectedLocation((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleValidation = () => {
    const validations = {};
    var validEmailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = true;
    if (!completeAddress) {
      isValid = false;
      validations.LocationAddressVal = t("Address is compulsory");
    } else {
      validations.LocationAddressVal = "";
    }
    if (!selectedLocation.City) {
      isValid = false;
      validations.LocationCityVal = t("City is compulsory");
    } else {
      validations.LocationCityVal = "";
    }
    if (!selectedLocation.State) {
      isValid = false;
      validations.LocationStateVal = t("State is compulsory");
    } else {
      validations.LocationStateVal = "";
    }
    if (!selectedLocation.Zip) {
      isValid = false;
      validations.LocationZipVal = t("Zip is compulsory");
    } else {
      validations.LocationZipVal = "";
    }
    if (!selectedLocation.Country) {
      isValid = false;
      validations.LocationCountryVal = t("Country is compulsory");
    } else {
      validations.LocationCountryVal = "";
    }
    var validRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!selectedLocation.EmailID) {
      isValid = false;
      validations.LocationEmailVal = t("Email is compulsory");
    } else if (!selectedLocation.EmailID.match(validRegex)) {
      isValid = false;
      validations.LocationEmailVal = t("Email address is Invalid");
    } else {
      validations.LocationEmailVal = "";
    }
    if (!selectedLocation.Phone) {
      isValid = false;
      validations.LocationPhoneVal = t("Phone is compulsory");
    } else if (
      (selectedLocation.Phone.length < 15 &&
        selectedLocation.Phone.includes("-") &&
        /^[0-9]+$/.test(selectedLocation.Phone)) ||
      (selectedLocation.Phone.length < 15 &&
        selectedLocation.Phone.includes("+") &&
        /^[0-9]+$/.test(selectedLocation.Phone))
    ) {
      validations.LocationPhoneVal = "";
    } else if (
      /^0*$/.test(selectedLocation.Phone) ||
      /[a-zA-Z@{}#$%?/^*&]/g.test(selectedLocation.Phone)
    ) {
      isValid = false;
      validations.LocationPhoneVal = t("Phone number is invalid");
    } else if (
      selectedLocation.Phone.length > 15 ||
      selectedLocation.Phone.length < 10
    ) {
      isValid = false;
      validations.LocationPhoneVal = t("Phone number is invalid");
    } else {
      validations.LocationPhoneVal = "";
    }
    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const updateLocationHandler = () => {
    const isValid = handleValidation();
    if (!isValid) {
      return false;
    }
    try {
      if (
        selectedLocation.City !== "" &&
        selectedLocation.State !== "" &&
        selectedLocation.Zip !== "" &&
        selectedLocation.Country !== ""
      ) {
        let inputData = {
          txtAddress: completeAddress,
          txtCity: selectedLocation.City,
          txtState: selectedLocation.State,
          txtZip: selectedLocation.Zip,
          drpcountry: selectedLocation.Country.toString(),
          txtPhone: selectedLocation.Phone,
          txtEmail: selectedLocation.EmailID,
          txtWebsite: selectedLocation.Website,
          dealerID: dealerId.toString(),
          locationID: locationId ? locationId.toString() : "",
        };
        setSaveAddressLoading(true);
        CompanyLocationService.UpdatePOLocation(inputData).then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            NotificationManager.success(t("Location Updated Successfully."));
            handleGetLocationDetails(dealerId, "fromSave");
            setAddressModal(false);
            setSelectedLocation(initialAddressState);
            setValidations(initialAddressValidationState);
            setCompleteAddress("");
            setSaveAddressLoading(false);
          } else {
            NotificationManager.error(t("Location Update Failed."));
            setSaveAddressLoading(false);
          }
        });
      }
    } catch (e) {
      console.log(e);
      NotificationManager.error(t("Something Went Wrong."));
      setSaveAddressLoading(false);
    }
  };
  const handleGetLocationDropdownDetailsByCountry = () => {
    try {
      let inputData = {
        id: 0,
        dropdownType: "Country",
      };
      AdminToolsDataService.GetLocationDropdownDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setCountryDD(responseData);
            } else {
              setCountryDD([]);
            }
          } else {
            setCountryDD([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const updateLocationNameHandler = (id) => {
    if (updatedLocationName == "") {
      NotificationManager.error(t("Location name should not be empty"));
      return false;
    }
    try {
      let inputData = {
        DealerID: dealerId.toString(),
        txtAddLocation: updatedLocationName,
      };
      CompanyLocationService.UpdatePOLocationName(inputData).then(
        (response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setLocationId(responseData);
            // NotificationManager.success("Location Updated Successfully.");
            closeLocationModal();
            openAddressModal();
          } else {
            NotificationManager.error(t("Location Update Failed."));
          }
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const locationNameChangeHandler = (e, id) => {
    const { value } = e.target;
    setUpdatedLocationName(value);
  };

  const deleteLocationDataHandler = () => {
    try {
      let inputData = {
        dealerID: dealerId ? dealerId.toString() : "", //2948
        locationID: locationId ? locationId : "",
      };
      CompanyLocationService.DeletePOLocation(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            NotificationManager.success(t("Location Deleted Successfully."));
            handleGetLocationDetails(dealerId);
            closeAddressModal();
          } else {
            NotificationManager.error(t("Location Delete Failed."));
          }
        })
        .catch((error) => {
          NotificationManager.error(t("Location Delete Failed."));
        });
    } catch (e) {
      console.log(e);
    }
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      workSheetData,
      result.source.index,
      result.destination.index
    );

    setWorkSheetData(items);
  };
  const filteredData = scaList.filter((item) => item.isVendorapprove);
  const dataToRender = filteredData.length > 0 ? filteredData : scaList;

  return (
    <div className="form__fields">
      <div className="row magento__mapping">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0 mb-0"> {t("Worksheet")} </h4>
          </div>
        </div>
        <div className="col-md-12">
          <p>
            {t(
              "Products without a checkbox are not eligible for our Purchase Order program."
            )}
          </p>
          <p style={{ color: "red" }}>
            {t(
              "Items without a wholesale price will not be considered for purchase orders (PO)"
            )}
          </p>
        </div>
        <div className="col-md-12">
          <div className="divForm">
            <div className="row">
              <div className="col-md-9">
                <Spin spinning={workSheetLoading}>
                  {/* DO NOT REMOVE THIS COMMENTED CODE */}

                  {/* {workSheetData.length > 0 ? (
                   workSheetData.map((item, i) => {
                    return (
                      <div className="">
                        <div className="row mt-4">
                          <div class="col-md-6 d-flex">
                            <div class="form-group">
                              <div class="radio__block mb-0">
                                <Input
                                  type="checkbox"
                                  className="mt-1"
                                  name="optradio"
                                  checked={
                                    item.selected == true ? true : false
                                  }
                                  onChange={(e) =>
                                    handleStatusOnChange(e, item)
                                  }
                                />
                              </div>
                            </div>
                            <div class="form-group">
                              <label>Selected</label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group d-flex justify-content-end">
                              <div className="col-md-1">
                                <i
                                  class="fa fa-minus-circle"
                                  onClick={() => handleDeleteModalById(item)}
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div className="col-md-1">
                                <i
                                  class="fa fa-arrows"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <hr className="mt-2" />
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-4">
                            <div className="form-group">
                              <span>{item.dealercompany}</span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <span>{item.styleNumber}</span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <span>{item.categoryNames}</span>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-4">
                            <div className="form-group">
                              <span>Collection:</span>
                              <span>{item.categoryNames}</span>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <span>Order Qty</span>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <Input
                                class="input"
                                type="text"
                                id=""
                                // onblur="handleOK()"
                                value={item.quantity}
                                onChange={(e) =>
                                  handleQuantityOnChange(e, item)
                                }
                                onBlur={() =>
                                  handleUpdateWorkSheetDetailsByIdQty(
                                    dealerId,
                                    workSheetDetailID,
                                    productQty,
                                    selected
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <span>{item.metalType}</span>
                              <span>(White)</span>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-3">
                            <div className="form-group">
                              <img
                                src={item.imagePath}
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="col-md-9 emailTemplate">
                            <div className="emailDetailBlock">
                              <ul>
                                <li>
                                  <a>{item.productName}</a>
                                </li>
                              </ul>
                              <div className="col-md-9">
                                <div className="form-group">
                                  <p>{item.productDescription}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-3">
                            <div className="form-group text-center">
                              <Button
                                onClick={() =>
                                  handleItemDetailsRedirection(
                                    item.dealerID,
                                    item.jewelryInventoryID
                                  )
                                }
                                className="primary-btn"
                              >
                                Item Detail
                              </Button>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <span>
                                Suggested Retail: $
                                {item.suggestedRetail == ""
                                  ? 0
                                  : item.suggestedRetail}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <span>
                                Wholesale Cost: $
                                {item.wholesalePrice == ""
                                  ? 0
                                  : item.wholesalePrice}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <span>
                                Total Estimate:$
                                {item.totalEstimate == ""
                                  ? 0
                                  : item.totalEstimate}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                  ) */}
                  {workSheetData.length > 0 ? (
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {workSheetData.map((item, i) => {
                              return (
                                <Draggable
                                  key={item.jewelryInventoryID}
                                  draggableId={item.jewelryInventoryID}
                                  index={i}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                    >
                                      <div className="">
                                        <div className="row mt-4">
                                          <div class="col-md-6 d-flex">
                                            <div class="form-group">
                                              <div class="radio__block mb-0">
                                                <Input
                                                  type="checkbox"
                                                  className="mt-1"
                                                  name="optradio"
                                                  checked={
                                                    item.selected == true
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) =>
                                                    handleStatusOnChange(
                                                      e,
                                                      item
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                            <div class="form-group">
                                              <label>{t("Selected")}</label>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="form-group d-flex justify-content-end">
                                              <div className="col-md-1">
                                                <i
                                                  class="fa fa-minus-circle"
                                                  onClick={() =>
                                                    handleDeleteModalById(item)
                                                  }
                                                  aria-hidden="true"
                                                ></i>
                                              </div>
                                              <div
                                                className="col-md-1"
                                                {...provided.dragHandleProps}
                                              >
                                                <i
                                                  class="fa fa-arrows"
                                                  aria-hidden="true"
                                                ></i>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-12">
                                            <hr className="mt-2" />
                                          </div>
                                        </div>
                                        <div className="row mt-3">
                                          <div className="col-md-4">
                                            <div className="form-group">
                                              <span>
                                                {t("Dealer Company")}:{" "}
                                              </span>
                                              <span>{item.dealercompany}</span>
                                            </div>
                                          </div>
                                          <div className="col-md-4">
                                            <div className="form-group">
                                              <span>{t("SKU")}: </span>
                                              <span>{item.styleNumber}</span>
                                            </div>
                                          </div>
                                          <div className="col-md-4">
                                            <div className="form-group">
                                              <span>{t("Category")}: </span>
                                              <span
                                                style={{
                                                  wordBreak: "break-word",
                                                }}
                                              >
                                                {item.categoryNames}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row mt-3">
                                          <div className="col-md-4">
                                            <div className="form-group">
                                              <span>{t("Collection")}: </span>
                                              <span>{item.collection}</span>
                                            </div>
                                          </div>
                                          <div className="col-md-2">
                                            <div className="form-group">
                                              <span>{t("Order Qty")}</span>
                                            </div>
                                          </div>
                                          <div className="col-md-2">
                                            <div className="form-group">
                                              <Input
                                                class="input"
                                                type="text"
                                                id=""
                                                value={item.quantity}
                                                onChange={(e) =>
                                                  handleQuantityOnChange(
                                                    e,
                                                    item
                                                  )
                                                }
                                                onBlur={() =>
                                                  handleUpdateWorkSheetDetailsByIdQty(
                                                    dealerId,
                                                    workSheetDetailID,
                                                    productQty,
                                                    selected
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                          <div className="col-md-4">
                                            <div className="form-group">
                                              <span>
                                                {t("Metal Type")} & {t("Color")}
                                                :{" "}
                                              </span>
                                              <span>{item.metalType}</span>
                                              <span>(White)</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row mt-3">
                                          <div className="col-md-3">
                                            <div className="form-group">
                                              <img
                                                src={item.imagePath}
                                                className="img-fluid"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-md-9 emailTemplate">
                                            <div className="emailDetailBlock">
                                              <ul>
                                                <li>
                                                  <a>{item.productName}</a>
                                                </li>
                                              </ul>
                                              <div className="col-md-9">
                                                <div className="form-group">
                                                  <p>
                                                    {item.productDescription}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row mt-3">
                                          <div className="col-md-3">
                                            <div className="form-group text-center">
                                              <Button
                                                onClick={() =>
                                                  handleItemDetailsRedirection(
                                                    item.dealerID,
                                                    item.jewelryInventoryID
                                                  )
                                                }
                                                className="primary-btn"
                                              >
                                                {t("Item Detail")}
                                              </Button>
                                            </div>
                                          </div>
                                          <div className="col-md-3">
                                            <div className="form-group">
                                              <span>
                                                {t("Suggested Retail")}: $
                                                {item.suggestedRetail == ""
                                                  ? 0
                                                  : item.suggestedRetail}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-md-3">
                                            <div className="form-group">
                                              <span>
                                                {t("Wholesale Cost")}: $
                                                {item.wholesalePrice == ""
                                                  ? 0
                                                  : item.wholesalePrice}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="col-md-3">
                                            <div className="form-group">
                                              <span>
                                                {t("Total Estimate")}: $
                                                {item.totalEstimate == ""
                                                  ? 0
                                                  : item.totalEstimate}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  ) : Count == 1 ? (
                    <>
                      <div
                        className="text-center cursor__pointer text-left-mobile "
                        onClick={() => handleRedirection()}
                      >
                        <p
                          className="my-hover-pointer"
                          style={{ color: "#24abe2" }}
                        >
                          {t(
                            "This Worksheet does not have any product assigned to it."
                          )}
                        </p>
                        <p
                          className="my-hover-pointer"
                          style={{ color: "#24abe2" }}
                        >
                          {t(
                            "Please go to Catalog/Products to choose products for this worksheet."
                          )}
                        </p>
                      </div>
                    </>
                  ) : null}
                </Spin>
              </div>
              {/* End */}
              <div className="col-md-3 Grey__box">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="d-flex">
                      <div className="action__btns">
                        <div className="image__block">
                          <img
                            src={Edit_icon}
                            alt=""
                            onClick={() => {
                              handleEditWorksheetModal();
                            }}
                          />
                        </div>
                      </div>
                      <div>{workSheetName ? workSheetName : ""}</div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-6">
                    <div className="form-group">
                      <span>{t("Total Qty")}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Input
                        class="input"
                        type="text"
                        id=""
                        onblur="ChangeWorkSheetDetail(76)"
                        value={quantity}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <span>{t("Total Cost")}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <Input
                        class="input"
                        type="text"
                        id=""
                        onblur="ChangeWorkSheetDetail(76)"
                        value={"$" + price}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="form-group">
                    <Button
                      disabled={checkValue == true ? false : true}
                      className={
                        checkValue == true
                          ? "primary-btn"
                          : "primary-btn disabled-btn"
                      }
                      onClick={() => {
                        handleCreateModal();
                      }}
                    >
                      {t("Create PO")}
                    </Button>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="form-group">
                    <button
                      className="primary-btn"
                      onClick={() => {
                        handleDeleteModal();
                      }}
                    >
                      {t("Delete Worksheet")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title={t("Edit WorkSheet")}
        className="modalconsupld__section"
        centered
        visible={visible5}
        onOk={handleUpdateWorkSheetName}
        onCancel={handleCancelEditModal}
        width={400}
        footer={[
          <Button key="back" onClick={handleCancelEditModal} type="primary">
            {t("Cancel")}
          </Button>,
          <Button
            loading={updateLoading}
            key="submit"
            type="primary"
            onClick={handleUpdateWorkSheetName}
          >
            {t("Update")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="col-md-12">
                  <div className="input__block">
                    <label>{t("WorkSheet Name")} </label>
                    <input
                      type="text"
                      placeholder={t("Enter WorkSheet Name")}
                      name="workSheetName"
                      value={workSheetNamedata.workSheetName}
                      onChange={(e) => handleWorksheetOnChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={t("Delete")}
        className="modalconsupld__section"
        centered
        visible={visible3}
        onOk={handleRemoveWorkSheetName}
        onCancel={handleCancelDeleteModal}
        width={400}
        footer={[
          <Button key="back" onClick={handleCancelDeleteModal}>
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            loading={deleteLoading}
            onClick={handleRemoveWorkSheetName}
            type="primary"
          >
            {t("Delete")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <p>
                      {t("Delete the")} "{workSheetName}" {t("Worksheet")}
                    </p>
                    <h5>{t("THIS CAN NOT BE UNDONE")}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* By ID Delete  */}

      <Modal
        title={t("Delete")}
        className="modalconsupld__section"
        centered
        visible={deleteModalVisible}
        onOk={handleRemoveWorkSheetDetailsById}
        onCancel={handleCancelDeleteModalById}
        width={400}
        footer={[
          <Button key="back" onClick={handleCancelDeleteModalById}>
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            loading={deleteByIdLoading}
            onClick={handleRemoveWorkSheetDetailsById}
            type="primary"
          >
            {t("Delete Product")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <p>{t("Are You Sure You Want To Delete This Product")}</p>
                    <h5>{t("THIS CAN NOT BE UNDONE")}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* End  */}
      <Modal
        title={t("Create PO")}
        className="modalconsupld__section"
        centered
        visible={visible2}
        onOk={() => handleGetGetConfirmOrderDetailByID()}
        onCancel={handleCancelCreateWorksheetModal}
        width={400}
        footer={[
          <Button key="back" onClick={handleCancelCreateWorksheetModal}>
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => handleGetGetConfirmOrderDetailByID()}
          >
            {t("Preview PO")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <Spin spinning={vendorListLoading}>
            <div className="modal__body">
              <div className="col-lg-12">
                <div className="form__fields border-0 p-0">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="input__block">
                        <label>{t("Select Vendor")} </label>
                        <Select
                          name="vendor"
                          className="border__grey"
                          showSearch
                          optionFilterProp="children"
                          value={createWorksheetData.vendor}
                          onChange={(e) =>
                            handleOnChange(e, "select", "vendor")
                          }
                        >
                          {vendorList &&
                            vendorList.map((item, i) => {
                              return (
                                <Option value={item.dealerID}>
                                  {item.designerName}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="input__block">
                        <label>{t("PO Number")} </label>
                        <input
                          type="text"
                          name="PoNumber"
                          placeholder={t("Enter PO Number")}
                          value={createWorksheetData.PoNumber}
                          onChange={(e) => handleOnChange(e)}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="input__block">
                        <label>{t("Reference Number")} </label>
                        <input
                          type="text"
                          name="referenceNo"
                          placeholder={t("Enter Reference Number")}
                          value={createWorksheetData.referenceNumber}
                          onChange={(e) => handleOnChange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="input__block">
                        <label>{t("Notes")}</label>
                        <textarea
                          type="text"
                          name="Notes"
                          id=""
                          cols="30"
                          rows="3"
                          class="hide__height w-100"
                          value={createWorksheetData.Notes}
                          onChange={(e) => handleOnChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </div>
      </Modal>

      {/* Generate PO Modal */}
      <Modal
        title={t("Purchase Order Summary")}
        centered
        visible={generateOrderModalVisible}
        onOk={handleGeneratePOForSelectedVendor}
        onCancel={handleCancelGenerateOrderModal}
        footer={[
          <Button key="back" onClick={handleCancelGenerateOrderModal}>
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleGeneratePOForSelectedVendor}
            disabled={dataToRender.length === 0}
            loading={createPOLoading}
          >
            {t("Create PO")}
          </Button>,
        ]}
        width={1000}
      >
        <div className="form__fields">
          <div className="row magento__mapping">
            <div className="col-md-4">
              <h5 className="subheading">{t("Select Shipping Address :")}</h5>
              <Select
                style={{ width: "100%" }}
                showSearch
                placeholder={t("Select Shipping Address")}
                optionFilterProp="children"
                value={selectedAddress}
                onChange={(e) => handleChangeAddress(e)}
              >
                {allLocationData &&
                  allLocationData.length > 0 &&
                  allLocationData.map((item, index) => {
                    return (
                      <Option
                        key={item.LocationId.toString()}
                        value={item.LocationId.toString()}
                      >
                        {item.LocationName}
                      </Option>
                    );
                  })}
              </Select>
              <h5 className="subheading" style={{ textAlign: "center" }}>
                {t("OR")}
              </h5>
              <div className="form-group mt-4">
                <Button
                  className={
                    checkValue == true
                      ? "primary-btn"
                      : "primary-btn disabled-btn"
                  }
                  block
                  onClick={() => {
                    openLocationModal();
                  }}
                >
                  {t("Add New Shipping Address")}
                </Button>
              </div>
            </div>
            {addressData.shippingAddress
              ? addressData.shippingAddress.length > 0 && (
                  <div className="col-md-4 mb-4">
                    <h5 className="subheading">{t("Shipping Address :")}</h5>
                    <p className="mb-0">
                      {addressData.shippingAddress[0].dealercompany}
                    </p>
                    <p className="mb-0">
                      {addressData.shippingAddress[0].dealername}
                    </p>
                    <p className="mb-0">
                      {addressData.shippingAddress[0].dealerAddress}
                    </p>
                    <p className="mb-0">
                      {addressData.shippingAddress[0].dealerCity}{" "}
                      {addressData.shippingAddress[0].dealerState}{" "}
                      {addressData.shippingAddress[0].dealerZIP}
                    </p>
                    <p className="mb-0">
                      {addressData.shippingAddress[0].dealerCountry}
                    </p>
                    <p className="mb-0">
                      {t("T:")}
                      {addressData.shippingAddress[0].dealerphone}
                    </p>
                    <p className="mb-0">
                      {t("Email:")}
                      {addressData.shippingAddress[0].dealeremail}{" "}
                    </p>
                  </div>
                )
              : null}
            {addressData.manufactureraddress
              ? addressData.manufactureraddress.length > 0 && (
                  <div className="col-md-4 mb-4">
                    <h5 className="subheading">
                      {t("Manufacturer Address :")}
                    </h5>
                    <p className="mb-0">
                      {addressData.manufactureraddress[0].dealercompany}
                    </p>
                    <p className="mb-0">
                      {addressData.manufactureraddress[0].dealername}
                    </p>
                    <p className="mb-0">
                      {addressData.manufactureraddress[0].dealerAddress}
                    </p>
                    <p className="mb-0">
                      {addressData.manufactureraddress[0].dealerCity}
                      {","}
                      {addressData.manufactureraddress[0].dealerState}
                      {","}
                      {addressData.manufactureraddress[0].dealerZIP}
                    </p>
                    <p className="mb-0">
                      {addressData.manufactureraddress[0].dealerCountry}
                    </p>
                    <p className="mb-0">
                      {t("T:")}
                      {addressData.manufactureraddress[0].dealerphone}
                    </p>
                    <p className="mb-0">
                      {t("Email:")}
                      {addressData.manufactureraddress[0].dealeremail}
                    </p>
                  </div>
                )
              : null}
            <div className="col-md-4">
              <div className="input__block">
                <label> {t("Account Number")}: </label>
                <input
                  type="text"
                  name="AccNo"
                  value={accNo}
                  className={error && "border__red"}
                  disabled
                />
                {error && (
                  <p className="error-color-red">
                    {t("Kindly Add Account Number First")}
                  </p>
                )}
              </div>
            </div>

            <div className="col-md-4">
              <div className="input__block mb-3">
                <label>
                  {t("Standard Carrier Alpha")} :{" "}
                  <span className="mandatory">*</span>
                </label>
                <Select
                  value={selectedSCA}
                  autocomplete="off"
                  name="SCAvalue"
                  showSearch
                  placeholder={t("Select SCA")}
                  onChange={(e) => handleSelectChange(e, "select", "SCAvalue")}
                  className={
                    dataToRender.length === 0 || listerr
                      ? "border__red"
                      : "border__grey"
                  }
                >
                  {dataToRender.map((item) => (
                    <Option key={item.scaid} value={item.scaid}>
                      {item.scacname}
                    </Option>
                  ))}
                </Select>
                {dataToRender.length === 0 && (
                  <p className="error-color-red">
                    {t(
                      "This vendor has not configured any SCA Codes. Please reach out to GemFind for assistance."
                    )}
                  </p>
                )}

                {listerr && (
                  <p className="error-color-red">
                    {t("Please Select Standard Carrier Alpha")}
                  </p>
                )}
              </div>
            </div>

            <div className="col-md-12">
              <p>{t("Order Notes:")}</p>
            </div>

            <div className="divForm">
              <Spin spinning={tableLoading}>
                <Table
                  columns={[
                    {
                      title: t("Product Description"),
                      dataIndex: "productName",
                      key: "productDesc",
                    },
                    {
                      title: t("SKU"),
                      dataIndex: "jewelryInventoryID",
                      key: "jewelryInventoryID",
                    },
                    {
                      title: t("Vendor SKU"),
                      dataIndex: "styleNumber",
                      key: "styleNumber",
                    },
                    {
                      title: t("Price"),
                      dataIndex: "wholesalePrice",
                      key: "wholesalePrice",
                    },
                    {
                      title: t("Qty"),
                      dataIndex: "quantity",
                      key: "quantity",
                    },
                    {
                      title: t("Subtotal"),
                      dataIndex: "totalEstimate",
                      key: "totalEstimate",
                      // render: (item, row) => {
                      //   let sum = Number(item.quantity * item.wholesalePrice);
                      //   return <p>{sum}</p>;
                      // },
                    },
                  ]}
                  dataSource={orderNotesData}
                  scroll={{ x: 600, y: 800 }}
                  pagination={false}
                />
              </Spin>
              <div className="d-flex justify-content-end">
                <div className="total__section p-3">
                  <p>
                    <span className="font__bold">
                      {t("Total Item:")}{" "}
                      {orderNotesData.reduce((accumulator, currentValue) => {
                        return accumulator + parseInt(currentValue.quantity);
                      }, 0)}
                    </span>
                  </p>
                  <p>
                    <span className="font__bold">{t("SubTotal")}:</span> $
                    {parseFloat(
                      orderNotesData.reduce((accumulator, currentValue) => {
                        return (
                          accumulator + parseFloat(currentValue.totalEstimate)
                        );
                      }, 0)
                    ).toFixed(2)}
                  </p>
                  <p>
                    <span className="font__bold">{t("PO Total")}:</span> $
                    {parseFloat(
                      orderNotesData.reduce((accumulator, currentValue) => {
                        return (
                          accumulator + parseFloat(currentValue.totalEstimate)
                        );
                      }, 0)
                    ).toFixed(2)}
                  </p>
                </div>
              </div>
              <p>{t("Vendor to provide shipping/insurance info.")}</p>
            </div>
          </div>

          {/* </Spin>    */}
        </div>
      </Modal>
      {/* End Modal */}
      {/* Address Modal */}
      <Modal
        title={t("Shipping Address")}
        className="modalconsupld__section"
        centered
        visible={addressModal}
        closable={false}
        footer={[
          <Popconfirm
            title={t("Are you sure?")}
            onConfirm={deleteLocationDataHandler}
          >
            <Button key="back">{t("Delete")}</Button>
          </Popconfirm>,
          <Button
            key="submit"
            type="primary"
            onClick={updateLocationHandler}
            loading={saveAddressLoading}
          >
            {t("Add Address")}
          </Button>,
        ]}
      >
        <div
          className="pendjewelreq__section form__fields magento__mapping permission__section"
          style={{ border: "none" }}
        >
          <div className="row">
            <div className="col-md-12 form__section">
              <div className="row">
                <div className="col-md-12">
                  <div className="input__block">
                    <label>
                      {" "}
                      {t("Address")}
                      <span className="mandatory">*</span>
                    </label>
                    <textarea
                      value={completeAddress}
                      onChange={(e) =>
                        formChangeHandler(e, "Address", "LocationAddressVal")
                      }
                    ></textarea>
                    {!completeAddress && validations.LocationAddressVal ? (
                      <div className="text-danger">
                        {validations.LocationAddressVal}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      {" "}
                      {t("City")}
                      <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      className={
                        (!selectedLocation.City ||
                          Object.keys(selectedLocation.City).length == 0) &&
                        validations.LocationCityVal
                          ? "border__red"
                          : ""
                      }
                      value={
                        typeof selectedLocation.City !== "object"
                          ? selectedLocation.City
                          : ""
                      }
                      onChange={(e) =>
                        formChangeHandler(e, "City", "LocationCityVal")
                      }
                    />
                    {validations.LocationCityVal ? (
                      <div className="text-danger">
                        {validations.LocationCityVal}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      {" "}
                      {t("State")}
                      <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      value={
                        typeof selectedLocation.State !== "object"
                          ? selectedLocation.State
                          : ""
                      }
                      className={
                        (!selectedLocation.State ||
                          Object.keys(selectedLocation.State).length == 0) &&
                        validations.LocationStateVal
                          ? "border__red"
                          : ""
                      }
                      onChange={(e) =>
                        formChangeHandler(e, "State", "LocationStateVal")
                      }
                    />
                    {validations.LocationStateVal ? (
                      <div className="text-danger">
                        {validations.LocationStateVal}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      {" "}
                      {t("Zip")}
                      <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      className={
                        (!selectedLocation.Zip ||
                          Object.keys(selectedLocation.Zip).length == 0) &&
                        validations.LocationZipVal
                          ? "border__red"
                          : ""
                      }
                      value={
                        typeof selectedLocation.Zip !== "object"
                          ? selectedLocation.Zip
                          : ""
                      }
                      onChange={(e) =>
                        formChangeHandler(e, "Zip", "LocationZipVal")
                      }
                    />
                    {validations.LocationZipVal ? (
                      <div className="text-danger">
                        {validations.LocationZipVal}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      {" "}
                      {t("Country")}
                      <span className="mandatory">*</span>
                    </label>
                    <Select
                      showSearch
                      className={
                        (!selectedLocation.Country ||
                          Object.keys(selectedLocation.Country).length == 0) &&
                        validations.LocationCountryVal
                          ? "border__red"
                          : "border__grey"
                      }
                      placeholder=""
                      optionFilterProp="children"
                      value={
                        typeof selectedLocation.Country !== "object"
                          ? selectedLocation.Country
                          : ""
                      }
                      onChange={(e) =>
                        formChangeHandler(e, "Country", "LocationCountryVal")
                      }
                    >
                      {countryDD.length > 0 &&
                        countryDD.map((item, index) => {
                          return (
                            <Option key={item.id} value={item.name}>
                              {item.name}
                            </Option>
                          );
                        })}
                    </Select>
                    {validations.LocationCountryVal ? (
                      <div className="text-danger">
                        {validations.LocationCountryVal}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      {" "}
                      {t("Location E-Mail")}
                      <span className="mandatory">*</span>
                    </label>
                    <input
                      type="email"
                      className={
                        (!selectedLocation.EmailID ||
                          Object.keys(selectedLocation.EmailID).length == 0) &&
                        validations.LocationEmailVal
                          ? "border__red"
                          : ""
                      }
                      value={
                        typeof selectedLocation.EmailID !== "object"
                          ? selectedLocation.EmailID
                          : ""
                      }
                      onChange={(e) =>
                        formChangeHandler(e, "EmailID", "LocationEmailVal")
                      }
                    />
                    {validations.LocationEmailVal ? (
                      <div className="text-danger">
                        {validations.LocationEmailVal}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label>
                      {" "}
                      {t("Location Phone")}
                      <span className="mandatory">*</span>
                    </label>
                    <input
                      type="text"
                      className={
                        (!selectedLocation.Phone ||
                          Object.keys(selectedLocation.Phone).length == 0) &&
                        validations.LocationPhoneVal
                          ? "border__red"
                          : ""
                      }
                      value={
                        typeof selectedLocation.Phone !== "object"
                          ? selectedLocation.Phone
                          : ""
                      }
                      onChange={(e) =>
                        formChangeHandler(e, "Phone", "LocationPhoneVal")
                      }
                    />
                    {validations.LocationPhoneVal ? (
                      <div className="text-danger">
                        {validations.LocationPhoneVal}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input__block">
                    <label> {t("Website")}</label>
                    <input
                      type="text"
                      className={
                        (!selectedLocation.Website ||
                          Object.keys(selectedLocation.Website).length == 0) &&
                        validations.LocationWebsiteVal
                          ? "border__red"
                          : ""
                      }
                      value={
                        typeof selectedLocation.Website !== "object"
                          ? selectedLocation.Website
                          : ""
                      }
                      onChange={(e) =>
                        formChangeHandler(e, "Website", "LocationWebsiteVal")
                      }
                    />
                    {validations.LocationWebsiteVal ? (
                      <div className="text-danger">
                        {validations.LocationWebsiteVal}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={t("Add Location Name")}
        className="modalconsupld__section"
        centered
        visible={locationModal}
        onCancel={closeLocationModal}
        footer={[
          <Button key="back" onClick={closeLocationModal}>
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={updateLocationNameHandler}
          >
            {t("Add Location")}
          </Button>,
        ]}
      >
        <div
          className="pendjewelreq__section form__fields magento__mapping permission__section"
          style={{ border: "none", padding: "0" }}
        >
          <div>
            <div className="col-12 m-0">
              <div className="input__block mb-0">
                <label>
                  {" "}
                  {t("Location Name")}
                  <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  value={updatedLocationName}
                  onChange={(e) => locationNameChangeHandler(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default WorkSheetDetails;
