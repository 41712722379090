import React, { useState, useEffect } from "react";
import { Checkbox, Table, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CompanyShowsService from "../../services/company-Shows.service";
import { NotificationManager } from "react-notifications";

const ManageCompanyShows = () => {
  const { t } = useTranslation();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [CompanyShowsTableList, setCompanyShowsTableList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showLoaderById, setShowLoaderById] = useState(false);
  const [checkboxData, setCheckboxData] = useState([]);
  const [dealerID, setDealerId] = useState("");
  const [recordByID, setrecordByID] = useState("");
  const [showsInputData, setShowsInputData] = useState({
    booth: "",
    notesandshow: "",
  });
  const [showsInputValidation, setShowsInputValidation] = useState({
    boothval: "",
  });
  const [showDetailsById, setShowDetailsById] = useState({
    imageShow: "",
    websiteShow: "",
    nameShow: "",
    idShow: "",
    masterShowId: "",
    ShowStartTime: "",
    ShowEndTime: "",
    StartDate: "",
    EndDate: "",
  });
  const [isShow, setIsShow] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          //   setDealerId(loginDetials.loginDetials.responseData.dealerId);
          //   handleGetForDealer(loginDetials.loginDetials.responseData.dealerId);
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetTableChartData(
            loginDetials.loginDetials.responseData.dealerId
          );
        }
      }
    }
  }, []);

  useEffect(() => {
    handleGetTableChartData(dealerID, pageNo, pageSize, sortColumn, sortOrder);
  }, [dealerID, pageNo, pageSize, sortColumn, sortOrder]);

  const handleGetTableChartData = (
    DealerId,
    pageNo,
    pageSize,
    sortColumn,
    sortOrder
  ) => {
    try {
      let inputData = {
        dealerID: DealerId.toString(),
        orderBy: "",
        pageNumber: pageNo !== undefined ? pageNo.toString() : "1",
        pageSize: pageSize !== undefined ? pageSize.toString() : "10",
        sortColumnName: sortColumn,
        sortOrder: sortOrder,
      };
      setShowLoader(true);
      CompanyShowsService.GetDealerShowsDetails(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData.Table;
          if (message === "Success") {
            if (responseData.length > 0) {
              setShowLoader(false);
              setCompanyShowsTableList(responseData);
              setCheckboxData([]);
              setIsShow(true);
              handleGetTableChartDataById(DealerId, responseData[0].ID);
            } else {
              setShowLoader(false);
              setIsShow(false);
              setCompanyShowsTableList([]);
              setCheckboxData([]);
            }
          } else {
            setIsShow(false);
            setShowLoader(false);
            setCompanyShowsTableList([]);
            setCheckboxData([]);
          }
        })
        .catch((error) => {
          setIsShow(false);
          setShowLoader(false);
          console.log(error);
        });
    } catch (error) {
      setIsShow(false);
      console.log(error);
      setShowLoader(false);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { field, order } = sorter;
    let newSortOrder = "";
    if (sortColumn === field && sortOrder === "asc") {
      newSortOrder = "desc";
    } else if (sortColumn === field && sortOrder === "desc") {
      newSortOrder = "";
    } else {
      newSortOrder = "asc";
    }
    setSortColumn(field);
    setSortOrder(newSortOrder);
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleGetTableChartDataById = (dealerID, record) => {
    try {
      let inputData = {
        dealerID: dealerID.toString(),
        showID: record.toString(),
      };
      setrecordByID(record);
      setShowLoaderById(true);
      CompanyShowsService.GetBindDealerShowDetailss(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData.dtDetails;
          if (message === "Success") {
            if (responseData.length > 0) {
              setShowDetailsById({
                imageShow: responseData[0].ShowsImagePath,
                websiteShow: responseData[0].website,
                nameShow: responseData[0].ShowName,
                idShow: responseData[0].ID,
                masterShowId: responseData[0].MasterShowID,
                ShowStartTime: responseData[0].ShowStartTime,
                ShowEndTime: responseData[0].ShowEndTime,
                StartDate: responseData[0].StartDate,
                EndDate: responseData[0].EndDate,
              });

              setShowsInputData({
                booth: responseData[0].BoothNumber,
                notesandshow: responseData[0].Notes,
              });
            }
            setShowLoaderById(false);
          } else {
            setShowLoaderById(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setShowLoaderById(false);
        });
    } catch (error) {
      console.log(error);
      setShowLoaderById(false);
    }
  };

  const handleValidationShows = () => {
    const { booth } = showsInputData;

    const showsInputValidation = {
      boothval: "",
    };
    let isValid = true;

    if (!booth.trim()) {
      isValid = false;
      showsInputValidation.boothval = t("Both Number can not be blank");
    } else {
      showsInputValidation.boothval = "";
    }
    setShowsInputValidation(showsInputValidation);
    return isValid;
  };

  const handleShowInputChange = (e, name, type) => {
    setShowsInputData({
      ...showsInputData,
      [name]: e.target.value,
    });
    if (e.target.name == "booth") {
      setShowsInputValidation((prevdata) => ({
        ...prevdata,
        boothval: "",
      }));
    }
  };

  const handleSaveDiamondView1 = async () => {
    var isValid = handleValidationShows();

    if (!isValid) {
      return false;
    }
    let inputData = {
      dealerID: dealerID.toString(),
      id: showDetailsById.idShow,
      masterShowID: showDetailsById.masterShowId,
      boothNumber: showsInputData.booth,
      startShowTime: showDetailsById.ShowStartTime,
      endShowTime: showDetailsById.ShowEndTime,
      showStartDate: showDetailsById.StartDate,
      showEndDate: showDetailsById.EndDate,
      notesAndSpecials: showsInputData.notesandshow,
    };
    // setButtonLoading(true);
    try {
      CompanyShowsService.UpdateDealerShowsDetails(inputData)
        .then((response) => {
          let msg = response.data.message;
          let responseData = response.data.responseData;
          if (msg == "Success") {
            NotificationManager.success(t("Record Added Successfully"));
            setShowsInputData({
              booth: "",
              notesandshow: "",
            });
            handleGetTableChartData(dealerID);
            handleGetTableChartDataById(dealerID, recordByID);
          } else {
            NotificationManager.error(t("Could Not Add Record"));
            setShowsInputData({
              booth: "",
              notesandshow: "",
            });
          }
        })
        .catch((error) => {
          NotificationManager.error(t("Could Not Add Record"));
          setShowsInputData({
            booth: "",
            notesandshow: "",
          });
          console.log(error);
        });
    } catch (error) {
      NotificationManager.error(t("Sorry we had an error"));
      setShowsInputData({
        booth: "",
        notesandshow: "",
      });
      console.log(error);
    }
  };

  const handleCheckAddShowToDealer = (id, showid) => {
    const inputData = {
      dealerID: id.toString(),
      showID: showid.toString(),
    };
    setShowLoader(true);
    CompanyShowsService.AddShowToDealer(inputData)
      .then((response) => {
        const msg = response.data.message;
        if (msg === "Success") {
          NotificationManager.success(t("Updated Successfully"));
          handleGetTableChartData(
            dealerID,
            pageNo,
            pageSize,
            sortColumn,
            sortOrder
          );
        } else {
          NotificationManager.error(t("Not Updated"));
        }
      })
      .catch((error) => {
        NotificationManager.error(t("Not Updated"));
        console.error(error);
      });
  };

  const handleUnCheckRemoveShowToDealer = (id, showid) => {
    const inputData = {
      dealerID: id.toString(),
      showID: showid.toString(),
    };
    setShowLoader(true);
    CompanyShowsService.RemoveShowToDealer(inputData)
      .then((response) => {
        const msg = response.data.message;
        if (msg === "Success") {
          NotificationManager.success(t("Updated Successfully"));
          handleGetTableChartData(
            dealerID,
            pageNo,
            pageSize,
            sortColumn,
            sortOrder
          );
        } else {
          NotificationManager.error(t("Not Updated"));
        }
      })
      .catch((error) => {
        NotificationManager.error(t("Not Updated"));
        console.error(error);
      });
  };

  const handleCheckChange = (e, id) => {
    if (e.target.checked == true) {
      let data = checkboxData;
      setCheckboxData((data) => [id, ...data]);
      handleCheckAddShowToDealer(dealerID, id);
    } else {
      let data = checkboxData;
      data = data.filter((e) => e !== id);
      setCheckboxData(data);
      handleUnCheckRemoveShowToDealer(dealerID, id);
    }
  };

  const handleOnLinkClick = (url) => {
    if (url.includes("https")) {
      window.open(url, "_blank");
    } else if (url.includes("http")) {
      window.open(url, "_blank");
    } else {
      window.open("https://" + url, "_blank");
    }
  };

  return (
    <React.Fragment>
      <div className="form__fields">
        <div className="row magento__mapping permission__section">
          <div className="row">
            <div className="col-md-12">
              <div className="heading__block">
                <h4 class="workarea__heading mt-0 mb-0">{t("Shows")}</h4>
                {/* <button className="primary-btn">
                                    {" "}
                                    <img src={Create_icon} alt="" /> Add User
                            </button> */}
              </div>
            </div>

            <div className="col-lg-9">
              <div className="shipping_tbldiv">
                <Table
                  columns={[
                    {
                      title: t("Active"),
                      dataIndex: "IsSelected",
                      width: 50,
                      render: (item, row) => {
                        return (
                          <div className="action__btns">
                            <Checkbox
                              onChange={(e) => handleCheckChange(e, row.ID)}
                              checked={row.IsSelected === 0 ? false : true}
                            />
                          </div>
                        );
                      },
                    },
                    {
                      title: t("Show"),
                      dataIndex: "ShowName",
                      width: 200,
                      onCell: (record, rowIndex) => {
                        return {
                          onClick: (event) => {
                            handleGetTableChartDataById(dealerID, record.ID);
                          },
                        };
                      },
                      sorter: true,
                    },
                    {
                      title: t("City"),
                      dataIndex: "City",
                      width: 100,
                      onCell: (record, rowIndex) => {
                        return {
                          onClick: (event) => {
                            handleGetTableChartDataById(dealerID, record.ID);
                          },
                        };
                      },
                      sorter: true,
                    },
                    {
                      title: t("State"),
                      dataIndex: "State",
                      width: 100,
                      onCell: (record, rowIndex) => {
                        return {
                          onClick: (event) => {
                            handleGetTableChartDataById(dealerID, record.ID);
                          },
                        };
                      },
                      sorter: true,
                    },
                    {
                      title: t("Dates"),
                      dataIndex: "Date",
                      width: 200,
                      onCell: (record, rowIndex) => {
                        return {
                          onClick: (event) => {
                            handleGetTableChartDataById(dealerID, record.ID);
                          },
                        };
                      },
                      sorter: true,
                    },
                    // {
                    //   title: t("Appointments"),
                    //   dataIndex: "Appointment",
                    //   width: 100,
                    //   onCell: (record, rowIndex) => {
                    //     return {
                    //       onClick: (event) => {
                    //         handleGetTableChartDataById(dealerID, record.ID);
                    //       }
                    //     };
                    //   }
                    // }
                  ]}
                  onChange={handleTableChange}
                  pagination={{
                    pageNo: pageNo,
                    pageSize: pageSize,
                    pageSizeOptions: [10, 20, 50, 100],
                    responsive: true,
                    showSizeChanger: true,
                    onChange: (page, pageSize) => {
                      setPageNo(page);
                      setPageSize(pageSize);
                    },
                  }}
                  dataSource={CompanyShowsTableList}
                  loading={showLoader}
                  scroll={{ x: 600, y: 800 }}
                />
              </div>
            </div>
            {isShow && (
              <div className="col-lg-3">
                <Spin spinning={showLoaderById}>
                  <div className="right__section">
                    <div className="profile__block">
                      <span className="anchorText">
                        {showDetailsById.nameShow
                          ? showDetailsById.nameShow
                          : ""}{" "}
                      </span>

                      <div className="logoImg__block p-0">
                        <img src={showDetailsById.imageShow} />
                      </div>
                    </div>
                    <div className="links__block">
                      <a
                        onClick={() =>
                          handleOnLinkClick(showDetailsById.websiteShow)
                        }
                        class="linkText"
                      >
                        {" "}
                        {showDetailsById.websiteShow
                          ? showDetailsById.websiteShow
                          : ""}
                      </a>
                    </div>
                    <div className="input__block">
                      <label>{t("Booth #(s)")}</label>
                      <input
                        type="text"
                        value={showsInputData.booth}
                        className={
                          showsInputValidation.boothval && "border__red"
                        }
                        onChange={(e) =>
                          handleShowInputChange(e, "booth", "input")
                        }
                      />
                      {showsInputValidation.boothval && (
                        <p className="error-color-red">
                          {showsInputValidation.boothval}
                        </p>
                      )}
                    </div>

                    <div className="input__block">
                      <label>{t("Notes & Show Specials")}</label>
                      <textarea
                        value={showsInputData.notesandshow}
                        onChange={(e) =>
                          handleShowInputChange(e, "notesandshow", "input")
                        }
                      ></textarea>
                    </div>
                    <button
                      className="primary-btn"
                      onClick={() => handleSaveDiamondView1()}
                    >
                      {t("Save Show Details")}
                    </button>
                  </div>
                </Spin>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManageCompanyShows;
