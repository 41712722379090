import React, { useEffect, useState } from "react";
import { Button, Modal, Table, Spin, Input } from "antd";
import Create_icon from "../../assets/images/create_icon.png";
import SCAMasterService from "../../services/setting-SCA.service";
import Edit_icon from "../../assets/images/icons/edit_icon.svg";
import Delete_iconbl from "../../assets/images/icons/delete_iconbl.svg";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const SCAMaster = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [validations, setValidations] = useState({
    scacodeError: "",
    scacnameError: "",
    routingError: "",
  });

  const [scaList, setScaList] = useState([]);
  const [isUpdateDeletId, setIsUpdateDeletId] = useState("");
  const [scacode, setScacode] = useState("");
  const [routing, setRouting] = useState("");
  const [scaname, setScaname] = useState("");

  const [dealerId, setDealerId] = useState(0);
  const loginDetails = useSelector((state) => state.loginReducer.loginDetials);
  // const [pageNo, setPageNo] = useState(1);
  // const [pageSize, setPageSize] = useState(10);
  // const [totalCount, setTotalCount] = useState(1000);

  useEffect(() => {
    if (loginDetails && loginDetails.responseData) {
      setDealerId(loginDetails.responseData.dealerId);
      handleGetSCAList();
    }
  }, [loginDetails]);

  const handleCancel = () => {
    setVisible(false);
    resetSCA();
  };

  const resetSCA = () => {
    setRouting("");
    setScacode("");
    setScaname("");
    setValidations({
      routing: "",
      scacname: "",
      scacode: "",
    });
  };

  // Handle form input change
  const handleOnChange = (e) => {
    const { name, value } = e.target;

    // Update the state dynamically based on the input field name
    if (name === "scaCode") {
      setScacode(value);
    } else if (name === "scaName") {
      setScaname(value);
    } else if (name === "routing") {
      setRouting(value);
    }
  };

  const handleValidation = () => {
    const validations = {
      scacodeError: "",
      scacnameError: "",
      routingError: "",
    };
    let isValid = true;

    if (!scacode.trim()) {
      isValid = false;
      validations.scacodeError = t("SCA Code is Compulsory.");
    }
    if (!scaname.trim()) {
      isValid = false;
      validations.scacnameError = t("SCA Name is Compulsory");
    }
    if (!routing.trim()) {
      isValid = false;
      validations.routingError = t("Routing is Compulsory.");
    }
    setValidations(validations);
    return isValid;
  };

  const handleGetSCAList = () => {
    setLoading(true);
    SCAMasterService.GetSCAList()
      .then((response) => {
        if (response.data.message === "Success") {
          setScaList(response.data.responseData || []);
        } else {
          NotificationManager.error(t("Fail To Fetch Data"));
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  // function handlePageNoChange(page, pageSize) {
  //   if (page !== pageNo) {
  //     setPageNo(page);
  //     handleGetOrganizationList(page, pageSize);
  //   }
  // }

  // function handlePageSizeChange(current, size) {
  //   setPageSize(size);
  //   handleGetOrganizationList(current, size);
  // }
  const handleAddSCA = () => {
    if (handleValidation()) {
      handleAddUpdateSCA();
    }
    setIsUpdateDeletId("");
  };

  const handleAddUpdateSCA = () => {
    const inputData = {
      ...(isUpdateDeletId && { id: isUpdateDeletId }),
      scaccode: scacode,
      scacname: scaname,
      routing: routing,
    };

    setSubmitLoading(true);
    SCAMasterService.AddUpdateSCA(inputData)
      .then((response) => {
        if (response.data.message === "Success") {
          NotificationManager.success(
            t(response.data.responseData.returnMessage)
          );
          handleGetSCAList();
          resetSCA();
          setVisible(false);
        } else {
          NotificationManager.error(t("Something Went Wrong !!"));
        }
        setSubmitLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSubmitLoading(false);
      });
  };

  const handleEditModal = (row) => {
    setVisible(true);
    setIsUpdateDeletId(row.id);
    setRouting(row.routing);
    setScacode(row.scaccode);
    setScaname(row.scacname);
  };
  const handleDeleteModal = (row) => {
    setVisible3(true);
    setIsUpdateDeletId(row.id);
  };

  const handleCancel3 = () => {
    setVisible3(false);
    setIsUpdateDeletId("");
  };
  const DeleteSCARecord = () => {
    let inputData = {
      id: isUpdateDeletId,
    };

    SCAMasterService.DeleteSCARecord(inputData)
      .then((response) => {
        var message = response.data.message;
        if (message === "Success") {
          NotificationManager.success(
            t(response.data.responseData.returnMessage)
          );
          handleGetSCAList();
        } else {
          NotificationManager.error(t("Data Not Deleted."));
        }
      })
      .catch((error) => {
        console.log(error);
      });

    setVisible3(false);
    setIsUpdateDeletId("");
  };

  return (
    <React.Fragment>
      <div className="tradeshow__section">
        <div className="form__fields">
          <div className="row">
            <div className="col-lg-12">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <h4 className="workarea__heading mt-0 mb-0">
                    {t("SCA List")}
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-3 mobile-m-0">
              <div className="row">
                <div className="col-lg-12 d-flex justify-content-end mobile-justify-content-start">
                  <Button
                    className="primary-btn"
                    onClick={() => setVisible(true)}
                  >
                    <img src={Create_icon} alt="Create" /> {t("Add SCA")}
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-12 mt-4 mobile-m-0">
              <div className="tradeshow_tbldiv">
                <Spin spinning={loading}>
                  <Table
                    columns={[
                      {
                        title: t("SCA Code"),
                        dataIndex: "scaccode",
                      },
                      {
                        title: t("SCA Name"),
                        dataIndex: "scacname",
                      },
                      {
                        title: t("Routing"),
                        dataIndex: "routing",
                      },
                      {
                        title: t("Action"),
                        fixed: "right",
                        width: 100,
                        render: (index, row) => (
                          <div className="action__btns">
                            <div className="image__block">
                              <img
                                src={Edit_icon}
                                onClick={() => {
                                  handleEditModal(row);
                                }}
                                alt=""
                              />
                            </div>
                            <div className="image__block">
                              <img
                                src={Delete_iconbl}
                                onClick={() => {
                                  handleDeleteModal(row);
                                }}
                                alt=""
                              />
                            </div>
                          </div>
                        ),
                      },
                    ]}
                    dataSource={scaList}
                    pagination={true}
                    scroll={{ x: 600, y: 800 }}
                  />
                  {/* <div className="col-md-12 mt-3" style={{ textAlign: "end" }}>
                    <Pagination
                      current={pageNo}
                      pageSize={pageSize}
                      total={totalCount}
                      onChange={handlePageNoChange}
                      onShowSizeChange={handlePageSizeChange}
                      showSizeChanger="true"
                    />
                  </div> */}
                </Spin>
              </div>
            </div>
            <Modal
              title={t("Add/Update Standard Carrier Alpha")}
              visible={visible}
              onOk={handleAddSCA}
              onCancel={handleCancel}
              footer={[
                <Button key="back" onClick={handleCancel}>
                  {t("Cancel")}
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={submitLoading}
                  onClick={handleAddSCA}
                >
                  {t("Save SCA")}
                </Button>,
              ]}
            >
              <div className="col-lg-12">
                <div className="modal__body">
                  <div className="col-lg-12">
                    <div className="form__fields border-0 p-0">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="input__block">
                            <label>
                              {t("Standard Carrier Alpha Code")}
                              <span>*</span>
                            </label>
                            <Input
                              name="scaCode"
                              value={scacode}
                              onChange={handleOnChange}
                              maxLength={4}
                              placeholder={t(
                                "Enter Standard Carrier Alpha Code"
                              )}
                              className={
                                validations.scacodeError && "border__red"
                              }
                            />
                            {validations.scacodeError && (
                              <p className="error-color-red">
                                {validations.scacodeError}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input__block">
                            <label>
                              {t("Standard Carrier Alpha Name")}
                              <span>*</span>
                            </label>
                            <Input
                              name="scaName"
                              value={scaname}
                              onChange={handleOnChange}
                              placeholder={t(
                                "Enter Standard Carrier Alpha Name"
                              )}
                              className={
                                validations.scacnameError && "border__red"
                              }
                            />
                            {validations.scacnameError && (
                              <p className="error-color-red">
                                {validations.scacnameError}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="input__block">
                            <label>
                              {t("Routing")}
                              <span>*</span>
                            </label>
                            <textarea
                              name="routing"
                              value={routing}
                              onChange={handleOnChange}
                              placeholder={t("Enter Routing")}
                              className={
                                validations.routingError && "border__red"
                              }
                            />
                            {validations.routingError && (
                              <p className="error-color-red">
                                {validations.routingError}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>

            {/* Delete Modal */}
            <Modal
              title={t("Delete")}
              className="modalconsupld__section"
              centered
              visible={visible3}
              onOk={() => setVisible3(false)}
              onCancel={() => setVisible3(false)}
              width={400}
              footer={[
                <Button key="back" onClick={handleCancel3}>
                  {t("Cancel")}
                </Button>,
                <Button key="submit" type="primary" onClick={DeleteSCARecord}>
                  {t("Delete")}
                </Button>,
              ]}
            >
              <div className="col-lg-12">
                <div className="modal__body">
                  <div className="col-lg-12">
                    <div className="form__fields border-0 p-0">
                      <div className="row">
                        <div className="col-lg-12 text-center">
                          <p>{t("Are you sure you want to delete")}</p>
                          <h5>{t("THIS CAN NOT BE UNDONE")}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SCAMaster;
