import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button, Table, Modal, Spin, Select, Input } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PurchaseOrderServices from "../../services/purchaseOrder.service";
import { NotificationManager } from "react-notifications";
import AdminManageLanguageService from "../../services/admin-manageLanguage.service";
const { Option } = Select;

export const PurchaseOrder = (props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [purchaseOrders, SetPurchaseOrder] = useState([]);
  const [dealerID, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [vendorType, setVendorType] = useState("");
  const [generateOrderModalVisible, setgenerateOrderModalVisible] =
    useState(false);
  const [cancelOrederData, setCancelOrederData] = useState({
    orderID: "",
    poName: "",
    status: 0,
  });
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [reorderData, setReorderData] = useState({
    PoName: "",
    notes: "",
    referenceNumber: "",
  });
  const [purchaseOrdersProductDetails, SetPurchaseOrderProductDetails] =
    useState([]);
  const [purchaseOrdersDtRetailer, SetPurchaseOrderDtRetailer] = useState([]);
  const [purchaseOrdersDtVendor, SetPurchaseOrderDtVendor] = useState([]);
  const [purchaseOrdersProductPrice, SetPurchaseOrderProductPrice] = useState(
    {}
  );
  const [historyLoading, setHistoryLoading] = useState(false);
  const [validation, setValidations] = useState({
    PONameVal: "",
    notesVal: "",
  });
  const [scaList, setScaList] = useState([]);
  const [selectedSCA, setSelectedSCA] = useState("");
  const [accNo, setAccNo] = useState("");
  const [error, setError] = useState(false);
  const [listerr, setListerr] = useState(false);
  const [cancelOrderVisible, setCancelOrderVisible] = useState(true);
  const [isCancelOrderDisabled, setIsCancelOrderDisabled] = useState(false);
  const { Search } = Input;
  const [searchValue, SetSearchValue] = useState("");
  const filteredData = scaList.filter((item) => item.isVendorapprove);
  const dataToRender = filteredData.length > 0 ? filteredData : scaList;
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          if (
            loginDetials.loginDetials.responseData.dealerMemberTypeName ==
            "Retailer"
          ) {
            GetMyPurchaseOrderDetails(
              loginDetials.loginDetials.responseData.dealerId,
              "",
              ""
            );
          } else {
            GetMyPurchaseOrderDetails(
              loginDetials.loginDetials.responseData.dealerId,
              loginDetials.loginDetials.responseData.dealerMemberTypeName,
              ""
            );
            setVendorType(
              loginDetials.loginDetials.responseData.dealerMemberTypeName
            );
          }
        }
      }
    }
  }, []);

  const handleCancel = () => {
    setVisible(false);
    setReorderData({
      PoName: "",
      notes: "",
      referenceNumber: "",
    });
    setValidations({
      PONameVal: "",
      notesVal: "",
    });
  };
  const onSearch = (value) => {
    SetSearchValue(value);
    GetMyPurchaseOrderDetails(dealerID, vendorType, value);
  };
  const handleCancel1 = () => {
    setgenerateOrderModalVisible(false);
    setListerr(false);
  };
  const handleOk = () => {
    setgenerateOrderModalVisible(true);
    GetMyPurchaseOrderHistoryDetails(dealerID);
  };
  const handleGetSCAList = async (id) => {
    try {
      await AdminManageLanguageService.getSCADrpdown(id)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setScaList(responseData || []);
            }
          } else {
            setScaList([]);
          }
        })
        .catch((error) => {
          setScaList([]);
        });
    } catch (error) {
      setScaList([]);
    }
  };
  const handleSelectChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setSelectedSCA(e);
    }
  };

  const handleCancelOrder = () => {
    if (cancelOrederData.status === 1) {
      setIsCancelOrderDisabled(true);
    } else {
      setIsCancelOrderDisabled(false);
      if (purchaseOrders.length > 0) {
        setCancelModalVisible(true);
      } else {
        NotificationManager.error(t("No Records Available"));
      }
    }
  };

  const handleCancelModal = () => {
    setCancelModalVisible(false);
  };

  // to create Unique Po Number
  const handleCreatePoNo = () => {
    let inputData = {
      dealerID: dealerID ? dealerID.toString() : "",
    };
    try {
      PurchaseOrderServices.CreatePONumber(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setReorderData({
              PoName: responseData,
            });
          } else {
            setReorderData({
              PoName: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setReorderData({
            PoName: "",
          });
        });
    } catch (error) {
      console.log(error);
      setReorderData({
        PoName: "",
      });
    }
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setReorderData({
        ...reorderData,
        [name]: e,
      });
    } else {
      setReorderData({
        ...reorderData,
        [e.target.name]: e.target.value,
      });

      if (e.target.name == "PoName") {
        setValidations((prevdata) => ({
          ...prevdata,
          PONameVal: "",
        }));
      }
      if (e.target.name == "notes") {
        setValidations((prevdata) => ({
          ...prevdata,
          notesVal: "",
        }));
      }
    }
  };

  const handleValidation = () => {
    const { PoName, notes } = reorderData;

    const validation = {
      PONameVal: "",
      notesVal: "",
    };
    let isValid = true;

    if (!PoName.trim()) {
      isValid = false;
      validation.PONameVal = t("PO Name is Compulsory");
    } else {
      validation.PONameVal = "";
    }

    setValidations(validation);
    return isValid;
  };

  const GetMyPurchaseOrderDetails = (id, vendorType, value) => {
    let inputData = {
      delarId: id,
      type: vendorType ? vendorType : "",
      search: value ? value : "",
    };
    setLoading(true);
    try {
      PurchaseOrderServices.GetMyPurchaseOrder(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            SetPurchaseOrder(responseData);
            setCancelOrederData({
              orderID: responseData[0].poid,
              poName: responseData[0].poName,
              status: responseData[0].status,
            });
            if (responseData[0].poid != "") {
              setCancelOrderVisible(false);
            }
            setLoading(false);
          } else {
            SetPurchaseOrder([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleUpdateOrderStatus = () => {
    let inputData = {
      dealerID: dealerID.toString(),
      orderID: cancelOrederData.orderID.toString(),
      status: "2",
    };

    try {
      PurchaseOrderServices.UpdateOrderStatus(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setCancelModalVisible(false);
            NotificationManager.success(
              t("Order Has Been Successfully Cancelled.")
            );
            GetMyPurchaseOrderDetails(dealerID, vendorType, "");
          } else {
            setCancelModalVisible(false);
            NotificationManager.error(t("Order Has Not Been Cancelled."));
          }
        })
        .catch((error) => {
          console.log(error);
          setCancelModalVisible(false);
        });
    } catch (error) {
      console.log(error);
      setCancelModalVisible(false);
    }
  };

  const GetMyPurchaseOrderHistoryDetails = (id) => {
    let inputData = {
      dealerID: id,
      orderID: cancelOrederData.orderID,
      pOname: "",
      type: "",
    };
    setHistoryLoading(true);
    try {
      PurchaseOrderServices.purchaseorderhistotry(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            SetPurchaseOrderProductDetails(responseData.productDetail);
            SetPurchaseOrderDtRetailer(responseData.dtRetailer[0]);
            SetPurchaseOrderDtVendor(responseData.dtVendor[0]);
            SetPurchaseOrderProductPrice(responseData.productprice);
            setHistoryLoading(false);
            handleGetSCAList(responseData.purchaseOrder[0].vendorID);
            setAccNo(responseData.purchaseOrder[0].ediAccNumber);
            if (
              responseData.purchaseOrder[0].ediAccNumber == "" ||
              responseData.purchaseOrder[0].ediAccNumber === null
            ) {
              setError(true);
            } else {
              setError(false);
            }
          } else {
            SetPurchaseOrderProductDetails([]);
            SetPurchaseOrderDtRetailer([]);
            SetPurchaseOrderDtVendor([]);
            SetPurchaseOrderProductPrice({});
            setHistoryLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setHistoryLoading(false);
        });
    } catch (error) {
      console.log(error);
      setHistoryLoading(false);
    }
  };

  const handleCreateReOrder = () => {
    var isValid = handleValidation();

    if (!isValid) {
      handleCancel1();
      return false;
    } else if (selectedSCA <= 0) {
      setListerr(true);
      return;
    }

    let inputData = {
      dealerID: dealerID.toString(),
      poid: cancelOrederData.orderID.toString(),
      poName: reorderData.PoName ? reorderData.PoName.toString() : "",
      poNote: reorderData.notes ? reorderData.notes.toString() : "",
      referenceNumber: reorderData.referenceNumber
        ? reorderData.referenceNumber.toString()
        : "",
      scaccode: selectedSCA ? selectedSCA.toString() : "",
    };
    setSubmitLoading(true);
    try {
      PurchaseOrderServices.CreateReOrder(inputData)
        .then((response) => {
          setSubmitLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData == "Please Enter Acc No for this Vendor") {
              NotificationManager.error(
                t("Kindly Add Account Number for this Vendor.")
              );
              setgenerateOrderModalVisible(false);
            } else if (responseData == "Vendor Retailer Not connected") {
              NotificationManager.error(
                t(
                  "Your connection to this vendor is no longer active. Please request access to this vendor again before placing orders. If you believe this is a mistake please contact GemFind support."
                )
              );
              setgenerateOrderModalVisible(false);
            } else {
              NotificationManager.success(
                t("Order Has Been Successfully ReOrder.")
              );
              GetMyPurchaseOrderDetails(dealerID, vendorType, "");
              setgenerateOrderModalVisible(false);
              setVisible(false);
              setSubmitLoading(true);
              handleCancel();
            }
          } else {
            NotificationManager.error(t("Order Has Not Been Cancelled."));
            setSubmitLoading(true);
            handleCancel();
          }
        })
        .catch((error) => {
          console.log(error);
          setSubmitLoading(true);
          handleCancel();
        });
    } catch (error) {
      console.log(error);
      setSubmitLoading(true);
    }
  };

  const RetailerColumns = [
    {
      title: t("Purchase Order"),
      dataIndex: "poName",
      key: "poName",
      render: (item, row) => {
        return (
          <Link
            to={"/purchaseOrderDetails"}
            state={{ POOrderID: row.poid, POOrderName: row.poName }}
          >
            {" "}
            <span className="linkText">{row.poName}</span>{" "}
          </Link>
        );
      },
    },
    {
      title: t("Reference Number"),
      dataIndex: "referenceNumber",
    },
    {
      title: t("Vendor Name"),
      dataIndex: "vendorName",
    },
    {
      title: t("Total items"),
      dataIndex: "variation",
      key: "variation",
    },
    {
      title: t("Total Cost"),
      dataIndex: "poTotal",
      render: (item, row) => {
        return <span>${row.poTotal}</span>;
      },
    },
    {
      title: t("Updated"),
      dataIndex: "lastModifiedDate",
      render: (item, row) => {
        return (
          <span> {moment(item.lastModifiedDate).format("MM/DD/yyyy")}</span>
        );
      },
    },
    {
      title: t("Status"),
      dataIndex: "status",
      render: (item, row) => {
        return item == 2
          ? "Cancelled"
          : item == 1
          ? "Completed"
          : item == 0
          ? "Submited"
          : item == 3
          ? "Open"
          : item == 4
          ? "Shipped"
          : item == 5
          ? "Unpaid"
          : "";
      },
    },
  ];

  const VendorColumns = [
    {
      title: t("Purchase Order"),
      dataIndex: "poName",
      key: "poName",
      render: (item, row) => {
        return (
          <Link
            to={"/purchaseOrderDetails"}
            state={{ POOrderID: row.poid, POOrderName: row.poName }}
          >
            <span className="linkText">{row.poName}</span>
          </Link>
        );
      },
    },
    {
      title: t("Reference Number"),
      dataIndex: "referenceNumber",
    },
    {
      title: t("Vendor Name"),
      dataIndex: "vendorName",
    },
    {
      title: t("Total items"),
      dataIndex: "variation",
      key: "variation",
    },
    {
      title: t("Total Cost"),
      dataIndex: "poTotal",
      render: (item, row) => {
        return <span>${row.poTotal}</span>;
      },
    },
    {
      title: t("Updated"),
      dataIndex: "lastModifiedDate",
      key: "lastModifiedDate",
    },
    {
      title: t("Status"),
      dataIndex: "status",
      render: (item, row) => {
        return item == 2
          ? "Cancelled"
          : item == 1
          ? "Completed"
          : item == 0
          ? "Pending"
          : item == 3
          ? "Open"
          : item == 5
          ? "Unpaid"
          : item == 4
          ? "Shipped"
          : "";
      },
    },
  ];

  return (
    <div className="form__fields">
      <div className="row magento__mapping">
        <div className="col-md-12">
          <div className="heading__block">
            <h4 class="workarea__heading mt-0 mb-0"> {t("Purchase Order")} </h4>
            <div className="d-flex w-40 justify-content-end mobile-justify-content-start">
              {loginDetials.loginDetials.responseData.dealerMemberTypeName ==
                "Retailer" || vendorType == "" ? (
                <Button
                  disabled={cancelOrderVisible}
                  className={
                    cancelOrederData.orderID == ""
                      ? "disabled-btn mr-2"
                      : "primary-btn mr-2"
                  }
                  onClick={() => {
                    setVisible(true);
                    handleCreatePoNo();
                  }}
                >
                  {t("Reorder PO")}
                </Button>
              ) : null}

              <Link
                to={"/purchaseOrderDetails"}
                state={{
                  POOrderID: cancelOrederData.orderID,
                  POOrderName: cancelOrederData.poName,
                }}
              >
                <Button
                  disabled={cancelOrederData.orderID == "" ? true : false}
                  className={
                    cancelOrederData.orderID == ""
                      ? "disabled-btn mr-2"
                      : "primary-btn mr-2"
                  }
                >
                  {t("View Order")}
                </Button>
              </Link>
              {loginDetials.loginDetials.responseData.dealerMemberTypeName ===
                "Retailer" || vendorType === "" ? (
                <Button
                  disabled={
                    cancelOrederData.status === 2 ||
                    cancelOrederData.status === 1
                      ? true
                      : false
                  }
                  className={
                    cancelOrederData.orderID === "" ||
                    cancelOrederData.status === 2 ||
                    cancelOrederData.status === 1
                      ? "disabled-btn"
                      : "primary-btn"
                  }
                  onClick={handleCancelOrder}
                >
                  {t("Cancel Order")}
                </Button>
              ) : (
                <Button
                  disabled={
                    cancelOrederData.status === 2 ||
                    cancelOrederData.status === 1
                      ? true
                      : false
                  }
                  className={
                    cancelOrederData.status === 2 ||
                    cancelOrederData.status === 1
                      ? "disabled-btn"
                      : "primary-btn"
                  }
                  onClick={handleCancelOrder}
                >
                  {t("Cancel Order")}
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="search__bar">
            <Search
              placeholder={t("Search")}
              allowClear
              onSearch={onSearch}
              style={{
                width: 220,
              }}
            />
          </div>
        </div>
        <div className="divForm">
          <Spin spinning={loading}>
            <Table
              columns={
                loginDetials.loginDetials.responseData.dealerMemberTypeName ==
                  "Retailer" || vendorType == ""
                  ? RetailerColumns
                  : VendorColumns
              }
              dataSource={purchaseOrders}
              scroll={{ x: 600, y: 800 }}
              onRow={(record, recordIndex) => ({
                onClick: (event) => {
                  setCancelOrederData({
                    orderID: record.poid,
                    poName: record.poName,
                    status: record.status,
                  });
                },
              })}
              rowClassName={(record) =>
                record.poid == cancelOrederData.orderID
                  ? "row-active-highlight"
                  : ""
              }
            />
          </Spin>
        </div>
      </div>
      <Modal
        title={t("Reorder")}
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t("Cancel")}
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            {t("Generate PO")}
          </Button>,
        ]}
        width={400}
      >
        <div className="form__fields p-0 border-0">
          <div className="input__block">
            <label>{t("PO Name")}</label>
            <input
              type="text"
              name="PoName"
              value={reorderData.PoName}
              onChange={handleOnChange}
              className={validation.PONameVal && "border__red"}
              disabled
            />
            {validation.PONameVal && (
              <p className="error-color-red">{validation.PONameVal}</p>
            )}
          </div>
          <div className="input__block">
            <label>{t("Reference Number")}</label>
            <input
              type="text"
              name="referenceNumber"
              value={reorderData.referenceNumber}
              onChange={handleOnChange}
              className={validation.PONameVal && "border__red"}
            />
            {/* {validation.PONameVal && (
              <p className="error-color-red">{validation.PONameVal}</p>
            )} */}
          </div>

          <div className="input__block">
            <label>{t("Notes")}</label>
            <textarea
              name="notes"
              value={reorderData.notes}
              onChange={handleOnChange}
              className={validation.notesVal && "border__red"}
            ></textarea>
            {validation.notesVal && (
              <p className="error-color-red">{validation.notesVal}</p>
            )}
          </div>
        </div>
      </Modal>

      {/* Generate PO Modal */}

      <Modal
        title={t("Purchase Order Summary")}
        centered
        visible={generateOrderModalVisible}
        onOk={handleCreateReOrder}
        onCancel={handleCancel1}
        footer={[
          <Button key="back" onClick={handleCancel1}>
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleCreateReOrder}
            loading={submitLoading}
            disabled={dataToRender.length === 0}
          >
            {t("Submit PO")}
          </Button>,
        ]}
        width={1000}
      >
        <div className="form__fields">
          <Spin spinning={historyLoading}>
            <div className="row magento__mapping">
              <div className="col-md-4 mb-4">
                <h5 className="subheading">{t("Shipping Address")} :</h5>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealercompany
                    ? purchaseOrdersDtRetailer.dealercompany
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealername
                    ? purchaseOrdersDtRetailer.dealername
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerAddress
                    ? purchaseOrdersDtRetailer.dealerAddress
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerCity
                    ? purchaseOrdersDtRetailer.dealerCity
                    : "null"}
                  ,{" "}
                  {purchaseOrdersDtRetailer.dealerState
                    ? purchaseOrdersDtRetailer.dealerState
                    : "null"}
                  ,
                  {purchaseOrdersDtRetailer.dealerZIP
                    ? purchaseOrdersDtRetailer.dealerZIP
                    : "null"}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerCountry
                    ? purchaseOrdersDtRetailer.dealerCountry
                    : ""}
                </p>
                <p className="mb-0">
                  {t("T")}:
                  {purchaseOrdersDtRetailer.dealerphone
                    ? purchaseOrdersDtRetailer.dealerphone
                    : ""}
                </p>
                <p className="mb-0">
                  {t("Email")}:{" "}
                  {purchaseOrdersDtRetailer.dealeremail
                    ? purchaseOrdersDtRetailer.dealeremail
                    : ""}
                </p>
              </div>

              <div className="col-md-4 mb-4">
                <h5 className="subheading">{t("Manufacturer Address")} :</h5>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealercompany
                    ? purchaseOrdersDtVendor.dealercompany
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealername
                    ? purchaseOrdersDtVendor.dealername
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerAddress
                    ? purchaseOrdersDtVendor.dealerAddress
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerCity
                    ? purchaseOrdersDtVendor.dealerCity
                    : "null"}
                  ,{" "}
                  {purchaseOrdersDtVendor.dealerState
                    ? purchaseOrdersDtVendor.dealerState
                    : "null"}
                  ,
                  {purchaseOrdersDtVendor.dealerZIP
                    ? purchaseOrdersDtVendor.dealerZIP
                    : "null"}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerCountry
                    ? purchaseOrdersDtVendor.dealerCountry
                    : ""}
                </p>
                <p className="mb-0">
                  {t("T")}:
                  {purchaseOrdersDtVendor.dealerphone
                    ? purchaseOrdersDtVendor.dealerphone
                    : ""}
                </p>
                <p className="mb-0">
                  {t("Email")}:{" "}
                  {purchaseOrdersDtVendor.dealeremail
                    ? purchaseOrdersDtVendor.dealeremail
                    : ""}
                </p>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="input__block">
                    <label> {t("Account Number")}: </label>
                    <input
                      type="text"
                      name="AccNo"
                      value={accNo}
                      className={error && "border__red"}
                      disabled
                    />
                    {error && (
                      <p className="error-color-red">
                        {t("Kindly Add Account Number First")}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="input__block mb-3">
                    <label>
                      {t("Standard Carrier Alpha")} :{" "}
                      <span className="mandatory">*</span>
                    </label>{" "}
                    <Select
                      value={selectedSCA}
                      autocomplete="off"
                      name="SCAvalue"
                      showSearch
                      placeholder={t("Select SCA")}
                      onChange={(e) =>
                        handleSelectChange(e, "select", "SCAvalue")
                      }
                      className={
                        dataToRender.length === 0 || listerr
                          ? "border__red"
                          : "border__grey"
                      }
                    >
                      {dataToRender.map((item) => (
                        <Option key={item.scaid} value={item.scaid}>
                          {item.scacname}
                        </Option>
                      ))}
                    </Select>
                    {dataToRender.length === 0 && (
                      <p className="error-color-red">
                        {t(
                          "This vendor has not configured any SCA Codes. Please reach out to GemFind for assistance."
                        )}
                      </p>
                    )}
                    {listerr && (
                      <p className="error-color-red">
                        {t("Please Select Standard Carrier Alpha")}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <p>
                  {t("Order Notes")}:{" "}
                  {purchaseOrdersProductPrice.poName
                    ? purchaseOrdersProductPrice.poName
                    : ""}
                </p>
              </div>

              <div className="divForm">
                <Table
                  columns={[
                    {
                      title: t("Item"),
                      dataIndex: "id",
                      key: "id",
                      width: 100,
                    },
                    {
                      title: t("Product Description"),
                      dataIndex: "productDesc",
                      key: "productDesc",
                    },
                    {
                      title: t("SKU"),
                      dataIndex: "retailerStockNumber",
                      key: "retailerStockNumber",
                    },
                    {
                      title: t("Vendor SKU"),
                      dataIndex: "retailerStyle",
                      key: "retailerStyle",
                    },
                    {
                      title: t("Price"),
                      dataIndex: "strPurchasePrice",
                      key: "strPurchasePrice",
                    },
                    {
                      title: t("Qty"),
                      dataIndex: "quantity",
                      key: "quantity",
                    },
                    {
                      title: t("SubTotal"),
                      dataIndex: "strEXTWholeSalePrice",
                      key: "strEXTWholeSalePrice",
                    },
                  ]}
                  dataSource={purchaseOrdersProductDetails}
                  scroll={{ x: 600, y: 350 }}
                  pagination={false}
                />

                <div className="d-flex justify-content-end">
                  <div className="total__section p-3">
                    <p>
                      <span className="font__bold">{t("Total Item")}:</span>
                      {purchaseOrdersProductPrice.totalQuantity
                        ? purchaseOrdersProductPrice.totalQuantity
                        : 0}
                    </p>
                    <p>
                      <span className="font__bold">{t("SubTotal")}:</span> $
                      {purchaseOrdersProductPrice.poTotal
                        ? parseFloat(
                            purchaseOrdersProductPrice.poTotal
                          ).toFixed(2)
                        : "0.00"}
                    </p>
                    <p>
                      <span className="font__bold">{t("PO Total")}:</span> $
                      {purchaseOrdersProductPrice.poTotal
                        ? parseFloat(
                            purchaseOrdersProductPrice.poTotal
                          ).toFixed(2)
                        : "0.00"}
                    </p>
                  </div>
                </div>
                <p>{t("Vendor to provide shipping/insurance info.")}</p>
              </div>
            </div>
          </Spin>
        </div>
      </Modal>
      {/* End Modal */}

      {/* Cancel Order Modal */}
      <Modal
        className="delete_conv_modal"
        centered
        visible={cancelModalVisible}
        onCancel={handleCancelModal}
        width={400}
        footer={[
          <Button type="primary" onClick={handleUpdateOrderStatus}>
            {t("Cancel Order")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <h5 className="reset-pass-conf-heading">
                      {t("Cancel The")} "{cancelOrederData.poName}" {t("Order")}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* End Cancel Order Modal */}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrder);
