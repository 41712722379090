import React, { useState, useEffect } from "react";
import { Select, Button, Tooltip, Modal, Spin } from "antd";

import userIcon from "../../../assets/images/user-icon.png";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import Create_icon from "../../../assets/images/create_icon.png";

import { NotificationManager } from "react-notifications";
import CatalogDiamondDataService from "../../../services/catalog-diamond.service";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DownloadBox from "../../../assets/images/download_sample_icon.png";
import HelpBook from "../../../assets/images/help_docs.png";
import idexLogo from "../../../assets/images/idex_logo.png";
import polygonLogo from "../../../assets/images/polygon_logo.png";
import rapnetLogo from "../../../assets/images/rapnet_logo.png";

import ExcellFormatIcon from "../../../assets/images/excelFormatIcon.png";
import { BlobToDownloadFile } from "../../../helper/commanFunction";
import axios from "axios";
import config from "../../../helper/config";

const UploadDiamondFile = () => {
  const { t } = useTranslation();
  const { Option } = Select;

  const loadImg = (
    <span>
      If you want to add jewelry images direct from server like:
      https://www.gemfind.com/zoomimages/80785.jpg then mark this checkbox as
      checked. Also verify that you have also set column IsImageFromUrl in your
      file. If you have set 1 to this column then image will load direct from
      server and if you have set 0 then imagepath will be treated as simple
    </span>
  );

  const replaceDetail = (
    <span>
      {" "}
      {t(
        "Inventory with Dealer Stock # not included in the upload will be deleted."
      )}{" "}
    </span>
  );

  const addDetail = (
    <span>
      {" "}
      {t(
        "Inventory with New Dealer Stock # will be added and existing Dealer Stock # will be updated. Dealer Stock # not included will remain untouched."
      )}{" "}
    </span>
  );

  /*Custom Binding Starts*/
  const [submitLoading1, setSubmitLoading1] = useState(false);
  const [submitLoading2, setSubmitLoading2] = useState(false);
  const [submitLoading3, setSubmitLoading3] = useState(false);
  const [submitLoading4, setSubmitLoading4] = useState(false);

  const [dealerId, setdealerId] = useState(0);
  const [columnId, setcolumnId] = useState(14);
  const location = useLocation();
  const loginDetials = useSelector((state) => state.loginReducer.loginDetials);
  const [uploadCertVisible, setUploadCertVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [visible6, setVisible6] = useState(false);
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);

  useEffect(() => {
    if (location.state) {
      if (location.state.dealerData) {
        setdealerId(location.state.dealerData.dealerID);
      }
    } else {
      if (loginDetials) {
        if (loginDetials.responseData) {
          setdealerId(loginDetials.responseData.dealerId);

          handleGet3rdPartyProviderHeader(
            loginDetials.responseData.dealerId,
            ""
          );
        }
      }
    }
  }, []);
  const initialState = {
    ImagefileName: null,
    Imagefile: [],
    DatafileName: null,
    Datafile: [],
    Delimiter: "COMMA",
    IsServer: false,
    rbtnRemove: true,
    filetype: null,
    Error: {},
    UploadCertFileName: null,
    UploadCertFile: null,
    UploadCertFileType: null,
  };
  const [state, setState] = useState(initialState);
  const [thirdPartyData, setThirdPartyData] = useState({
    linkSelect: "",
    linkText: "",
    directLinkUrl: "",
    polygonFileName: "69.65.43.23",
    idexLinkUrl: "",
    rapnetLinkUrl: "",
    rapnetlinkUserName: "",
    rapnetlinkPassword: "",
    rapnetlinkCheckBox: false,
    idexUsername: "",
    idexPassword: "",
  });

  const [validations1, setValidations1] = useState({
    idexLinkUrlValue: "",
  });

  const [validations2, setValidations2] = useState({
    rapnetLinkUrlValue: "",
  });

  const [validations3, setValidations3] = useState({
    polygonFileNameValue: "",
  });

  const handleValidationLink = () => {
    const { idexLinkUrl } = thirdPartyData;

    const validations1 = {
      idexLinkUrlValue: "",
    };

    let isValid = true;

    if (!idexLinkUrl) {
      isValid = false;
      validations1.idexLinkUrlValue = t("Please Enter Link");
    } else {
      validations1.idexLinkUrlValue = "";
    }

    setValidations1(validations1);
    return isValid;
  };

  const handlePassShowHide = () => {
    setShowPassword(!showPassword);
  };

  const handleValidationRapnet = () => {
    const { rapnetLinkUrl } = thirdPartyData;

    const validations2 = {
      rapnetLinkUrlValue: "",
    };

    let isValid = true;

    if (!rapnetLinkUrl) {
      isValid = false;
      validations2.rapnetLinkUrlValue = t("Please Enter Link");
    } else {
      validations2.rapnetLinkUrlValue = "";
    }

    setValidations2(validations2);
    return isValid;
  };

  const handleValidationPoly = () => {
    const { polygonFileName } = thirdPartyData;

    const validations3 = {
      polygonFileNameValue: "",
    };

    let isValid = true;

    if (!polygonFileName) {
      isValid = false;
      validations3.polygonFileNameValue = t("Please Enter Text");
    } else {
      validations3.polygonFileNameValue = "";
    }

    setValidations3(validations3);
    return isValid;
  };

  const handleCheckBoxChange = (e, name) => {
    setThirdPartyData({
      ...thirdPartyData,
      [name]: e.target.checked,
    });
  };
  const fileUpload = async (e, name) => {
    let tempState = state;
    let allFiles = [];
    let selectedFiles = e.target.files[0];
    if (name == "Image") {
      if (e.target.files[0].name.match(/.(zip)$/i)) {
        if (selectedFiles) {
          allFiles.push(selectedFiles);

          tempState["Imagefile"] = allFiles;
          tempState["ImagefileName"] = allFiles[0].name;
          tempState.Error["Imagefile"] = null;
        }
      } else {
        tempState.Error["Imagefile"] = t("Please select zip file only.");
        tempState["Imagefile"] = null;
        tempState["ImagefileName"] = null;
      }
    } else if (name == "Data") {
      if (e.target.files[0].name.match(/.(csv|xls|txt|.xlsx)$/i)) {
        if (selectedFiles) {
          allFiles.push(selectedFiles);

          tempState["Datafile"] = allFiles;
          tempState["DatafileName"] = allFiles[0].name;
          tempState["filetype"] = allFiles[0].name.split(".")[1];
          tempState.Error["Datafile"] = null;
        }
      } else {
        tempState.Error["Datafile"] = t(
          "Please select .csv|xls|txt file only."
        );
        tempState["DatafileName"] = null;
        tempState["Datafile"] = null;
        tempState["filetype"] = null;
      }
    }

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };

  const handleVisible6 = () => {
    setVisible6(true);
  };
  const handleOnOk = () => {
    setVisible6(false);
  };
  /*Upload Certificates file Starts*/
  const fileUploadCertificate = async (e, name) => {
    let tempState = state;
    let allCertFiles = [];
    let selectedFiles = e.target.files[0];
    allCertFiles.push(selectedFiles);
    tempState["UploadCertFile"] = allCertFiles;
    tempState["UploadCertFileName"] = allCertFiles[0].name;
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  };
  /*Upload Certificates file Ends*/

  const handleUploadDiamondDataFile = (name) => {
    if (handleValidation(name)) {
      setSubmitLoading1(true);
      const formData = new FormData();

      formData.append("file", state.Datafile[0]);
      formData.append("dealerId", dealerId);
      formData.append("fileType", state.filetype.toUpperCase());
      formData.append("ReplaceAllChecked", state.rbtnRemove);
      formData.append("SummaryIDDia", 0);
      formData.append("ddlDELIMITERDia", state.Delimiter);

      try {
        CatalogDiamondDataService.UploadDiamondDataFile(formData)
          .then((response) => {
            let message = response.data.message;
            let responseData = response.data.responseData;

            setSubmitLoading1(false);
            if (message === "Success") {
              if (
                responseData.lblMsgDia ==
                "Your previous file is already in process. You can not upload this file at this time."
              ) {
                NotificationManager.error(
                  t(
                    "Your previous file is already in process. You can not upload this file at this time."
                  )
                );
              } else if (
                responseData.lblMsgDia ==
                "Please do the mapping of columns based on uploaded sheet."
              ) {
                NotificationManager.error(
                  t("Please do the mapping of columns based on uploaded sheet.")
                );
              } else {
                NotificationManager.success(t("File Uploaded Successfully."));
                handleVisible6();
              }

              state["Datafile"] = null;
              state["DatafileName"] = null;
              state.Error["Datafile"] = null;
              state["Delimiter"] = null;
              state["IsServer"] = false;
              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
              document.getElementById("file-upload1").value = "";
            } else {
              NotificationManager.error(t("File Not Uploaded."));
              state["Datafile"] = null;
              state["DatafileName"] = null;
              state.Error["Datafile"] = null;
              state["Delimiter"] = null;
              state["IsServer"] = false;
              setState((prevState) => ({
                ...prevState,
                ...state,
              }));
              document.getElementById("file-upload1").value = "";
            }
          })
          .catch((error) => {
            setSubmitLoading1(false);
            console.log(error);
          });
      } catch (error) {
        setSubmitLoading1(false);
        console.log(error);
      }
    }
  };

  const handleDiamondUpload3rdParty = (name) => {
    // let isValid = true;
    if (thirdPartyData.linkSelect == "rapnet") {
      var isValid = handleValidationRapnet();
    } else if (thirdPartyData.linkSelect == "idex") {
      var isValid = handleValidationLink();
    } else if (thirdPartyData.linkSelect == "polygon") {
      var isValid = handleValidationPoly();
    } else {
      var isValid = true;
    }
    if (!isValid) {
      return false;
    }
    setSubmitLoading4(true);
    const formData = new FormData();

    formData.append("dealerId", dealerId);
    formData.append("DrpValue", thirdPartyData.linkSelect);
    formData.append(
      "txtLinkURL",
      thirdPartyData.linkSelect == "RAPNET"
        ? thirdPartyData.rapnetLinkUrl
        : thirdPartyData.linkSelect == "IDEX"
        ? thirdPartyData.idexLinkUrl
        : thirdPartyData.linkSelect == "POLYGON"
        ? thirdPartyData.polygonFileName
        : thirdPartyData.linkSelect == "DIRECTLINK"
        ? thirdPartyData.directLinkUrl
        : ""
    );
    formData.append(
      "txtUserName",
      thirdPartyData.rapnetlinkUserName
        ? thirdPartyData.rapnetlinkUserName
        : "Test"
    );
    formData.append(
      "idexUsername",
      thirdPartyData.idexUsername ? thirdPartyData.idexUsername : "Test"
    );
    formData.append(
      "idexPassword",
      thirdPartyData.idexPassword ? thirdPartyData.idexPassword : "Test"
    );
    formData.append(
      "txtPassword",
      thirdPartyData.rapnetlinkPassword
        ? thirdPartyData.rapnetlinkPassword
        : "Test"
    );
    formData.append(
      "cbEnabled",
      thirdPartyData.rapnetlinkCheckBox
        ? thirdPartyData.rapnetlinkCheckBox
        : false
    );

    try {
      CatalogDiamondDataService.DiamondUpload3rdParty(formData)
        .then((response) => {
          let message = response.data.message;

          setSubmitLoading4(false);
          if (message === "Success") {
            NotificationManager.success(
              t("Diamond RAPNET Information Updated Successfully.")
            );
            handleGet3rdPartyProviderHeader(dealerId, "");
          } else {
            NotificationManager.error(
              t("Diamond RAPNET Information Not Updated .")
            );
          }
        })
        .catch((error) => {
          setSubmitLoading4(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading4(false);
      console.log(error);
    }
    // }
  };

  const handleDownloadAttributesMapFile = () => {
    try {
      let inputData = {
        DealerID: Number(dealerId),
        // columnID: Number(columnId),
      };
      setSubmitLoading3(true);
      CatalogDiamondDataService.CreateCSVDiamonds(inputData)
        .then((response) => {
          setSubmitLoading3(false);
          let fileName = dealerId + ".xlsx";

          BlobToDownloadFile(response.data, fileName);
        })
        .catch((error) => {
          setSubmitLoading3(false);
          console.log(error);
        });
    } catch (error) {
      setSubmitLoading3(false);
      console.log(error);
    }
  };

  const handleValidation = (upName) => {
    let isValid = true;
    if (upName === "Upload1") {
      if (state.Imagefile === null) {
        state.Error["Imagefile"] = t("Please select image.");
        isValid = false;
      } else {
        if (state.Imagefile.length === 0) {
          state.Error["Imagefile"] = t("Please select image.");
          isValid = false;
        }
      }
    } else if (upName === "Upload2") {
      if (state.Datafile === null) {
        state.Error["Datafile"] = t("Please select file.");
        isValid = false;
      } else {
        if (state.Datafile.length === 0) {
          state.Error["Datafile"] = t("Please select file.");
          isValid = false;
        }
      }
      if (state.Delimiter === null) {
        state.Error["Delimiter"] = t("Please select delimiter.");
        isValid = false;
      }
    }

    setState((prevState) => ({
      ...prevState,
      ...state,
    }));

    return isValid;
  };

  // 3rd Party GET API

  const handleGet3rdPartyProvider = (id, type) => {
    try {
      let inputData = {
        dealerID: id,
        type: type,
      };
      setLoader(true);
      CatalogDiamondDataService.Get3rdPartyProvider(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setThirdPartyData({
              directLinkUrl:
                responseData.type == type ? responseData.linkURL : "",
              linkSelect: type == "" ? responseData.type : type,
              idexLinkUrl:
                responseData.type == type ? responseData.linkURL : "",
              polygonFileName:
                responseData.type == type
                  ? responseData.linkURL
                  : "69.65.43.23",
              rapnetLinkUrl:
                responseData.type == type ? responseData.linkURL : "",
              rapnetlinkUserName:
                responseData.type == type ? responseData.username : "",
              rapnetlinkPassword:
                responseData.type == type ? responseData.password : "",
              rapnetlinkCheckBox:
                responseData.type == type ? responseData.enabled : "",
              idexUsername:
                responseData.type == type ? responseData.username : "",
              idexPassword:
                responseData.type == type ? responseData.password : "",
            });
            setLoader(false);
          } else {
            setThirdPartyData({});
            setLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoader(false);
        });
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleGet3rdPartyProviderHeader = (id) => {
    try {
      let inputData = {
        dealerID: id,
        type: "",
      };
      CatalogDiamondDataService.Get3rdPartyProviderHeader(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          let type = responseData.type;
          if (message === "Success") {
            handleGet3rdPartyProvider(loginDetials.responseData.dealerId, type);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // End 3rd Party GET API
  function onChangeRadio(e, name) {
    let tempState = state;
    if (name === "ReplaceAll") {
      // alert("This option will override the data.");
      setVisibleConfirmModal(true);
      tempState["rbtnRemove"] = true;
    } else {
      tempState["rbtnRemove"] = false;
    }

    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  }
  function onChangeDrop(e, name) {
    let tempState = state;

    tempState[name] = e;
    tempState.Error["Delimiter"] = null;
    setState((prevState) => ({
      ...prevState,
      ...tempState,
    }));
  }
  const handleSelectOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setThirdPartyData({
        ...thirdPartyData,
        [name]: e,
      });
    }
    handleGet3rdPartyProvider(dealerId, e);
  };
  const handleOnChange = (e, name) => {
    setThirdPartyData({
      ...thirdPartyData,
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "idexLinkUrl") {
      setValidations1((prevdata) => ({
        ...prevdata,
        idexLinkUrlValue: "",
      }));
    }

    if (e.target.name === "rapnetLinkUrl") {
      setValidations2((prevdata) => ({
        ...prevdata,
        rapnetLinkUrlValue: "",
      }));
    }

    if (e.target.name === "polygonFileName") {
      setValidations3((prevdata) => ({
        ...prevdata,
        polygonFileNameValue: "",
      }));
    }
  };
  function onSearch(val) {}
  const saveFile = () => {
    axios({
      url: `${config.BlobURL}gemfind2staging/StaticFiles/UploadJewelry/ImageGuideline%20(1).pdf`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ImageGuideline.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  /* Modal functionality starts */
  const handleCancel = () => {
    setUploadCertVisible(false);
  };
  /* Modal functionality starts */
  return (
    <div className="prodetail__section">
      {loginDetials?.responseData?.vendorStatusID == 3 ? (
        <>{t("Please Contact Gemfind to get Access.")}</>
      ) : (
        <div className="form__fields">
          <div className="col-md-12">
            <div className="prod__maindiv data__maping uplaod__file magento__mapping">
              <Spin spinning={submitLoading1}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="heading__block">
                      <h4 class="workarea__heading mt-0 mb-0">
                        {" "}
                        {t("Upload Your Data File")}{" "}
                      </h4>
                      <div className="right__btn">
                        <Link to={"/addSingleDiamond"}>
                          <button className="primary-btn mr-2">
                            <img src={Create_icon} alt="" />{" "}
                            {t("Add Individual")}
                          </button>
                        </Link>
                        {/* <button className="primary-btn mr-2" onClick={() => setUploadCertVisible(true)}>
                        <img src={Upload_icon} alt="" />{" "}
                        {t("Upload Certs")}
                      </button> */}
                        <Button
                          className="primary-btn mobile__topspace"
                          onClick={handleDownloadAttributesMapFile}
                          loading={submitLoading3}
                        >
                          <i className="fa fa-download"></i>{" "}
                          {t("Download Data")}
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <div className="input__block">
                      <div className="col-md-12 image_block">
                        <div class="row">
                          <div className="col-lg-12">
                            <p>
                              {" "}
                              {t(
                                "Please keep your inventory current. Your diamond inventory will be removed if not updated at least every 48 hours."
                              )}{" "}
                            </p>
                          </div>
                          <div class="col-lg-4 col-md-12">
                            <div className="divimage_block">
                              <label
                                htmlFor="file-upload1"
                                class="primary-btn choose_file_upload"
                              >
                                {t("Choose File")}
                              </label>
                              <input
                                id="file-upload1"
                                type="file"
                                onChange={(e) => fileUpload(e, "Data")}
                              />
                              {state.Error ? (
                                <div className="text-danger">
                                  {state.Error["Datafile"]}
                                </div>
                              ) : null}

                              {/* For No file choosen  */}
                              {state.DatafileName ? (
                                ""
                              ) : (
                                <span className="mb-4">
                                  {t("No File Choosen")}{" "}
                                  {/* <span className="text-red">*</span> */}
                                </span>
                              )}

                              {/* End */}
                              <span> {t("Xlsx/Txt/Csv Format")} </span>
                              <span className="">{state.DatafileName}</span>
                            </div>

                            <div className="choose_file_divimg d-none">
                              <img src={userIcon} alt="img" />
                              <span>x</span>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-12">
                            <div>
                              <Select
                                className="border__grey mobile-w-100"
                                optionFilterProp="children"
                                placeholder={t("Please Select Delimiter")}
                                style={{ width: 177 }}
                                value={state.Delimiter}
                                onChange={(e) => onChangeDrop(e, "Delimiter")}
                              >
                                {/* <Option value=""> {t("Select")} </Option> */}
                                <Option value="COMMA"> {t("Comma")} </Option>
                                <Option value="TAB"> {t("Tab")} </Option>
                              </Select>
                              {state.Error ? (
                                <div className="text-danger">
                                  {state.Error["Delimiter"]}
                                </div>
                              ) : null}

                              <div className="radio__block mt-3">
                                <div className="radio__btn">
                                  <input
                                    type="radio"
                                    id="dr"
                                    name="mt"
                                    className="mr-1"
                                    checked={state.rbtnRemove ? true : false}
                                    onChange={(e) =>
                                      onChangeRadio(e, "ReplaceAll")
                                    }
                                  />
                                  <label htmlFor="dr">
                                    {" "}
                                    {t("Replace All")}
                                  </label>
                                  <Tooltip
                                    placement="right"
                                    title={replaceDetail}
                                    className="ToolTip-Diamond ml-1"
                                  >
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip>
                                </div>
                                <div className="radio__btn">
                                  <input
                                    type="radio"
                                    id="jr"
                                    name="mt"
                                    className="mr-1"
                                    checked={
                                      state.rbtnRemove == false ? true : false
                                    }
                                    onChange={(e) =>
                                      onChangeRadio(e, "AddUpdate")
                                    }
                                  />
                                  <label htmlFor="jr">
                                    {" "}
                                    {t("Add & Update")}
                                  </label>
                                  <Tooltip
                                    placement="right"
                                    title={addDetail}
                                    className="ToolTip-Diamond ml-1"
                                  >
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip>
                                </div>
                              </div>
                              <div className="help-doc-sp">
                                <img
                                  src={HelpBook}
                                  width="15px"
                                  height="15px"
                                />
                                <p>
                                  {t("Questions? View the")}
                                  <a href="#" onClick={saveFile}>
                                    {" "}
                                    {t("Diamond Import")}{" "}
                                  </a>
                                  {t("Help Docs")}.
                                </p>
                              </div>
                              <Button
                                className="primary-btn"
                                onClick={(e) =>
                                  handleUploadDiamondDataFile("Upload2")
                                }
                              >
                                {t("Upload Data")}
                              </Button>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="format__img">
                              <img
                                src={ExcellFormatIcon}
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="input__block">
                    
                    <div class="col-md-12">
                      <h4 className="workarea__heading">
                        {" "}
                        {t("Upload Diamond Images & Videos")}{" "}
                      </h4>
                    </div>
                    <div className="col-md-12 image_block">
                      <div class="row">
                        <div class="col-lg-4 col-md-12">
                          <div className="divimage_block">
                            <label
                              htmlFor="file-upload"
                              class="primary-btn choose_file_upload"
                            >
                              {t("Choose File")}
                            </label>
                            <input
                              id="file-upload"
                              type="file"
                              onChange={e => fileUpload(e, "Image")}
                            />
                            {state.Error ? (
                              <div className="text-danger">
                                {state.Error["Imagefile"]}
                              </div>
                            ) : null}
                            {state.ImagefileName ? (
                              state.ImagefileName
                            ) : (
                              <span className="mb-4">
                                {" "}
                                {t("No File Choosen")}{" "}
                                
                              </span>
                            )}
                            <span className="">
                              {" "}
                              500 x 500 {t("Pixels")}{" "}
                            </span>
                            <span className="">
                              {" "}
                              {t("Jpg/Png Format")}{" "}
                            </span>
                          </div>

                          <div className="preview__img choose_file_divimg d-none">
                            <img src={userIcon} alt="img" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <p>
                            -{" "}
                            {t(
                              "Compress images together & upload in a .zip format."
                            )}{" "}
                          </p>
                          <p className="help-doc-sp">
                            <a
                              id="Download"
                              href="https://platform.stage.jewelcloud.com/Catalog/GuideLines/ImageGuideline.pdf"
                              download
                            >
                              {t(
                                "Download our image guidelines documentation"
                              )}
                              .
                            </a>
                          </p>
                          <div className="help-doc-sp">
                            <img src={HelpBook} width="15px" height="15px" />
                            <p>
                              {t("Questions? View the")}
                              <a
                                href="https://platform.stage.jewelcloud.com/Catalog/GuideLines/ImageGuideline.pdf"
                                target="_blank"
                                download
                              >
                                {" "}
                                {t("Image Guidelines")}{" "}
                              </a>
                              {t("Help Docs")}.
                            </p>
                          </div>
                          <Button
                            className="primary-btn"
                            onClick={e =>
                              handleUploadDiamondImageFiles("Upload1")
                            }
                            loading={submitLoading2}
                          >
                            {t("Upload Data")}
                          </Button>
                        </div>
                        <div className="col-md-2">
                          <div className="format__img">
                            <img src={ImageFormatIcon} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                    <div className="row">
                      {/* <Spin spinning = {loader}> */}
                      <div className="col-md-6">
                        <div className="input__block">
                          <h4 className="workarea__heading mt-0">
                            {" "}
                            {t("Import Data From 3rd Party Provider")}{" "}
                          </h4>

                          <div className="w-50 mobile-w-100">
                            <Spin spinning={loader}>
                              <Select
                                className="border__grey"
                                optionFilterProp="children"
                                placeholder={t("DIRECTLINK")}
                                value={thirdPartyData.linkSelect}
                                name="linkSelect"
                                onChange={(e) =>
                                  handleSelectOnChange(
                                    e,
                                    "select",
                                    "linkSelect"
                                  )
                                }
                                onSearch={onSearch}
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                <Option value="RAPNET"> {t("RAPNET")} </Option>
                                <Option value="DEX"> {t("IDEX")} </Option>
                                <Option value="POLYGON">
                                  {" "}
                                  {t("POLYGON")}{" "}
                                </Option>
                                <Option value="DIRECTLINK">
                                  {" "}
                                  {t("DIRECT LINK")}{" "}
                                </Option>
                              </Select>

                              {thirdPartyData.linkSelect === "IDEX" && (
                                <div>
                                  <input
                                    type="text"
                                    className="mt-3"
                                    name="idexLinkUrl"
                                    placeholder={t("Link URL")}
                                    value={thirdPartyData.idexLinkUrl}
                                    onChange={handleOnChange}
                                  />
                                  {thirdPartyData.linkSelect === "IDEX" && (
                                    <div>
                                      {validations1.idexLinkUrlValue && (
                                        <p className="error-color-red">
                                          {validations1.idexLinkUrlValue}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                  {thirdPartyData.linkSelect === "IDEX" && (
                                    <div>
                                      <input
                                        type="text"
                                        className="mt-3"
                                        name="idexUsername"
                                        placeholder={t("IDEX Username")}
                                        value={thirdPartyData.idexUsername}
                                        onChange={handleOnChange}
                                      />
                                      <input
                                        type="password"
                                        className="mt-3"
                                        name="idexPassword"
                                        placeholder={t("IDEX Password")}
                                        value={thirdPartyData.idexPassword}
                                        onChange={handleOnChange}
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                              {thirdPartyData.linkSelect === "DIRECTLINK" && (
                                <input
                                  type="text"
                                  className="mt-3"
                                  name="directLinkUrl"
                                  placeholder={t("Link URL")}
                                  value={thirdPartyData.directLinkUrl}
                                  onChange={handleOnChange}
                                />
                              )}
                              {thirdPartyData.linkSelect === "POLYGON" && (
                                <input
                                  type="text"
                                  className="mt-3"
                                  name="polygonFileName"
                                  placeholder={t("File Name")}
                                  value={thirdPartyData.polygonFileName}
                                  onChange={handleOnChange}
                                />
                              )}
                              {thirdPartyData.linkSelect === "POLYGON" && (
                                <div>
                                  {validations3.polygonFileNameValue && (
                                    <p className="error-color-red">
                                      {validations3.polygonFileNameValue}
                                    </p>
                                  )}
                                </div>
                              )}
                              {thirdPartyData.linkSelect === "RAPNET" && (
                                <div>
                                  <input
                                    type="text"
                                    className="mt-3"
                                    name="rapnetLinkUrl"
                                    placeholder={t("Link URL")}
                                    value={thirdPartyData.rapnetLinkUrl}
                                    onChange={handleOnChange}
                                  />
                                  {thirdPartyData.linkSelect === "RAPNET" && (
                                    <div>
                                      {validations2.rapnetLinkUrlValue && (
                                        <p className="error-color-red">
                                          {validations2.rapnetLinkUrlValue}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                  <input
                                    type="text"
                                    className="mt-3"
                                    name="rapnetlinkUserName"
                                    placeholder={t("User Name")}
                                    value={thirdPartyData.rapnetlinkUserName}
                                    onChange={handleOnChange}
                                  />
                                  <input
                                    type={showPassword ? "text" : "password"}
                                    className="mt-3"
                                    name="rapnetlinkPassword"
                                    placeholder={t("Password")}
                                    value={thirdPartyData.rapnetlinkPassword}
                                    onChange={handleOnChange}
                                  />
                                  <span
                                    class="p-viewer"
                                    onClick={handlePassShowHide}
                                  >
                                    <i
                                      class={
                                        showPassword
                                          ? "fa fa-eye"
                                          : "fa fa-eye-slash"
                                      }
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                  <div className="chckbox__div uploadDiamondCheckbox-sp mt-3">
                                    <input
                                      checked={
                                        thirdPartyData.rapnetlinkCheckBox
                                      }
                                      onChange={(e) =>
                                        handleCheckBoxChange(
                                          e,
                                          "rapnetlinkCheckBox"
                                        )
                                      }
                                      type="checkbox"
                                      name="rapnetlinkCheckBox"
                                      id="rapnetlinkCheckBox"
                                    />
                                    <label htmlFor="rapnetlinkCheckBox">
                                      {t(
                                        "Use Rapnet Instant Inventory Service."
                                      )}
                                    </label>
                                  </div>
                                </div>
                              )}
                            </Spin>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="w-50 mx-auto">
                          <img
                            src={idexLogo}
                            className="img-fluid"
                            alt="idex-logo"
                          />
                        </div>
                        <div className="w-50 mx-auto">
                          <img
                            src={rapnetLogo}
                            className="img-fluid"
                            alt="rapnet-logo"
                          />
                        </div>
                        <div className="w-50 mx-auto">
                          <img
                            src={polygonLogo}
                            className="img-fluid"
                            alt="polygon-logo"
                          />
                        </div>
                      </div>
                      {/* </Spin> */}
                    </div>
                    <Button
                      className="primary-btn"
                      onClick={(e) => handleDiamondUpload3rdParty("Save")}
                      loading={submitLoading4}
                    >
                      {t("Save")}
                    </Button>
                  </div>
                  <div className="col-lg-3">
                    <div className="right__sec">
                      <h5>
                        {" "}
                        {t(
                          "Add new diamonds in bulk or make mass item updates"
                        )}
                        .{" "}
                      </h5>
                      <h5> {t("First time importing?")} </h5>
                      <ul>
                        <li>
                          <b> {t("Import Your Content")} </b>
                          <p>
                            {" "}
                            {t(
                              "Choose & upload your data or connect to a 3rd party"
                            )}
                            .{" "}
                          </p>
                        </li>
                        <li>
                          <b> {t("Wait For Confirmation")} </b>
                          <p>
                            {" "}
                            {t(
                              "You will receive an e-mail confirmation when your content has been uploaded."
                            )}{" "}
                          </p>
                        </li>
                        {/* <li>
                                                <b>Map Your Content</b>
                                                <p>Match your categories with the JewelCloud industry standard making your content compatible with our network and applications.</p>
                                                <p>Required if your data file is not in our format.</p>

                                            </li> */}
                      </ul>
                      <br />
                      <div className="row download__block">
                        <h5> {t("Preparing Your Data")} </h5>
                        <br />
                        <br />
                        <h5> {t("Download Templates")} </h5>
                        <br />
                        <div className="col-md-2 col-2">
                          <img src={DownloadBox} alt="download-box" />
                        </div>
                        <div className="col-md-10 col-10 mobile-p-0">
                          <a
                            //href="GuideLines/JC Data Form - Required Fields.xlsx"
                            href="https://platform.stage.jewelcloud.com/Catalog/GuideLines/JC%20Diamond%20Data%20Form.xlsx"
                            //target="_blank"
                            download
                          >
                            {t("Standard Diamond Form")}
                          </a>
                          <br />
                        </div>

                        <br />
                      </div>
                      <br />
                      <div className="row download__block">
                        <h5> {t("Help Docs")} </h5>
                        <br />
                        <div className="col-md-2 col-2">
                          <img src={HelpBook} alt="help-book" />
                        </div>
                        <div className="col-md-10 col-10 mobile-p-0">
                          <a onClick={saveFile}>{t("Diamond Importing")}</a>
                          <br />
                        </div>

                        <br />
                      </div>
                    </div>
                  </div>
                  {/* Upload File */}

                  <Modal
                    title={" "}
                    className="modalconsupld__section"
                    centered
                    visible={visible6}
                    onCancel={() => handleOnOk()}
                    width={400}
                    maskClosable={false}
                    footer={[
                      <Button
                        key="submit"
                        type="primary"
                        onClick={() => handleOnOk()}
                      >
                        {t("OK")}
                      </Button>,
                    ]}
                  >
                    <div className="col-lg-12">
                      <div className="modal__body">
                        <div className="col-lg-12">
                          <div className="form__fields border-0 p-0">
                            <div className="row">
                              <div className="col-lg-12 text-center">
                                <p>
                                  {t(
                                    "Your diamonds are Added Successfully. However it will take some moments to Add them from cache."
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal>

                  {/* For No Update File */}
                  {/* Upload Certificates modal starts */}
                  <div className="col-lg-12">
                    <Modal
                      title={t("Upload Certificates")}
                      className="modal__manageUser"
                      centered
                      width={600}
                      visible={uploadCertVisible}
                      onCancel={handleCancel}
                      footer={[
                        <Button onClick={handleCancel}>{t("Cancel")}</Button>,
                        <Button key="submit" type="primary">
                          {t("Save")}
                        </Button>,
                      ]}
                    >
                      <div className="col-lg-12">
                        <div className="modal__body">
                          <div className="col-lg-12">
                            <div className="form__fields">
                              <div className="divimage_block uploadCertsImgBlock">
                                <label
                                  htmlFor="certificate-upload1"
                                  class="primary-btn choose_file_upload"
                                >
                                  {t("Choose File")}
                                </label>
                                <input
                                  id="certificate-upload1"
                                  type="file"
                                  onChange={(e) => fileUploadCertificate(e)}
                                />
                                {/* {state.Error ? (
                                <div className="text-danger">
                                  {state.Error["Datafile"]}
                                </div>
                              ) : null} */}

                                {/* For No file choosen  */}
                                {state.UploadCertFileName ? (
                                  ""
                                ) : (
                                  <span className="mb-4">
                                    {t("No File Choosen")}{" "}
                                    {/* <span className="text-red">*</span> */}
                                  </span>
                                )}

                                {/* End */}
                                <span> {t("Zip Format")} </span>
                                <span className="">
                                  {state.UploadCertFileName}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                    <Modal
                      title={t("Confirmation")}
                      centered
                      visible={visibleConfirmModal}
                      onCancel={() => setVisibleConfirmModal(false)}
                      width={400}
                      footer={[
                        <Button
                          key="submit"
                          type="primary"
                          onClick={() => setVisibleConfirmModal(false)}
                        >
                          {t("Ok")}
                        </Button>,
                      ]}
                    >
                      <div className="text-center">
                        <h5> {t("This option will override the data.")} </h5>
                      </div>
                    </Modal>
                  </div>

                  {/* Upload Certificates modal ends */}
                </div>
              </Spin>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadDiamondFile;
