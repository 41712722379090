import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Checkbox, Modal } from "antd";
import { Link } from "react-router-dom";
import playicon from "../../assets/images/login-play.svg";
import footerCompanyLogo1 from "../../assets/images/footerlogo1.png";
import footerCompanyLogo2 from "../../assets/images/footerlogo2.png";
import footerCompanyLogo3 from "../../assets/images/footerlogo3.png";
import footerCompanyLogo4 from "../../assets/images/footerlogo4.png";
import footerCompanyLogo5 from "../../assets/images/footerlogo5.png";
import OwlCarousel from "react-owl-carousel";
import {
  loginSubmit,
  setLoginToken,
  setCurrency,
  setWhiteLabelingData,
  setCurrencySign,
} from "../../actions/login/login";
import {
  setHeaderMenuData,
  setChecklinkHeaderMenuData,
  setSelectSideMenu,
  setAdminMenuData,
  setOpenKeyHeaderMenuData,
  setSelectedLanguage,
} from "../../actions/headerMenu/headerMenu";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useNavigate, useLocation } from "react-router-dom";
import adminToolsService from "../../services/admin-tools.service";
import { NotificationManager } from "react-notifications";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import LoginDataService from "../../services/login.service";

import adminManageLanguageService from "../../services/admin-manageLanguage.service";
import AdminDashboardDataService from "../../services/admin-dashboard.service";
import config from "../../helper/config";
import useEncryptData from "../../utils/EncryptData";

const Login = (props) => {
  const { t } = useTranslation();
  const inputEl = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const EncryptData = useEncryptData();

  const formRef = React.createRef();
  const search = useLocation().search;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [designerName, setDesignerName] = useState("");
  const [designerLogoData, setdesignerLogoData] = useState([]);
  const [couter, setCounter] = useState(0);
  const [cookies, setCookie] = useCookies([
    "userName",
    "password",
    "token",
    false,
  ]);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [remeberMecheck, setRememberMeCheck] = useState(false);
  const [token, setToken] = useState(false);
  const [mstokenVal, setMsTokenVal] = useState("");
  const [msDealerId, setMsDealerId] = useState("");
  const [msCustomerId, setMsCustomerId] = useState();

  const [successfullverification, setSuccessfullverification] = useState(false);
  const [customerLogin, setCustomerLogin] = useState(false);
  const [productInfo, setProductInfo] = useState(false);
  const [productId, setProductId] = useState("");
  const [emailDealerId, setEmailDealerId] = useState("");
  const [discardCollection, setDiscardCollection] = useState(false);
  const [userSetting, setUserSetting] = useState(false);
  const [DealerID, setDealerID] = useState("");
  const [SummaryID, setSummaryID] = useState("");
  const [appointment, setAppointment] = useState(false);

  const [resetPassword, setresetPassword] = useState({
    email: "",
  });
  const [validations, setValidations] = useState({
    emailVal: "",
  });

  const WhiteLabeling = useSelector((state) => state.loginReducer);
  const expiryTime = new Date();
  expiryTime.setTime(expiryTime.getTime() + 3 * 60 * 60 * 1000); ///3 hours expiry time
  const domainSite = extractDomain();

  useEffect(() => {
    inputEl.current.focus();
    const username = new URLSearchParams(search).get("username");
    const IsImpersonate = new URLSearchParams(search).get("IsImpersonate");
    const dealerId = new URLSearchParams(search).get("dealerId");
    const customerId = new URLSearchParams(search).get("customerID");
    const productId = new URLSearchParams(search).get("productId");
    const emailDealerId = new URLSearchParams(search).get("emailDealerId");
    const DealerID = new URLSearchParams(search).get("DealerID");
    const SummaryID = new URLSearchParams(search).get("SummaryID");
    const resetField = new URLSearchParams(search).get("resetField");

    if (resetField) {
      setIsModalVisible2(true);
    }

    if (customerId) {
      setToken(true);
      setMsDealerId(dealerId);
      setMsCustomerId(customerId);
    }

    if (window.location.href.indexOf("CreateDiscardCollection") > -1) {
      setDiscardCollection(true);
      setDealerID(DealerID);
      setSummaryID(SummaryID);
    }
    if (window.location.href.indexOf("CompanyEmailSetting") > -1) {
      setUserSetting(true);
    }
    if (window.location.href.indexOf("contactAppointment") > -1) {
      setAppointment(true);
    }
    if (window.location.href.indexOf("successfullverification") > -1) {
      setSuccessfullverification(true);
    }
    if (window.location.href.indexOf("customerLogin") > -1) {
      setCustomerLogin(true);
    }
    if (window.location.href.indexOf("ProductInfo") > -1) {
      setProductInfo(true);
      setProductId(productId);
      setEmailDealerId(emailDealerId);
    }
    if (username && IsImpersonate == "1") {
      window.location.href = "dashboard";
    } else {
      handleGetDesignerData();
    }
    if (cookies.userName) {
      formRef.current.setFieldsValue({
        username: cookies.userName,
        password: cookies.password,
        remember: cookies.remember,
      });
      setRememberMeCheck(cookies.remember == "true" ? true : false);
    }
  }, []);

  useEffect(() => {
    setCounter(couter + 1);
  }, [designerLogoData]);

  useEffect(() => {
    handleGetWhiteLabel();
  }, []);

  const handleGetWhiteLabel = async () => {
    AdminDashboardDataService.CompanyWhileLabel()
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            dispatch(setWhiteLabelingData(responseData));
          } else {
            dispatch(setWhiteLabelingData([]));
          }
        } else {
          dispatch(setWhiteLabelingData([]));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlecheckBoxchange = (e) => setRememberMeCheck(e.target.checked);

  const handleOnChange = (e, isSelect, name) => {
    let value = isSelect === "select" ? e : e.target.value;
    let fieldName = isSelect === "select" ? name : e.target.name;

    // Update state
    setresetPassword((prev) => ({
      ...prev,
      [fieldName]: value,
    }));

    // Validate email on change
    if (fieldName === "email") {
      validateEmail(value);
    }
  };
  const validateEmail = (email) => {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    let emailError = "";
    if (!email.trim()) {
      emailError = "Email address is compulsory";
    } else if (!email.match(emailRegex)) {
      emailError = "Email address is invalid";
    }

    setValidations((prev) => ({
      ...prev,
      emailVal: emailError,
    }));
    return emailError === "";
  };
  const handleResetPassword = () => {
    const isValid = validateEmail(resetPassword.email);
    if (!isValid) {
      return;
    }

    let inputData = {
      userEmail: resetPassword.email,
      websiteLink: window.location.origin + "/resetPassword",
    };
    setResetPasswordLoading(true);
    adminToolsService
      .ForgotPasswordGeneration(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        var errorMsg = response.data.responseData.errorMsg;
        if (message == "True") {
          NotificationManager.success(t("Mail Sent Successfully"));
          handleCancel2();
          setResetPasswordLoading(false);
        } else {
          if (errorMsg == "User Email Not Found") {
            NotificationManager.error(t("User Email Not Found"));
            setResetPasswordLoading(false);
          } else {
            NotificationManager.error(t("User Email does not Exist"));
            setResetPasswordLoading(false);
          }
        }
      })
      .catch((error) => {
        setResetPasswordLoading(false);
      })
      .finally(() => {
        setResetPasswordLoading(false);
      });
  };
  const handleCurrency = (id) => {
    let inputData = {
      delarId: id,
    };
    LoginDataService.currency(inputData).then((response) => {
      if (response.data.message === "Success") {
        let responseData = response.data.responseData;

        dispatch(setCurrency(responseData.currencyType));
        dispatch(setCurrencySign(responseData.currencysign));
      }
    });
  };
  const languageCodes = {
    Spanish: "es",
    English: "en",
    Arabic: "ar",
  };
  const handleLanguage = (id) => {
    let inputData = {
      delarId: id,
    };
    adminManageLanguageService
      .GetSelectlanguageForDelar(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            const getLangValue = languageCodes[responseData.language] || null;
            dispatch(setCurrency(responseData.currencyType));
            dispatch(setSelectedLanguage(getLangValue));
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onFinish = (values) => {
    let inputData = {
      userName: values.username || null,
      userEmail: values.useremail || null,
      password: values.password || null,
    };
    setLoading(true);
    dispatch(loginSubmit(inputData))
      .then((response) => {
        if (response.message == "True") {
          if (response.responseData.isUserExist == true) {
            dispatch(setLoginToken(response));
            let finalResponse = response.responseData;
            handleGetWhiteLabel();
            let encryptToken = EncryptData(finalResponse.userToken);
            finalResponse.userToken = encryptToken;
            setCookie("token", encryptToken, {
              path: "/",
              expires: expiryTime,
              domain: domainSite,
            });
            if (values.remember) {
              setCookie("userName", values.username);
              setCookie("password", values.password);
              setCookie("remember", remeberMecheck);
            } else {
              setCookie("userName", "");
              setCookie("password", "");
              setCookie("remember", false);
            }
            handleAddLoginDetails(values.username);
            setTimeout(() => {
              handleGetFirstTierMenu(
                response.responseData.dealerId,
                response.responseData.dealerMemberTypeName
              );
              handleCurrency(response.responseData.dealerId);
              handleLanguage(response.responseData.dealerId);
            }, 5000);
          } else {
            NotificationManager.error(t("Email Doesnot Exist"));
            setLoading(false);
          }
        } else {
          if (response.responseData) {
            if (
              response.responseData.message ==
              "your account has not been Verified "
            ) {
              NotificationManager.error(
                t("Your Account has not been approved yet")
              );
              setLoading(false);
            } else if (
              response.responseData.message ==
              "Your account has not been activated yet "
            ) {
              NotificationManager.error(t("Your Account has been deactivated"));
              setLoading(false);
            } else if (
              response.responseData.message ==
              "Your account in In-Active State please contact to Admin to Activate "
            ) {
              NotificationManager.error(
                t(
                  "Your account in In-Active State please contact to Admin to Activate "
                )
              );
              setLoading(false);
            } else if (response.responseData.isLockedOut == true) {
              NotificationManager.error(t("Your Account has been Locked."));
              setLoading(false);
            } else if (response.responseData.logInSucceeded == false) {
              NotificationManager.error(
                t("Please Check Username And Password.")
              );
              setLoading(false);
            }
          } else {
            NotificationManager.error(t("Please Check Username And Password."));
            setLoading(false);
          }
        }
      })
      .catch((e) => {
        NotificationManager.error(t("Something Went Wrong."));
        setLoading(false);
      });
  };
  function extractDomain() {
    const url = window.location.href;
    const domainMatch = url.match(/:\/\/(www[0-9]?\.)?([^/:]+)/i);

    if (domainMatch && domainMatch.length > 2) {
      const fullDomain = domainMatch[2];
      const parts = fullDomain.split(".");
      if (parts.length >= 2) {
        const domain = "." + parts.slice(-2).join(".");
        return domain;
      }
    }

    return null;
  }
  const handleGetFirstTierMenu = (id, type) => {
    let inputData = {
      dealerId: id.toString(),
    };

    adminToolsService
      .GetAllMenuDtlsByPermission(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        setLoading(false);
        if (message === "Success") {
          dispatch(setHeaderMenuData(responseData));
          dispatch(setAdminMenuData(responseData));
          localStorage.setItem("AllMenuDetails", JSON.stringify(responseData));
          if (token) {
            navigate("/MasterLinkApplicationPreview", {
              state: {
                dealerId: msDealerId,
                token: mstokenVal,
                customerId: msCustomerId,
              },
            });

            dispatch(setChecklinkHeaderMenuData("Apps"));
            var MenuData = [`${"Master Link"}`];
            dispatch(setOpenKeyHeaderMenuData(MenuData));
            dispatch(setSelectSideMenu(["MasterLinkApplicationPreview"]));
          } else if (successfullverification) {
            navigate("/MasterLinkApplicationPreview", {
              state: {
                successfullverification: true,
              },
            });
            dispatch(setChecklinkHeaderMenuData("Apps"));
            var MenuData = [`${"Master Link"}`];
            dispatch(setOpenKeyHeaderMenuData(MenuData));
            dispatch(setSelectSideMenu(["MasterLinkApplicationPreview"]));
          } else if (userSetting) {
            navigate("/userSettings");
          } else if (discardCollection) {
            navigate("/DiscardCollection", {
              state: {
                DealerID: DealerID,
                SummaryID: SummaryID,
              },
            });
          } else if (appointment) {
            dispatch(setChecklinkHeaderMenuData("Contacts"));
            navigate("/contact", {
              state: {
                key: "AppointmentsOnly",
              },
            });
          } else if (customerLogin) {
            navigate("/MasterLinkApplicationPreview", {
              state: {
                customerLogin: true,
              },
            });
            dispatch(setChecklinkHeaderMenuData("Apps"));
            var MenuData = [`${"Master Link"}`];
            dispatch(setOpenKeyHeaderMenuData(MenuData));
            dispatch(setSelectSideMenu(["MasterLinkApplicationPreview"]));
          } else if (productInfo) {
            navigate("/MasterLinkApplicationPreview", {
              state: {
                productId: productId,
                emailDealerId: emailDealerId,
              },
            });
            dispatch(setChecklinkHeaderMenuData("Apps"));
            var MenuData = [`${"Master Link"}`];
            dispatch(setOpenKeyHeaderMenuData(MenuData));
            dispatch(setSelectSideMenu(["MasterLinkApplicationPreview"]));
          } else {
            if (type == "Admin") {
              dispatch(setChecklinkHeaderMenuData("Admin"));
              navigate("/administrationTools");
              dispatch(setSelectSideMenu(["administrationTools"]));
            } else {
              dispatch(setChecklinkHeaderMenuData("Dashboard"));
              navigate("/dashboard");
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddLoginDetails = (username) => {
    try {
      let inputData = {
        userName: username,
      };
      adminToolsService
        .AddLoginDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onFinishFailed = (errorInfo) => {};

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModal2 = () => {
    setIsModalVisible2(true);
  };

  const handleCancel2 = () => {
    setIsModalVisible2(false);
    setresetPassword({
      email: "",
    });
    setValidations({
      emailVal: "",
    });
  };
  const handleDealerLogin = (userName, password) => {
    navigate("dashboard");
  };

  const options = {
    margin: 20,
    loop: true,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: designerLogoData && designerLogoData.length > 8 ? true : false,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 3,
      },
      600: {
        items: 4,
      },
      1000: {
        items:
          designerLogoData && designerLogoData.length > 8
            ? 8
            : designerLogoData
            ? designerLogoData.length
            : Number(designerLogoData.length),
      },
    },
  };

  const handleGetDesignerData = () => {
    try {
      adminToolsService
        .GetDesignerData()
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setDesignerName(responseData.designername);
            setdesignerLogoData(responseData.designerLogoData);
          } else {
            setDesignerName("");
            setdesignerLogoData([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenLink = (link) => {
    window.open(link, "__blank");
  };

  return (
    <div className="login__section container-fluid">
      <div className="row">
        <div className="col-md-12">
          <div className="login__header">
            <div className="logo__section">
              <img
                src={WhiteLabeling?.whiteLebelData?.[0]?.logo || ""}
                // style={{ width: "30px", marginBottom: "10px" }}
              />
            </div>

            <div className="right__text">
              <p>Social Product Network for the Jewelry Industry</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="left__section">
            <img src={playicon} onClick={showModal} className="circle-ripple" />
            <h4>
              {WhiteLabeling?.whiteLebelData?.[0]?.name || "JewelCloud"}, State
              of the art technology <br /> for the jewelry industry
            </h4>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form__section">
            <div className="login__block">
              <h3>
                Login to{" "}
                {WhiteLabeling?.whiteLebelData?.[0]?.name || "JewelCloud"}
              </h3>
              <Form
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                ref={formRef}
              >
                <Form.Item className="formlabel">
                  Username <span className="mandatory">*</span>
                </Form.Item>
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your username!",
                    },
                  ]}
                >
                  <Input ref={inputEl} className="login-input" />
                </Form.Item>
                <Form.Item className="formlabel">
                  Password <span className="mandatory">*</span>
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your password!",
                    },
                  ]}
                >
                  <Input.Password className="login-input" />
                </Form.Item>

                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  className="checkbox__block"
                >
                  <div className="forgotpw">
                    <div className="checkbox__block">
                      <Checkbox
                        onChange={(e) => handlecheckBoxchange(e)}
                        name="remeberMecheck"
                        checked={remeberMecheck}
                        className="formcheckbox"
                      >
                        Remember me
                      </Checkbox>
                    </div>
                    <a onClick={showModal2}>Forgot Password ?</a>
                  </div>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="primary-btn w-100 mb-3"
                    loading={loading}
                  >
                    Login
                  </Button>

                  <Button
                    type="primary"
                    htmlType="button"
                    className="primary-btn w-100"
                  >
                    <Link to={"/register"} className="manage__Link">
                      Register
                    </Link>
                  </Button>
                </Form.Item>
              </Form>
            </div>

            <div className="newto__block">
              <h3>
                New to{" "}
                {WhiteLabeling?.whiteLebelData?.[0]?.name || "JewelCloud"}:{" "}
                <span>{designerName}</span>
              </h3>
            </div>
          </div>
        </div>

        <div className="col-md-12 login__slider">
          <div className="slider__section">
            {designerLogoData ? (
              <OwlCarousel className="owl-theme" {...options}>
                {designerLogoData.map((item, i) => {
                  return item.logoImage ? (
                    <div class="item" key={i}>
                      <div className="slider__block">
                        <img src={item.logoImage} alt="" />
                      </div>
                    </div>
                  ) : null;
                })}
              </OwlCarousel>
            ) : null}
          </div>
        </div>

        <div className="col-md-12 p-0">
          <div className="footer__section login__footer">
            <div className="left__block">
              <div className="footer__logo">
                <img
                  className="imageHover"
                  src={WhiteLabeling?.whiteLebelData?.[0]?.footerLogo || ""}
                  alt=""
                  onClick={() =>
                    handleOpenLink(
                      WhiteLabeling?.whiteLebelData?.[0]?.footerLogoLink || ""
                    )
                  }
                />
              </div>
              <div className="footer__menu">
                <ul>
                  <li>
                    <Link to={"/termsOfService"}>{t("Terms of Service")}</Link>
                  </li>
                  <li>
                    <Link to={"/privacyStatement"}>
                      {t("Privacy Statement")}
                    </Link>
                  </li>
                  <li>
                    <span>
                      Copyright {new Date().getFullYear() + " "}
                      {WhiteLabeling?.whiteLebelData?.[0]?.name || ""}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="right__block">
              <ul>
                <li>
                  <a href="#">
                    <img src={footerCompanyLogo1} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={footerCompanyLogo2} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={footerCompanyLogo3} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={footerCompanyLogo4} />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src={footerCompanyLogo5} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal
        visible={isModalVisible}
        className="ytvideo__modal"
        onCancel={handleCancel}
      >
        <div className="iframe__section">
          <iframe
            width="100%"
            height="450"
            src="https://www.youtube.com/embed/RQqc2onAX9M?rel=0&wmode=transparent"
            title="YouTube video player"
            frameborder={"0"}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen="true"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
          ></iframe>
        </div>
      </Modal>

      {/* Forgot Password Modal Starts */}
      <Modal
        title="Forgot Password"
        visible={isModalVisible2}
        className="forgot__password"
        onCancel={handleCancel2}
        width={400}
        footer={
          <div className="input__block">
            <Button
              className="primary-btn"
              onClick={handleResetPassword}
              loading={resetPasswordLoading}
            >
              Reset Password
            </Button>
          </div>
        }
      >
        <div className="form__fields p-0 border-0 mh-auto">
          <div className="input__block">
            <label>Email Address</label>
            <input
              type="text"
              placeholder="Enter Email Address"
              name="email"
              value={resetPassword.email}
              onChange={handleOnChange}
              className={validations.emailVal && "border__red"}
            />
            <div>
              {validations.emailVal && (
                <p className="error-color-red">{validations.emailVal}</p>
              )}
            </div>
          </div>
        </div>
      </Modal>
      {/* Forgot Password Modal Ends */}
    </div>
  );
};

export default Login;
