import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import Moment from "moment";
import { Select, Button, Table, Modal, Spin } from "antd";
import logoImg from "../../assets/images/JewelCloud_Logo_Black.jpg";
import footerlogoImg from "../../assets/images/GemFind-footer.jpg";
import { NotificationManager } from "react-notifications";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PurchaseOrderServices from "../../services/purchaseOrder.service";
import AdminManageLanguageService from "../../services/admin-manageLanguage.service";

const { Option } = Select;

const PurchaseOrderDetails = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [purchaseOrdersHistory, SetPurchaseOrderHistory] = useState([]);
  const [purchaseOrdersProductDetails, SetPurchaseOrderProductDetails] =
    useState([]);
  const [purchaseOrdersDtRetailer, SetPurchaseOrderDtRetailer] = useState([]);
  const [purchaseOrdersDtVendor, SetPurchaseOrderDtVendor] = useState([]);
  const [purchaseOrdersProductPrice, SetPurchaseOrderProductPrice] = useState(
    {}
  );
  const [dealerId, setDealerId] = useState(0);
  const loginDetials = useSelector((state) => state.loginReducer);
  const [disableBtn, setDisableBtn] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [updateVendorDropdown, setupdateVendorDropdown] = useState(false);
  const [visible, setVisible] = useState(false);
  const [poOrderById, setpoOrderById] = useState(0);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [generateOrderModalVisible, setgenerateOrderModalVisible] =
    useState(false);
  const [reorderData, setReorderData] = useState({
    PoName: "",
    notes: "",
    referenceNumber: "",
  });
  const [vendorType, setVendorType] = useState("");
  const [validation, setValidations] = useState({
    PONameVal: "",
    notesVal: "",
  });
  const [scaList, setScaList] = useState([]);
  const [selectedSCA, setSelectedSCA] = useState("");
  const [accNo, setAccNo] = useState("");
  const [error, setError] = useState(false);
  const [listerr, setListerr] = useState(false);
  const location = useLocation();
  const filteredData = scaList.filter((item) => item.isVendorapprove);
  const dataToRender = filteredData.length > 0 ? filteredData : scaList;

  useEffect(() => {
    if (location.state) {
      if (location.state.POOrderID) {
        setpoOrderById(location.state.POOrderID);
      }
    }
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          if (
            loginDetials.loginDetials.responseData.dealerMemberTypeName ==
            "Retailer"
          ) {
            GetMyPurchaseOrderHistoryDetails(
              loginDetials.loginDetials.responseData.dealerId,
              ""
            );
          } else {
            GetMyPurchaseOrderHistoryDetails(
              loginDetials.loginDetials.responseData.dealerId,
              loginDetials.loginDetials.responseData.dealerMemberTypeName
            );
            setVendorType(
              loginDetials.loginDetials.responseData.dealerMemberTypeName
            );
          }
        }
      }
    }
  }, []);

  const GetMyPurchaseOrderHistoryDetails = (id, vendorType) => {
    let inputData = {
      dealerID: id,
      orderID: location.state.POOrderID ? location.state.POOrderID : 0,
      pOname: location.state.POOrderName ? location.state.POOrderName : "",
      type: vendorType ? vendorType : "",
    };
    setLoading(true);
    try {
      PurchaseOrderServices.purchaseorderhistotry(inputData)
        .then((response) => {
          setLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            SetPurchaseOrderHistory(responseData.purchaseOrder[0]);
            SetPurchaseOrderProductDetails(responseData.productDetail);
            SetPurchaseOrderDtRetailer(responseData.dtRetailer[0]);
            SetPurchaseOrderDtVendor(responseData.dtVendor[0]);
            SetPurchaseOrderProductPrice(responseData.productprice);
            handleGetSCAList(responseData.purchaseOrder[0].vendorID);
            setAccNo(responseData.purchaseOrder[0].ediAccNumber);
            if (
              responseData.purchaseOrder[0].ediAccNumber == "" ||
              responseData.purchaseOrder[0].ediAccNumber === null
            ) {
              setError(true);
            } else {
              setError(false);
            }
            setLoading(false);
          } else {
            SetPurchaseOrderHistory([]);
            SetPurchaseOrderProductDetails([]);
            SetPurchaseOrderDtRetailer([]);
            SetPurchaseOrderDtVendor([]);
            SetPurchaseOrderProductPrice({});
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleUpdateOrderStatus = () => {
    let inputData = {
      dealerID: dealerId.toString(),
      orderID: poOrderById ? poOrderById.toString() : "",
      status: "2",
    };

    try {
      PurchaseOrderServices.UpdateOrderStatus(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message === "Success") {
            setCancelModalVisible(false);
            NotificationManager.success(
              t("Order Has Been Successfully Cancelled.")
            );
            GetMyPurchaseOrderHistoryDetails(dealerId, vendorType);
          } else {
            setCancelModalVisible(false);
            NotificationManager.error(t("Order Has Not Been Cancelled."));
          }
        })
        .catch((error) => {
          console.log(error);
          setCancelModalVisible(false);
        });
    } catch (error) {
      console.log(error);
      setCancelModalVisible(false);
    }
  };
  const handleGetSCAList = async (id) => {
    try {
      await AdminManageLanguageService.getSCADrpdown(id)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setScaList(responseData || []);
            }
          } else {
            setScaList([]);
          }
        })
        .catch((error) => {
          setScaList([]);
        });
    } catch (error) {
      setScaList([]);
    }
  };
  const handleSelectChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setSelectedSCA(e);
    }
  };
  const handleCreatePoNo = () => {
    let inputData = {
      dealerID: dealerId ? dealerId.toString() : "",
    };
    try {
      PurchaseOrderServices.CreatePONumber(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setReorderData({
              PoName: responseData,
            });
          } else {
            setReorderData({
              PoName: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setReorderData({
            PoName: "",
          });
        });
    } catch (error) {
      console.log(error);
      setReorderData({
        PoNumber: "",
      });
    }
  };
  const handleCancelModal = () => {
    setCancelModalVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
    setReorderData({ PoName: "", notes: "", referenceNumber: "" });
  };

  const handleOk = () => {
    setgenerateOrderModalVisible(true);
  };
  const handleCancel1 = () => {
    setgenerateOrderModalVisible(false);
    setListerr(false);
  };
    function handlePrintDiamondList() {
    var disp_setting =
      "toolbar=yes,location=no,directories=yes,menubar=yes,resizable=yes,";
    disp_setting += "scrollbars=yes,width=1095, height=600, left=100, top=25";
    var docprint = window.open("", "", disp_setting);
    docprint.document.open();
    var htmlContent = document.getElementById("printsection").innerHTML;
    htmlContent +=
      '<style>.subheading {font-size: 16px;font-family: "LexendDeca-SemiBold";margin: 1rem 0;}.print__section .top__header {display: flex;justify-content: space-between;align-items: center;}.print__section .header__logo img {width:160px;}.print__section table thead {background-color:#efefef;}.print__section .table__content {overflow: hidden;text-overflow: ellipsis;margin-bottom:0;}.print__section .bottom__footer {background: #e9e8ea;padding: 10px;}.print__section .bottom__footer img {width:160px;}</style>';
    htmlContent +=
      '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">';
    docprint.document.write(htmlContent);
    docprint.document.close();

    setTimeout(() => {
      docprint.print();
    }, 500);
    docprint.focus();
  }

  const handleValidation = () => {
    const { PoName, notes } = reorderData;

    const validation = {
      PONameVal: "",
      notesVal: "",
    };
    let isValid = true;

    if (!PoName.trim()) {
      isValid = false;
      validation.PONameVal = t("PO Name is Compulsory");
    } else {
      validation.PONameVal = "";
    }

    setValidations(validation);
    return isValid;
  };

  const handleVendorDropdownopen = () => {
    setupdateVendorDropdown(true);
  };
  const handleVendorDropdownClose = () => {
    setupdateVendorDropdown(false);
  };

  const handleSubmitStatus = () => {
    try {
      setSubmitLoading(true);
      let inputData = {
        dealerID: dealerId.toString(),
        orderID: poOrderById ? poOrderById.toString() : "",
        status: purchaseOrdersHistory.status,
      };
      setLoading(true);
      PurchaseOrderServices.UpdateOrderStatus(inputData)
        .then((response) => {
          var message = response.data.message;
          setLoading(false);
          setSubmitLoading(false);
          setupdateVendorDropdown(false);
          if (message === "Success") {
            NotificationManager.success(t("Status Updated Successfully."));
            GetMyPurchaseOrderHistoryDetails(dealerId, vendorType);
          } else {
            NotificationManager.error(t("Status not Updated."));
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setSubmitLoading(false);
          setupdateVendorDropdown(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSubmitLoading(false);
      setupdateVendorDropdown(false);
    }
    setDisableBtn(true);
  };

  const handleCancelOrder = () => {
    setCancelModalVisible(true);
  };

  const handleCreateReOrder = () => {
    var isValid = handleValidation();

    if (!isValid) {
      handleCancel1();
      return false;
    } else if (selectedSCA <= 0) {
      setListerr(true);
      return false;
    }

    let inputData = {
      dealerID: dealerId.toString(),
      poid: poOrderById ? poOrderById.toString() : "",
      poName: reorderData.PoName ? reorderData.PoName.toString() : "",
      poNote: reorderData.notes ? reorderData.notes.toString() : "",
      referenceNumber: reorderData.referenceNumber
        ? reorderData.referenceNumber.toString()
        : "",
      scaccode: selectedSCA ? selectedSCA.toString() : "",
    };
    setSubmitLoading(true);
    try {
      PurchaseOrderServices.CreateReOrder(inputData)
        .then((response) => {
          setSubmitLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData == "Please Enter Acc No for this Vendor") {
              NotificationManager.error(
                t("Kindly Add Account Number for this Vendor.")
              );
              setgenerateOrderModalVisible(false);
            } else if (responseData == "Vendor Retailer Not connected") {
              NotificationManager.error(
                t(
                  "Your connection to this vendor is no longer active. Please request access to this vendor again before placing orders. If you believe this is a mistake please contact GemFind support."
                )
              );
              setgenerateOrderModalVisible(false);
            } else {
              NotificationManager.success(
                t("Order Has Been Successfully ReOrder.")
              );
              setgenerateOrderModalVisible(false);
              setVisible(false);
              setSubmitLoading(false);
              handleCancel();
            }
          } else {
            NotificationManager.error(t("Order Has Not Been Cancelled."));
            setSubmitLoading(false);
            handleCancel();
          }
        })
        .catch((error) => {
          console.log(error);
          setSubmitLoading(false);
          handleCancel();
        });
    } catch (error) {
      console.log(error);
      setSubmitLoading(false);
      handleCancel();
    }
  };
  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setDisableBtn(false);
      SetPurchaseOrderHistory({
        ...purchaseOrdersHistory,
        [name]: e,
      });
    } else {
      setDisableBtn(false);
      SetPurchaseOrderHistory({
        ...purchaseOrdersHistory,
        [e.target.name]: e.target.value,
      });
    }

    if (isSelect === "select") {
      setReorderData({
        ...reorderData,
        [name]: e,
      });
    } else {
      setReorderData({
        ...reorderData,
        [e.target.name]: e.target.value,
      });

      if (e.target.name == "PoName") {
        setValidations((prevdata) => ({
          ...prevdata,
          PONameVal: "",
        }));
      }
      if (e.target.name == "notes") {
        setValidations((prevdata) => ({
          ...prevdata,
          notesVal: "",
        }));
      }
    }
  };

  const RetailerColumns = [
    {
      title: t("Item"),
      dataIndex: "path",
      key: "path",
      render: (item, row) => {
        return (
          <img
            className="img-fluid"
            style={{ width: "50px", height: "50px" }}
            src={row.path ? row.path : ""}
          />
        );
      },
      width: 100,
    },
    {
      title: t("Product Description"),
      dataIndex: "productDesc",
      key: "productDesc",
    },
    {
      title: t("SKU"),
      dataIndex: "retailerStockNumber",
      key: "retailerStockNumber",
    },
    {
      title: t("Vendor SKU"),
      dataIndex: "retailerStyle",
      key: "retailerStyle",
    },
    {
      title: t("Price"),
      dataIndex: "strPurchasePrice",
      key: "strPurchasePrice",
    },
    {
      title: t("Qty"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: t("SubTotal"),
      dataIndex: "strEXTWholeSalePrice",
      key: "strEXTWholeSalePrice",
    },
  ];

  const VendorColumns = [
    {
      title: t("Item"),
      dataIndex: "path",
      key: "path",
      render: (item, row) => {
        return (
          <img
            className="img-fluid"
            style={{ width: "50px", height: "50px" }}
            src={row.path ? row.path : ""}
          />
        );
      },
      width: 100,
    },
    {
      title: t("Product Description"),
      dataIndex: "productDesc",
      key: "productDesc",
    },
    {
      title: t("Vendor SKU"),
      dataIndex: "retailerStyle",
      key: "retailerStyle",
    },
    {
      title: t("Price"),
      dataIndex: "strPurchasePrice",
      key: "strPurchasePrice",
    },
    {
      title: t("Qty"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: t("SubTotal"),
      dataIndex: "strEXTWholeSalePrice",
      key: "strEXTWholeSalePrice",
    },
  ];

  const RetailerPrintPO = [
    {
      title: t("Product Description"),
      dataIndex: "productDesc",
      key: "productDesc",
    },
    {
      title: t("SKU"),
      dataIndex: "retailerStockNumber",
      key: "retailerStockNumber",
    },
    {
      title: t("Vendor SKU"),
      dataIndex: "retailerStyle",
      key: "retailerStyle",
    },
    {
      title: t("Price"),
      dataIndex: "strPurchasePrice",
      key: "strPurchasePrice",
    },
    {
      title: t("Qty"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: t("SubTotal"),
      dataIndex: "strEXTWholeSalePrice",
      key: "strEXTWholeSalePrice",
    },
  ];

  const VendorPrintPO = [
    {
      title: t("Product Description"),
      dataIndex: "productDesc",
      key: "productDesc",
    },
    {
      title: t("Vendor SKU"),
      dataIndex: "retailerStyle",
      key: "retailerStyle",
    },
    {
      title: t("Price"),
      dataIndex: "strPurchasePrice",
      key: "strPurchasePrice",
    },
    {
      title: t("Qty"),
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: t("SubTotal"),
      dataIndex: "strEXTWholeSalePrice",
      key: "strEXTWholeSalePrice",
    },
  ];

  return (
    <React.Fragment>
      <div className="form__fields">
        <Spin spinning={loading}>
          {!loading && (
            <div className="row magento__mapping">
              {purchaseOrdersHistory.statusValue === "Cancelled" ||
              purchaseOrdersHistory.statusValue ===
                "Completed" ? null : loginDetials.loginDetials.responseData
                  .dealerMemberTypeName == "Retailer" || vendorType == "" ? (
                <div className="row">
                  <div className="col-md-12 heading__block mb-3">
                    <div className="col-md-6">
                      <h4 class="workarea__heading mt-0 mb-0">
                        {" "}
                        {t("Purchase Order Detail")}{" "}
                      </h4>
                    </div>
                    <div className="col-md-6 col-sm-5 d-flex justify-content-end">
                      <div className="input__block mb-0 mr-2 col-md-6">
                        <Select
                          className="border__grey"
                          placeholder={t("Selected")}
                          name="status"
                          value={
                            purchaseOrdersHistory.status == 2
                              ? "2"
                              : purchaseOrdersHistory.status == 0
                              ? "0"
                              : "0"
                          }
                          optionFilterProp="children"
                          onChange={(e) =>
                            handleOnChange(e, "select", "status")
                          }
                        >
                          <Option value="0">{t("Submitted")}</Option>
                          <Option value="2">{t("Cancelled")}</Option>
                        </Select>
                      </div>
                      <button
                        className={disableBtn ? "disabled-btn" : "primary-btn"}
                        disabled={disableBtn}
                        loading={submitLoading}
                        onClick={handleSubmitStatus}
                      >
                        {t("Update Detail")}
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-12 heading__block mb-3">
                    <div className="col-md-7">
                      <h4 class="workarea__heading mt-0 mb-0">
                        {" "}
                        {t("Purchase Order Detail")}{" "}
                      </h4>
                    </div>
                    <div className="col-md-5 col-sm-5 d-flex justify-content-end">
                      <div className="input__block mb-0 mr-2 col-md-6">
                        <Select
                          className="border__grey"
                          placeholder={t("Selected")}
                          name="status"
                          value={
                            purchaseOrdersHistory.status == 0
                              ? "0"
                              : purchaseOrdersHistory.status == 4
                              ? "4"
                              : purchaseOrdersHistory.status == 3
                              ? "3"
                              : purchaseOrdersHistory.status == 1
                              ? "1"
                              : purchaseOrdersHistory.status == 2
                              ? "2"
                              : purchaseOrdersHistory.status == 5
                              ? "5"
                              : ""
                          }
                          optionFilterProp="children"
                          onChange={(e) =>
                            handleOnChange(e, "select", "status")
                          }
                        >
                          <Option value="3">{t("Open")}</Option>
                          <Option value="0">{t("Pending")}</Option>
                          <Option value="4">{t("Shipped")}</Option>
                          <Option value="5">{t("Unpaid")}</Option>
                          <Option value="1">{t("Completed")}</Option>
                          <Option value="2">{t("Cancelled")}</Option>
                        </Select>
                      </div>
                      <button
                        className={disableBtn ? "disabled-btn" : "primary-btn"}
                        disabled={disableBtn}
                        onClick={handleVendorDropdownopen}
                      >
                        {t("Update Detail")}
                      </button>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-md-12">
                <h3 className="subheading mt-0">
                  {t("Order")}{" "}
                  {purchaseOrdersHistory.poName
                    ? purchaseOrdersHistory.poName
                    : null}
                </h3>
                <p className="mb-0">
                  {t("Status")}:{" "}
                  <span className="font__bold">
                    {purchaseOrdersHistory.statusValue
                      ? purchaseOrdersHistory.statusValue
                      : ""}
                  </span>
                </p>
                <p className="mb-0">
                  {t("Order Date")}:{" "}
                  {purchaseOrdersHistory.createdDate
                    ? Moment(purchaseOrdersHistory.createdDate).format(
                        "MMMM DD,YYYY"
                      )
                    : ""}{" "}
                </p>
              </div>

              <div className="col-md-3 mb-4">
                <h5 className="subheading">{t("Retailer Details")} :</h5>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealercompany
                    ? purchaseOrdersDtRetailer.dealercompany
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealername
                    ? purchaseOrdersDtRetailer.dealername
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerAddress
                    ? purchaseOrdersDtRetailer.dealerAddress
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerCity
                    ? purchaseOrdersDtRetailer.dealerCity
                    : "null"}
                  ,{" "}
                  {purchaseOrdersDtRetailer.dealerState
                    ? purchaseOrdersDtRetailer.dealerState
                    : "null"}
                  ,
                  {purchaseOrdersDtRetailer.dealerZIP
                    ? purchaseOrdersDtRetailer.dealerZIP
                    : "null"}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerCountry
                    ? purchaseOrdersDtRetailer.dealerCountry
                    : ""}
                </p>
                <p className="mb-0">
                  T:
                  {purchaseOrdersDtRetailer.dealerphone
                    ? purchaseOrdersDtRetailer.dealerphone
                    : ""}
                </p>
                <p className="mb-0">
                  {t("Email")}:{" "}
                  {purchaseOrdersDtRetailer.dealeremail
                    ? purchaseOrdersDtRetailer.dealeremail
                    : ""}
                </p>
              </div>

              <div className="col-md-3 mb-4">
                <h5 className="subheading">{t("Vendor Details")} :</h5>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealercompany
                    ? purchaseOrdersDtVendor.dealercompany
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealername
                    ? purchaseOrdersDtVendor.dealername
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerAddress
                    ? purchaseOrdersDtVendor.dealerAddress
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerCity
                    ? purchaseOrdersDtVendor.dealerCity
                    : "null"}
                  ,{" "}
                  {purchaseOrdersDtVendor.dealerState
                    ? purchaseOrdersDtVendor.dealerState
                    : "null"}
                  ,
                  {purchaseOrdersDtVendor.dealerZIP
                    ? purchaseOrdersDtVendor.dealerZIP
                    : "null"}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerCountry
                    ? purchaseOrdersDtVendor.dealerCountry
                    : ""}
                </p>
                <p className="mb-0">
                  T:
                  {purchaseOrdersDtVendor.dealerphone
                    ? purchaseOrdersDtVendor.dealerphone
                    : ""}
                </p>
                <p className="mb-0">
                  {t("Email")}:{" "}
                  {purchaseOrdersDtVendor.dealeremail
                    ? purchaseOrdersDtVendor.dealeremail
                    : ""}
                </p>
              </div>

              <div className="col-md-6 mb-3 text-right">
                {loginDetials.loginDetials.responseData.dealerMemberTypeName ==
                  "Retailer" || vendorType == "" ? (
                  <button
                    className="primary-btn mr-2"
                    onClick={() => {
                      setVisible(true);
                      handleCreatePoNo();
                    }}
                  >
                    {t("Reorder PO")}
                  </button>
                ) : null}

                <button
                  className="primary-btn mr-2"
                  onClick={() => {
                    handlePrintDiamondList();
                  }}
                >
                  {t("Print PO")}
                </button>
                {purchaseOrdersHistory.statusValue == "Cancelled" ? null : (
                  <button className="primary-btn" onClick={handleCancelOrder}>
                    {t("Cancel Order")}
                  </button>
                )}
              </div>
              <div className="col-md-12">
                <p>
                  {t("Order Notes:")}{" "}
                  {purchaseOrdersHistory.poNote
                    ? purchaseOrdersHistory.poNote
                    : ""}
                </p>
              </div>

              <div className="divForm">
                <Table
                  columns={
                    loginDetials.loginDetials.responseData
                      .dealerMemberTypeName == "Retailer" || vendorType == ""
                      ? RetailerColumns
                      : VendorColumns
                  }
                  dataSource={purchaseOrdersProductDetails}
                  scroll={{ x: 600, y: 800 }}
                  pagination={false}
                />

                <div className="d-flex justify-content-end">
                  <div className="total__section p-3">
                    <p>
                      <span className="font__bold">{t("Total Item")}:</span>
                      {purchaseOrdersProductPrice.totalQuantity
                        ? purchaseOrdersProductPrice.totalQuantity
                        : 0}
                    </p>
                    <p>
                      <span className="font__bold">{t("SubTotal")}:</span> $
                      {purchaseOrdersProductPrice.poTotal
                        ? parseFloat(
                            purchaseOrdersProductPrice.poTotal
                          ).toFixed(2)
                        : "0.00"}
                    </p>
                    <p>
                      <span className="font__bold">{t("PO Total")}:</span> $
                      {purchaseOrdersProductPrice.poTotal
                        ? parseFloat(
                            purchaseOrdersProductPrice.poTotal
                          ).toFixed(2)
                        : "0.00"}
                    </p>
                  </div>
                </div>
                <p>{t("Vendor to provide shipping/insurance info.")}</p>
              </div>
            </div>
          )}
        </Spin>

        <Modal
          title={t("Reorder")}
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              {t("Cancel")}
            </Button>,
            <Button key="submit" type="primary" onClick={handleOk}>
              {t("Generate PO")}
            </Button>,
          ]}
          width={400}
        >
          <div className="form__fields p-0 border-0">
            <div className="input__block">
              <label>{t("PO Name")}</label>
              <input
                type="text"
                name="PoName"
                value={reorderData.PoName}
                onChange={handleOnChange}
                className={validation.PONameVal && "border__red"}
                disabled
              />
              {validation.PONameVal && (
                <p className="error-color-red">{validation.PONameVal}</p>
              )}
            </div>
            <div className="input__block">
              <label>{t("Reference Number")}</label>
              <input
                type="text"
                name="referenceNumber"
                value={reorderData.referenceNumber}
                onChange={handleOnChange}
                className={validation.PONameVal && "border__red"}
              />
              {/* {validation.PONameVal && (
                <p className="error-color-red">{validation.PONameVal}</p>
              )} */}
            </div>

            <div className="input__block">
              <label>{t("Notes")}</label>
              <textarea
                name="notes"
                value={reorderData.notes}
                onChange={handleOnChange}
                className={validation.notesVal && "border__red"}
              ></textarea>
              {validation.notesVal && (
                <p className="error-color-red">{validation.notesVal}</p>
              )}
            </div>
          </div>
        </Modal>
      </div>

      {/* Generate PO Modal */}

      <Modal
        title={t("Purchase Order Summary")}
        centered
        visible={generateOrderModalVisible}
        onOk={handleCreateReOrder}
        onCancel={handleCancel1}
        footer={[
          <Button key="back" onClick={handleCancel1}>
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleCreateReOrder}
            loading={submitLoading}
            disabled={dataToRender.length === 0}
          >
            {t("Submit PO")}
          </Button>,
        ]}
        width={1000}
      >
        <div className="form__fields">
          <Spin spinning={loading}>
            <div className="row magento__mapping">
              <div className="col-md-4 mb-4">
                <h5 className="subheading">{t("Shipping Address :")}</h5>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealercompany
                    ? purchaseOrdersDtRetailer.dealercompany
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealername
                    ? purchaseOrdersDtRetailer.dealername
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerAddress
                    ? purchaseOrdersDtRetailer.dealerAddress
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerCity
                    ? purchaseOrdersDtRetailer.dealerCity
                    : "null"}
                  ,{" "}
                  {purchaseOrdersDtRetailer.dealerState
                    ? purchaseOrdersDtRetailer.dealerState
                    : "null"}
                  ,
                  {purchaseOrdersDtRetailer.dealerZIP
                    ? purchaseOrdersDtRetailer.dealerZIP
                    : "null"}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerCountry
                    ? purchaseOrdersDtRetailer.dealerCountry
                    : ""}
                </p>
                <p className="mb-0">
                  T:
                  {purchaseOrdersDtRetailer.dealerphone
                    ? purchaseOrdersDtRetailer.dealerphone
                    : ""}
                </p>
                <p className="mb-0">
                  {t("Email")}:{" "}
                  {purchaseOrdersDtRetailer.dealeremail
                    ? purchaseOrdersDtRetailer.dealeremail
                    : ""}
                </p>
              </div>

              <div className="col-md-4 mb-4">
                <h5 className="subheading">{t("Manufacturer Address :")}</h5>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealercompany
                    ? purchaseOrdersDtVendor.dealercompany
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealername
                    ? purchaseOrdersDtVendor.dealername
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerAddress
                    ? purchaseOrdersDtVendor.dealerAddress
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerCity
                    ? purchaseOrdersDtVendor.dealerCity
                    : "null"}
                  ,{" "}
                  {purchaseOrdersDtVendor.dealerState
                    ? purchaseOrdersDtVendor.dealerState
                    : "null"}
                  ,
                  {purchaseOrdersDtVendor.dealerZIP
                    ? purchaseOrdersDtVendor.dealerZIP
                    : "null"}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerCountry
                    ? purchaseOrdersDtVendor.dealerCountry
                    : ""}
                </p>
                <p className="mb-0">
                  T:
                  {purchaseOrdersDtVendor.dealerphone
                    ? purchaseOrdersDtVendor.dealerphone
                    : ""}
                </p>
                <p className="mb-0">
                  {t("Email")}:{" "}
                  {purchaseOrdersDtVendor.dealeremail
                    ? purchaseOrdersDtVendor.dealeremail
                    : ""}
                </p>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="input__block">
                    <label> {t("Account Number")}: </label>
                    <input
                      type="text"
                      name="AccNo"
                      value={accNo}
                      className={error && "border__red"}
                      disabled
                    />
                    {error && (
                      <p className="error-color-red">
                        {t("Kindly Add Account Number First")}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="input__block mb-3">
                    <label>
                      {t("Standard Carrier Alpha")} :{" "}
                      <span className="mandatory">*</span>
                    </label>{" "}
                    <Select
                      value={selectedSCA}
                      autocomplete="off"
                      name="SCAvalue"
                      showSearch
                      placeholder={t("Select SCA")}
                      onChange={(e) =>
                        handleSelectChange(e, "select", "SCAvalue")
                      }
                      className={
                        dataToRender.length === 0 || listerr
                          ? "border__red"
                          : "border__grey"
                      }
                    >
                      {dataToRender.map((item) => (
                        <Option key={item.scaid} value={item.scaid}>
                          {item.scacname}
                        </Option>
                      ))}
                    </Select>
                    {dataToRender.length === 0 && (
                      <p className="error-color-red">
                        {t(
                          "This vendor has not configured any SCA Codes. Please reach out to GemFind for assistance."
                        )}
                      </p>
                    )}
                    {listerr && (
                      <p className="error-color-red">
                        {t("Please Select Standard Carrier Alpha")}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <p>
                  {t("Order Notes")}:{" "}
                  {purchaseOrdersHistory.poNote
                    ? purchaseOrdersHistory.poNote
                    : ""}
                </p>
              </div>
              <div className="divForm">
                <Table
                  columns={vendorType == "" ? RetailerPrintPO : VendorPrintPO}
                  dataSource={purchaseOrdersProductDetails}
                  scroll={{ x: 600, y: 800 }}
                  pagination={false}
                />

                <div className="d-flex justify-content-end">
                  <div className="total__section p-3">
                    <p>
                      <span className="font__bold">{t("Total Item")}:</span>
                      {purchaseOrdersProductPrice.totalQuantity
                        ? purchaseOrdersProductPrice.totalQuantity
                        : 0}
                    </p>
                    <p>
                      <span className="font__bold">{t("SubTotal")}:</span> $
                      {purchaseOrdersProductPrice.poTotal
                        ? parseFloat(
                            purchaseOrdersProductPrice.poTotal
                          ).toFixed(2)
                        : "0.00"}
                    </p>
                    <p>
                      <span className="font__bold">{t("PO Total")}:</span> $
                      {purchaseOrdersProductPrice.poTotal
                        ? parseFloat(
                            purchaseOrdersProductPrice.poTotal
                          ).toFixed(2)
                        : "0.00"}
                    </p>
                  </div>
                </div>
                <p>{t("Vendor to provide shipping/insurance info.")}</p>
              </div>
            </div>
          </Spin>
        </div>
      </Modal>
      {/* End Modal */}

      {/* Cancel Order Modal */}
      <Modal
        className="delete_conv_modal"
        centered
        visible={cancelModalVisible}
        onCancel={handleCancelModal}
        width={400}
        footer={[
          <Button type="primary" onClick={handleUpdateOrderStatus}>
            {t("Cancel Order")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <h5 className="reset-pass-conf-heading">
                      {t("Cancel The")} "{purchaseOrdersHistory.poName}"{" "}
                      {t("Order")}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* End Cancel Order Modal */}

      <Modal
        className="modalconsupld__section"
        centered
        visible={updateVendorDropdown}
        onCancel={handleVendorDropdownClose}
        maskClosable={false}
        width={400}
        footer={[
          <Button key="back" onClick={handleVendorDropdownClose}>
            {t("No")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={submitLoading}
            onClick={handleSubmitStatus}
          >
            {t("Yes")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="input__block">
                  <label>{t("Are you sure you want to change status?")}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <React.Fragment>
        {/* // code for print section //  */}
        <div id="printsection" style={{ padding: "20px", display: "none" }}>
          <div
            className="form__fields print__section"
            style={{ padding: "10px" }}
          >
            <div className="row">
              <div
                className="col-md-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "15px",
                }}
              >
                <div
                  className="row"
                  style={{
                    width: "90%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="col-md-10">
                    <div className="top__header">
                      <div className="header__text">
                        <h3 className="subheading">
                          {t("Purchase Order")}{" "}
                          {purchaseOrdersHistory.poName
                            ? purchaseOrdersHistory.poName
                            : null}
                        </h3>
                      </div>
                      <div className="header__text">
                        <h3 className="subheading">
                          {t("Order Date:")}{" "}
                          {purchaseOrdersHistory.createdDate
                            ? Moment(purchaseOrdersHistory.createdDate).format(
                                "MMMM DD,YYYY"
                              )
                            : ""}{" "}
                        </h3>
                      </div>
                      <div className="header__text">
                        <h3 className="subheading">
                          {t("Ship Via:")}{" "}
                          {purchaseOrdersHistory.scacname
                            ? purchaseOrdersHistory.scacname
                            : null}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    tableLayout: "fixed",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          width: "33.33%",
                          verticalAlign: "top",
                          paddingLeft: "35px",
                        }}
                      >
                        <h5>{t("Vendor Details")} :</h5>
                        <p className="mb-0">
                          {purchaseOrdersDtVendor.dealercompany || ""}
                        </p>
                        <p className="mb-0">
                          {purchaseOrdersDtVendor.dealername || ""}
                        </p>
                        <p className="mb-0">
                          {purchaseOrdersDtVendor.dealerAddress || ""}
                        </p>
                        <p className="mb-0">
                          {purchaseOrdersDtVendor.dealerCity || "null"},{" "}
                          {purchaseOrdersDtVendor.dealerState || "null"},{" "}
                          {purchaseOrdersDtVendor.dealerZIP || "null"}
                        </p>
                        <p className="mb-0">
                          {purchaseOrdersDtVendor.dealerCountry || ""}
                        </p>
                        <p className="mb-0">
                          T: {purchaseOrdersDtVendor.dealerphone || ""}
                        </p>
                        <p className="mb-0">
                          {t("Email")}:{" "}
                          {purchaseOrdersDtVendor.dealeremail || ""}
                        </p>
                        <p>
                          {t("Order Notes:")}{" "}
                          {purchaseOrdersHistory.poNote || ""}
                        </p>
                      </td>
                      <td
                        style={{
                          width: "33.33%",
                          verticalAlign: "top",
                          textAlign: "center",
                        }}
                      >
                        <h5 style={{ fontSize: "25px", fontWeight: "bold" }}>
                          {t("Purchase Order")}
                        </h5>
                      </td>
                      <td
                        style={{
                          width: "33.33%",
                          verticalAlign: "top",
                          textAlign: "left",
                          paddingLeft: "70px",
                        }}
                      >
                        <h5>{t("Retailer Details :")}</h5>
                        <p className="mb-0">
                          {purchaseOrdersDtRetailer.dealercompany || ""}
                        </p>
                        <p className="mb-0">
                          {purchaseOrdersDtRetailer.dealername || ""}
                        </p>
                        <p className="mb-0">
                          {purchaseOrdersDtRetailer.dealerAddress || ""}
                        </p>
                        <p className="mb-0">
                          {purchaseOrdersDtRetailer.dealerCity || "null"},{" "}
                          {purchaseOrdersDtRetailer.dealerState || "null"},{" "}
                          {purchaseOrdersDtRetailer.dealerZIP || "null"}
                        </p>
                        <p className="mb-0">
                          {purchaseOrdersDtRetailer.dealerCountry || ""}
                        </p>
                        <p className="mb-0">
                          T: {purchaseOrdersDtRetailer.dealerphone || ""}
                        </p>
                        <p className="mb-0">
                          {t("Email")}:{" "}
                          {purchaseOrdersDtRetailer.dealeremail || ""}
                        </p>
                        <p className="mb-0">
                          {t("Status:")}{" "}
                          <span className="font__bold">
                            {purchaseOrdersHistory.statusValue || ""}
                          </span>
                        </p>
                        <p className="mb-0">
                          {t("Order Date:")}{" "}
                          {purchaseOrdersHistory.createdDate || ""}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="col-md-12"></div>

              <div className="col-lg-12 col-md-12 mt-4">
                <div className="table__block">
                  <table class="table table-bordered table-striped">
                    <thead class="thead-light">
                      <tr>
                        <th width={"5%"}>{t("Item")}</th>
                        <th width={"5%"}>{t("Product Description")}</th>
                        {loginDetials.loginDetials.responseData
                          .dealerMemberTypeName == "Retailer" ||
                        vendorType == "" ? (
                          <th width={"3%"}>SKU</th>
                        ) : null}

                        <th width={"3%"}>
                          {t("Vendor")} {t("SKU")}
                        </th>
                        <th width={"3%"}>{t("Price")}</th>
                        <th width={"3%"}>{t("Qty")}</th>
                        <th width={"4%"}>{t("SubTotal")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {purchaseOrdersProductDetails.map((item, key) => {
                        return (
                          <tr key={key}>
                            <td>
                              <p className="table__content text-center">
                                {" "}
                                <img
                                  className="img-fluid"
                                  style={{ width: "70px", height: "70px" }}
                                  src={item.path ? item.path : ""}
                                />
                              </p>
                            </td>
                            <td>
                              <p className="table__content">
                                {item.productDesc ? item.productDesc : ""}
                              </p>
                            </td>
                            {loginDetials.loginDetials.responseData
                              .dealerMemberTypeName == "Retailer" ||
                            vendorType == "" ? (
                              <td>
                                <p className="table__content">
                                  {item.retailerStockNumber
                                    ? item.retailerStockNumber
                                    : ""}
                                </p>
                              </td>
                            ) : null}
                            <td>
                              <p className="table__content">
                                {" "}
                                {item.retailerStyle ? item.retailerStyle : ""}
                              </p>
                            </td>
                            <td>
                              <p className="table__content">
                                {item.strPurchasePrice
                                  ? item.strPurchasePrice
                                  : ""}
                              </p>
                            </td>
                            <td>
                              <p className="table__content">
                                {item.quantity ? item.quantity : ""}
                              </p>
                            </td>
                            <td>
                              <p className="table__content">
                                {" "}
                                {item.strEXTWholeSalePrice
                                  ? item.strEXTWholeSalePrice
                                  : ""}
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                className="divForm col-md-12"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <div className="col-md-5">
                  <p>{t("Vendor to provide shipping/insurance info.")}</p>
                </div>
                <div className="col-md-3">
                  <div className="d-flex justify-content-end">
                    <div className="total__section p-3">
                      <p>
                        <span className="font__bold">{t("Total Item")}:</span>
                        {purchaseOrdersProductPrice.totalQuantity
                          ? purchaseOrdersProductPrice.totalQuantity
                          : 0}
                      </p>
                      <p>
                        <span className="font__bold">{t("SubTotal")}:</span> $
                        {purchaseOrdersProductPrice.poTotal
                          ? parseFloat(
                              purchaseOrdersProductPrice.poTotal
                            ).toFixed(2)
                          : "0.00"}
                      </p>
                      <p>
                        <span className="font__bold">
                          {t("Estimated Total")}:
                        </span>{" "}
                        $
                        {purchaseOrdersProductPrice.poTotal
                          ? parseFloat(
                              purchaseOrdersProductPrice.poTotal
                            ).toFixed(2)
                          : "0.00"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-12">
                <div className="bottom__footer">
                  <img src={footerlogoImg} />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchaseOrderDetails);
