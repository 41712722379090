import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, Button, Table, Modal, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import blackDiamond from "../../assets/images/black-diamond.png";
import PurchaseOrderServices from "../../services/purchaseOrder.service";
import { NotificationManager } from "react-notifications";
import AdminManageLanguageService from "../../services/admin-manageLanguage.service";
import {
  setOpenKeyHeaderMenuData,
  setSelectSideMenu,
} from "../../actions/headerMenu/headerMenu";
const { Option } = Select;

export const PODashboard = (props) => {
  const { t } = useTranslation();
  const [workSheetData, setWorkSheetData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [workSheetLoading, setWorkSheetLoading] = useState(false);
  const [purchaseOrdersProductPrice, SetPurchaseOrderProductPrice] = useState(
    {}
  );
  const [purchaseOrdersProductDetails, SetPurchaseOrderProductDetails] =
    useState([]);
  const [purchaseOrdersDtVendor, SetPurchaseOrderDtVendor] = useState([]);
  const [purchaseOrdersDtRetailer, SetPurchaseOrderDtRetailer] = useState([]);
  const [purchaseOrderLoading, setPurchaseOrderLoading] = useState(false);
  const [generateOrderModalVisible, setgenerateOrderModalVisible] =
    useState(false);
  const [purchaseOrderData, setPurchaseOrderData] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [dealerId, setDealerId] = useState(0);
  const [visible, setVisible] = useState(false);
  const [filteredVendor, setFilteredVendor] = useState({
    vendorname: "",
  });
  const [validation, setValidations] = useState({
    PONameVal: "",
    notesVal: "",
  });
  const [reorderData, setReorderData] = useState({
    PoName: "",
    notes: "",
    refrenceNumber: "",
  });
  const [vendorType, setVendorType] = useState("");

  const [worksheetDeleteDetails, setWorksheetDeleteDetails] = useState({
    workSheetId: 0,
    workSheetName: "",
  });
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [insertLoading, setInsertLoading] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [cancelOrederData, setCancelOrederData] = useState({
    orderID: "",
    poName: "",
  });
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [aftervendorselected, setAfterVendorSelected] = useState(false);
  const [worksheetDetails, setWorkSheetDetails] = useState({
    workSheetName: "",
  });
  const [scaList, setScaList] = useState([]);
  const [selectedSCA, setSelectedSCA] = useState("");
  const [accNo, setAccNo] = useState("");
  const [error, setError] = useState(false);
  const [listerr, setListerr] = useState(false);

  const [worksheetDetailsVal, setWorkSheetDetailsVal] = useState({
    workSheetNameVal: "",
  });
  const filteredData = scaList.filter((item) => item.isVendorapprove);
  const dataToRender = filteredData.length > 0 ? filteredData : scaList;

  const dispatch = useDispatch();

  const loginDetials = useSelector((state) => state.loginReducer);
  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          if (
            loginDetials.loginDetials.responseData.dealerMemberTypeName ==
            "Retailer"
          ) {
            setTimeout(() => {
              handleGetDashBoardWorksheetDetails(
                loginDetials.loginDetials.responseData.dealerId
              );
            }, 300);
          }

          if (
            loginDetials.loginDetials.responseData.dealerMemberTypeName ==
            "Retailer"
          ) {
            handleGetDashBoardMyPurchaseOrder(
              loginDetials.loginDetials.responseData.dealerId,
              ""
            );
          } else {
            handleGetDashBoardMyPurchaseOrder(
              loginDetials.loginDetials.responseData.dealerId,
              loginDetials.loginDetials.responseData.dealerMemberTypeName
            );
            setVendorType(
              loginDetials.loginDetials.responseData.dealerMemberTypeName
            );
          }
          if (
            loginDetials.loginDetials.responseData.dealerMemberTypeName ==
            "Retailer"
          ) {
            setTimeout(() => {
              handleGetDashBoardVendorDetails(
                loginDetials.loginDetials.responseData.dealerId
              );
            }, 500);
          }
        }
      }
    }
  }, []);

  const handleRedirectionColumn = (
    id,
    name,
    type,
    vendorDynamicId,
    vendorDynamicName
  ) => {
    if (type == "workSheet") {
      navigate("/workSheetDetails", {
        state: {
          id: id ? id : 0,
          name: name ? name : "",
          vendorDynamicId: vendorDynamicId ? vendorDynamicId : "",
          vendorDynamicName: vendorDynamicName ? vendorDynamicName : "",
        },
      });
    }
    if (type == "Po") {
      navigate("/purchaseOrderDetails", {
        state: {
          POOrderID: id ? id : 0,
          POOrderName: name ? name : "",
        },
      });
    }
  };

  const columns = [
    {
      title: t("Worksheet Name"),
      dataIndex: "workSheetName",
      width: 180,
      render: (item, row) => {
        return (
          <a
            onClick={() =>
              handleRedirectionColumn(
                row.poWorkSheetID,
                row.workSheetName,
                "workSheet",
                row.vendorID,
                row.vendorName
              )
            }
            className="linkText"
          >
            {row.workSheetName}
          </a>
        );
      },
    },
    {
      title: t("Item Count"),
      dataIndex: "itemCount",
    },
    {
      title: t("Total Base Price"),
      dataIndex: "totalBasePrice",
      render: (item, row) => {
        return (
          <span>
            {row.totalBasePrice == "" ? "$" + "0.00" : "$" + row.totalBasePrice}
          </span>
        );
      },
    },
    {
      title: t("Vendor Count"),
      dataIndex: "vendorCount",
    },
    {
      title: t("Actions"),
      render: (item, row) => {
        return (
          <div className="action__btns">
            <div className="">
              <span
                className="linkText"
                onClick={() =>
                  handleRedirectionColumn(
                    row.poWorkSheetID,
                    row.workSheetName,
                    "workSheet",
                    row.vendorID,
                    row.vendorName
                  )
                }
              >
                {t("View")}
              </span>
            </div>
            <div className="ml-2">
              <span
                onClick={() =>
                  handleDeleteModal(row.poWorkSheetID, row.workSheetName)
                }
                className="linkText"
              >
                {t("Delete")}
              </span>
            </div>
          </div>
        );
      },
    },
  ];
  const handleGetSCAList = async (id) => {
    try {
      await AdminManageLanguageService.getSCADrpdown(id)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData) {
              setScaList(responseData || []);
            }
          } else {
            setScaList([]);
          }
        })
        .catch((error) => {
          setScaList([]);
        });
    } catch (error) {
      setScaList([]);
    }
  };
  const handleSelectChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setSelectedSCA(e);
    }
  };

  const handleCancel1 = () => {
    setgenerateOrderModalVisible(false);
    setListerr(false);
  };
  // to create Unique Po Number
  const handleCreatePoNo = () => {
    let inputData = {
      dealerID: dealerId ? dealerId.toString() : "",
    };
    try {
      PurchaseOrderServices.CreatePONumber(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setReorderData({
              PoName: responseData,
            });
          } else {
            setReorderData({
              PoName: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setReorderData({
            PoName: "",
          });
        });
    } catch (error) {
      console.log(error);
      setReorderData({
        PoName: "",
      });
    }
  };
  const Retailercolumns1 = [
    {
      title: t("Purchase Order"),
      dataIndex: "poName",
      width: 150,
      render: (item, row) => {
        return (
          <a
            onClick={() =>
              handleRedirectionColumn(row.poid, row.poName, "Po", "", "")
            }
            className="linkText"
          >
            {row.poName}
          </a>
        );
      },
    },
    {
      title: t("Order#"),
      dataIndex: "poid",
    },
    {
      title: t("Reference Number"),
      dataIndex: "referenceNumber",
    },
    {
      title: t("Vendor Name"),
      dataIndex: "vendorName",
    },
    {
      title: t("Total Cost"),
      dataIndex: "poTotal",
      render: (item, row) => {
        return (
          <span>{row.poTotal == "" ? "$" + "0.00" : "$" + row.poTotal}</span>
        );
      },
    },
    {
      title: t("Date"),
      dataIndex: "createdDate",
    },
    {
      title: t("Status"),
      dataIndex: "statusname",
    },
    {
      title: t("Actions"),
      render: (item, row) => {
        return (
          <div className="action__btns">
            <div onClick={() => handlePurchaseOrdeDetails(row)}>
              <a className="linkText mr-2">{t("View")}</a>
            </div>
            <div>
              <a
                className="linkText"
                onClick={() =>
                  handleCancelOrder(
                    row.poid,
                    row.poName,
                    row.statusname == "Cancelled"
                      ? "Reorder"
                      : row.statusname == "Submitted"
                      ? "Cancel"
                      : ""
                  )
                }
              >
                {row.statusname == "Cancelled" ? (
                  <span
                    onClick={() => {
                      setVisible(true);
                      handleCreatePoNo();
                    }}
                  >
                    {t("Reorder")}
                  </span>
                ) : row.statusname == "Submitted" ? (
                  t("Cancel")
                ) : (
                  ""
                )}
              </a>
            </div>
          </div>
        );
      },
    },
  ];

  const Vendorcolumns1 = [
    {
      title: t("Retailer"),
      dataIndex: "dealerCompany",
      width: 150,
      render: (item, row) => {
        return <a className="linkText">{row.dealerCompany}</a>;
      },
    },
    {
      title: t("Purchase Order"),
      dataIndex: "poName",
      width: 150,
      render: (item, row) => {
        return (
          <a
            onClick={() =>
              handleRedirectionColumn(row.poid, row.poName, "Po", "", "")
            }
            className="linkText"
          >
            {row.poName}
          </a>
        );
      },
    },
    {
      title: t("Order#"),
      dataIndex: "poid",
    },
    {
      title: t("Reference Number"),
      dataIndex: "referenceNumber",
    },
    {
      title: t("Vendor Name"),
      dataIndex: "vendorName",
    },
    {
      title: t("Total Cost"),
      dataIndex: "poTotal",
      render: (item, row) => {
        return (
          <span>{row.poTotal == "" ? "$" + "0.00" : "$" + row.poTotal}</span>
        );
      },
    },
    {
      title: t("Date"),
      dataIndex: "createdDate",
    },
    {
      title: t("Status"),
      dataIndex: "statusname",
    },
    {
      title: t("Actions"),
      render: (item, row) => {
        return (
          <div className="action__btns">
            <div>
              <Link
                to={"/purchaseOrderDetails"}
                state={{ POOrderID: row.poid, POOrderName: row.poName }}
              >
                <a className="linkText mr-2">{t("View")}</a>
              </Link>
            </div>
          </div>
        );
      },
    },
  ];

  const GetMyPurchaseOrderHistoryDetails = (id) => {
    let inputData = {
      dealerID: id,
      orderID: cancelOrederData.orderID,
      pOname: reorderData.PoName,
    };
    setHistoryLoading(true);
    try {
      PurchaseOrderServices.purchaseorderhistotry(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            SetPurchaseOrderProductDetails(responseData.productDetail);
            SetPurchaseOrderDtRetailer(responseData.dtRetailer[0]);
            SetPurchaseOrderDtVendor(responseData.dtVendor[0]);
            SetPurchaseOrderProductPrice(responseData.productprice);
            setHistoryLoading(false);
            handleGetSCAList(responseData.purchaseOrder[0].vendorID);
            setAccNo(responseData.purchaseOrder[0].ediAccNumber);
            console.log(
              "EDI Account Number: ",
              responseData.purchaseOrder[0].ediAccNumber
            );
            if (
              responseData.purchaseOrder[0].ediAccNumber == "" ||
              responseData.purchaseOrder[0].ediAccNumber === null
            ) {
              setError(true);
            } else {
              setError(false);
            }
          } else {
            SetPurchaseOrderProductDetails([]);
            SetPurchaseOrderDtRetailer([]);
            SetPurchaseOrderDtVendor([]);
            SetPurchaseOrderProductPrice({});
            setHistoryLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setHistoryLoading(false);
        });
    } catch (error) {
      console.log(error);
      setHistoryLoading(false);
    }
  };

  const handleCreateReOrder = () => {
    var isValid = handleValidation();

    if (!isValid) {
      handleCancel1();
      return false;
    } else if (selectedSCA <= 0) {
      setListerr(true);
      return;
    }

    let inputData = {
      dealerID: dealerId.toString(),
      poid: cancelOrederData.orderID.toString(),
      poName: reorderData.PoName ? reorderData.PoName.toString() : "",
      poNote: reorderData.notes ? reorderData.notes.toString() : "",
      referenceNumber: reorderData.referenceNumber
        ? reorderData.referenceNumber.toString()
        : "",
      scaccode: selectedSCA ? selectedSCA.toString() : "",
    };
    setSubmitLoading(true);
    try {
      PurchaseOrderServices.CreateReOrder(inputData)
        .then((response) => {
          setSubmitLoading(false);
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData == "Please Enter Acc No for this Vendor") {
              NotificationManager.error(
                t("Kindly Add Account Number for this Vendor.")
              );
              setgenerateOrderModalVisible(false);
            } else if (responseData == "Vendor Retailer Not connected") {
              NotificationManager.error(
                t(
                  "Your connection to this vendor is no longer active. Please request access to this vendor again before placing orders. If you believe this is a mistake please contact GemFind support."
                )
              );
              setgenerateOrderModalVisible(false);
            } else {
              NotificationManager.success(
                t("Order Has Been Successfully ReOrder.")
              );
              setgenerateOrderModalVisible(false);
              handleGetDashBoardMyPurchaseOrder(dealerId, vendorType);
              setVisible(false);
              setSubmitLoading(true);
            }
          } else {
            NotificationManager.error(t("Order Has Not Been Cancelled."));
            setSubmitLoading(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setSubmitLoading(true);
        });
    } catch (error) {
      console.log(error);
      setSubmitLoading(true);
    }
  };

  const handleGetDashBoardWorksheetDetails = async (id) => {
    let inputData = {
      delarId: id ? id : 0,
    };
    setWorkSheetLoading(true);
    try {
      PurchaseOrderServices.GetDashBoardWorksheetDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData.length > 0) {
              setWorkSheetData(responseData);
              setWorkSheetLoading(false);
            } else {
              setWorkSheetData([]);
              setWorkSheetLoading(false);
            }
          } else {
            setWorkSheetData([]);
            setWorkSheetLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setWorkSheetLoading(false);
        });
    } catch (error) {
      console.log(error);
      setWorkSheetLoading(false);
    }
  };
  const [loading, setLoading] = useState(false);

  const handleGetDashBoardVendorDetails = async (id) => {
    let inputData = {
      dealerID: id.toString(),
      orderby: "GFInventoryID",
      order: "ASC",
      category: "",
      vendor: "",
      materialType: "",
      gender: "",
      collection: "",
      collectionID: "",
      materialColor: "",
      minRange: "",
      maxRange: "9999999",
      styleNumber: "",
      dealerType: "MergeProduct",
      noOfRows: "50",
      offset: "0",
      flgComm_VR: "",
      gemstoneType: "",
      strSelectedInvID: "",
      image: "",
    };
    setLoading(true);
    try {
      PurchaseOrderServices.GetPOConnectedVendorDropdown(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData.result.vendorBrand;
          if (message === "Success") {
            if (responseData.length > 0) {
              setVendorData(responseData);
              setLoading(false);
            } else {
              setVendorData([]);
              setLoading(false);
            }
          } else {
            setVendorData([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleGetDashBoardMyPurchaseOrder = async (id, vendorType) => {
    let inputData = {
      delarId: id ? id : 0,
      type: vendorType ? vendorType : "",
    };
    setPurchaseOrderLoading(true);
    try {
      PurchaseOrderServices.GetDashBoardMyPurchaseOrder(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData.length > 0) {
              setPurchaseOrderData(responseData);
              setPurchaseOrderLoading(false);
            } else {
              setPurchaseOrderData([]);
              setPurchaseOrderLoading(false);
            }
          } else {
            setPurchaseOrderData([]);
            setPurchaseOrderLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setPurchaseOrderLoading(false);
        });
    } catch (error) {
      console.log(error);
      setPurchaseOrderLoading(false);
    }
  };

  // Delete WorkSheet

  const handleRemoveWorkSheetName = () => {
    let inputData = {
      dealerID: dealerId ? dealerId : 0,
      workSheetID: worksheetDeleteDetails.workSheetId
        ? worksheetDeleteDetails.workSheetId
        : 0,
    };
    setDeleteLoading(true);
    try {
      PurchaseOrderServices.RemoveWorkSheetName(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message === "Success") {
            setDeleteLoading(false);
            setDeleteModalVisible(false);
            NotificationManager.success(
              t("WorkSheet Has Been Deleted Successfully.")
            );
            handleGetDashBoardWorksheetDetails(dealerId);
          } else {
            setDeleteLoading(false);
            setDeleteModalVisible(false);
            NotificationManager.error(t("WorkSheet Has Not Been Deleted."));
          }
        })
        .catch((error) => {
          console.log(error);
          setInsertLoading(false);
          setDeleteModalVisible(false);
        });
    } catch (error) {
      console.log(error);
      setDeleteLoading(false);
      setDeleteModalVisible(false);
    }
  };

  const handleDeleteModal = (id, name) => {
    setWorksheetDeleteDetails({
      workSheetId: id,
      workSheetName: name,
    });
    setDeleteModalVisible(true);
  };

  const handleCancelDeleteModal = () => {
    setDeleteModalVisible(false);
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setReorderData({
        ...reorderData,
        [name]: e,
      });
    } else {
      setReorderData({
        ...reorderData,
        [e.target.name]: e.target.value,
      });

      if (e.target.name == "PoName") {
        setValidations((prevdata) => ({
          ...prevdata,
          PONameVal: "",
        }));
      }
      if (e.target.name == "notes") {
        setValidations((prevdata) => ({
          ...prevdata,
          notesVal: "",
        }));
      }
    }
  };

  const handleValidation = () => {
    const { PoName, notes } = reorderData;

    const validation = {
      PONameVal: "",
      notesVal: "",
    };
    let isValid = true;

    if (!PoName.trim()) {
      isValid = false;
      validation.PONameVal = t("PO Name is Compulsory");
    } else {
      validation.PONameVal = "";
    }

    setValidations(validation);
    return isValid;
  };

  const handleCancel = () => {
    setVisible(false);
    setReorderData({
      PoName: "",
      notes: "",
    });
    setValidations({
      PONameVal: "",
      notesVal: "",
    });
  };

  const handleOk = () => {
    setgenerateOrderModalVisible(true);
    GetMyPurchaseOrderHistoryDetails(dealerId);
  };

  const handleOnChangeVendor = (e, isSelect, name) => {
    if (isSelect === "select") {
      setFilteredVendor({
        ...filteredVendor,
        [name]: e,
      });
    } else {
      setFilteredVendor({
        ...filteredVendor,
        [e.target.name]: e.target.value,
      });
    }
  };

  // Cancel Order

  const handleUpdateOrderStatus = () => {
    let inputData = {
      dealerID: dealerId.toString(),
      orderID: cancelOrederData.orderID.toString(),
      status: "2",
    };

    try {
      PurchaseOrderServices.UpdateOrderStatus(inputData)
        .then((response) => {
          var message = response.data.message;
          if (message === "Success") {
            setCancelModalVisible(false);
            NotificationManager.success(t("PO Cancelled Successfully."));
            handleGetDashBoardMyPurchaseOrder(dealerId, vendorType);
          } else {
            setCancelModalVisible(false);
            NotificationManager.error(t("PO Has Not Been Cancelled."));
          }
        })
        .catch((error) => {
          console.log(error);
          setCancelModalVisible(false);
        });
    } catch (error) {
      console.log(error);
      setCancelModalVisible(false);
    }
  };

  const handleCancelOrder = (poid, poName, type) => {
    if (type === "Cancel" || type === "Reorder") {
      setCancelOrederData({
        orderID: poid,
        poName: poName,
      });
      if (type === "Cancel") {
        setCancelModalVisible(true);
      }
    }
  };

  const handleCancelModal = () => {
    setCancelModalVisible(false);
  };

  let navigate = useNavigate();
  const handleRedirection = (type) => {
    if (type == "WorkSheet") {
      var MenuData = [`${"Worksheets"}`];
      dispatch(setOpenKeyHeaderMenuData(MenuData));
      dispatch(setSelectSideMenu(["myWorksheet"]));
      navigate("/myWorksheet");
    }
    if (type == "PurchaseOrder") {
      dispatch(setSelectSideMenu(["myPurchaseOrder"]));
      navigate("/myPurchaseOrder");
    }
  };
  const handlePurchaseOrdeDetails = (row) => {
    var MenuData = [`${"Purchase Orders"}`];
    dispatch(setOpenKeyHeaderMenuData(MenuData));
    dispatch(setSelectSideMenu(["myPurchaseOrder"]));
    navigate("/purchaseOrderDetails", {
      state: {
        POOrderID: row.poid,
        POOrderName: row.poName,
      },
    });
  };
  // create WorkSheet

  const handleGetCreateModelOpen = () => {
    if (filteredVendor.vendorname != "") {
      setCreateModalVisible(false);
      setAfterVendorSelected(true);
    }
  };

  const handleValidationWorksheet = () => {
    const { workSheetName } = worksheetDetails;

    const worksheetDetailsVal = {
      workSheetNameVal: "",
    };
    let isValid = true;

    if (!workSheetName.trim()) {
      isValid = false;
      worksheetDetailsVal.workSheetNameVal = t("Enter WorkSheet Name");
    } else {
      worksheetDetailsVal.workSheetNameVal = "";
    }
    setWorkSheetDetailsVal(worksheetDetailsVal);
    return isValid;
  };

  const handleInsertWorksheetDetails = () => {
    var isValid = handleValidationWorksheet();

    if (!isValid) {
      return false;
    }
    let inputData = {
      dealerId: dealerId ? dealerId : 0,
      workSheetName: worksheetDetails.workSheetName
        ? worksheetDetails.workSheetName.toString()
        : "",
      vendorName: filteredVendor.vendorname ? filteredVendor.vendorname : "",
    };
    setInsertLoading(true);
    try {
      PurchaseOrderServices.InsertWorksheetDetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            if (responseData.returnMessage == "Worksheet Already Exist") {
              NotificationManager.error(t("Worksheet Name Already Exists."));
              setInsertLoading(false);
              handleCancelCreateModal();
            } else {
              setInsertLoading(false);
              handleCancelCreateModal();
              NotificationManager.success(
                t("WorkSheet Has Been Created Successfully.")
              );
              handleGetDashBoardWorksheetDetails(dealerId);
            }
          } else {
            setInsertLoading(false);
            handleCancelCreateModal();
            NotificationManager.error(t("WorkSheet Has Not Been Created."));
          }
        })
        .catch((error) => {
          console.log(error);
          setInsertLoading(false);
          handleCancelCreateModal();
        });
    } catch (error) {
      console.log(error);
      setInsertLoading(false);
      handleCancelCreateModal();
    }
  };

  const handleCancelCreateModal = () => {
    setWorkSheetDetails({
      workSheetName: "",
    });
    setFilteredVendor({
      vendorname: "",
    });
    setWorkSheetDetailsVal({
      workSheetNameVal: "",
    });
    setCreateModalVisible(false);
    setAfterVendorSelected(false);
  };

  const handleBackOpenVendorModelCreateModal = () => {
    setCreateModalVisible(true);
    setAfterVendorSelected(false);
    setWorkSheetDetailsVal({
      workSheetNameVal: "",
    });
  };

  const handleWorksheetOnChange = (e, isSelect, name) => {
    if (isSelect === "select") {
      setWorkSheetDetails({
        ...worksheetDetails,
        [name]: e,
      });
    } else {
      setWorkSheetDetails({
        ...worksheetDetails,
        [e.target.name]: e.target.value,
      });
    }
    if (e.target.name == "workSheetName") {
      setWorkSheetDetailsVal((prevdata) => ({
        ...prevdata,
        workSheetNameVal: "",
      }));
    }
  };
  return (
    <div className="form__fields">
      {loginDetials.loginDetials.responseData.dealerMemberTypeName ==
        "Retailer" || vendorType == "" ? (
        <div className="row">
          <div className="col-md-2">
            <img src={blackDiamond} className="mt-5 mobile-m-0" />
          </div>
          <div className="col-md-10">
            <div className="row">
              <div className="mb-3 col-md-9">
                <h4 class="workarea__heading mt-0 mb-0">{t("Worksheet")}</h4>
                <p className="mb-0">
                  {t(
                    "Use this to build and manage your products before sending a purchase order."
                  )}
                </p>
              </div>
              <div className="col-md-3 text-right mobile__bottomspace text-left-mobile">
                <Button
                  onClick={() => {
                    setCreateModalVisible(true);
                  }}
                  className="primary-btn"
                >
                  {t("Create WorkSheet")}
                </Button>
              </div>
            </div>
            <div className="divForm">
              <Spin spinning={workSheetLoading}>
                <Table
                  columns={columns}
                  dataSource={workSheetData}
                  scroll={{ x: 600, y: 800 }}
                  pagination={false}
                />
              </Spin>
            </div>
          </div>
          <div className="col-md-12 text-right mt-3">
            <span
              onClick={() => handleRedirection("WorkSheet")}
              className="linkText"
            >
              {t("View All")}
            </span>
          </div>
          <div className="col-md-12 mt-3">
            <hr></hr>
          </div>
        </div>
      ) : null}

      <div className="row">
        <div className="col-md-2">
          <img src={blackDiamond} className="mt-5 mobile-m-0" />
        </div>

        <div className="col-md-10">
          <div className="mb-3">
            <h4 class="workarea__heading mt-0 mb-0"> {t("Purchase Order")}</h4>
          </div>

          <div className="divForm">
            <Spin spinning={purchaseOrderLoading}>
              <Table
                columns={
                  loginDetials.loginDetials.responseData.dealerMemberTypeName ==
                    "Retailer" || vendorType == ""
                    ? Retailercolumns1
                    : Vendorcolumns1
                }
                dataSource={purchaseOrderData}
                scroll={{ x: 600, y: 800 }}
                pagination={false}
              />
            </Spin>
          </div>
        </div>
        <div className="col-md-12 text-right mt-5">
          <span
            onClick={() => handleRedirection("PurchaseOrder")}
            className="linkText"
          >
            {t("View All")}
          </span>
        </div>
      </div>

      <Modal
        title={t("Delete")}
        className="modalconsupld__section"
        centered
        visible={deleteModalVisible}
        onOk={handleRemoveWorkSheetName}
        onCancel={handleCancelDeleteModal}
        maskClosable={false}
        width={400}
        footer={[
          <Button key="back" onClick={handleCancelDeleteModal}>
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={deleteLoading}
            onClick={handleRemoveWorkSheetName}
          >
            {t("Delete Worksheet")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <p>
                      {t("Delete the")} "{worksheetDeleteDetails.workSheetName}"
                      {t("Worksheet")}{" "}
                    </p>
                    <h5>{t("THIS CAN NOT BE UNDONE")}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Cancel Order Modal */}
      <Modal
        className="delete_conv_modal"
        centered
        visible={cancelModalVisible}
        onCancel={handleCancelModal}
        width={400}
        footer={[
          <Button type="primary" onClick={handleUpdateOrderStatus}>
            {t("Cancel Order")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <h5 className="reset-pass-conf-heading">
                      {t("Cancel The")} "{cancelOrederData.poName}" {t("Order")}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* End Cancel Order Modal */}

      <Modal
        title={t("Reorder")}
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {t("Cancel")}
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            {t("Generate PO")}
          </Button>,
        ]}
        width={400}
      >
        <div className="form__fields p-0 border-0">
          <div className="input__block">
            <label>{t("PO Name")}</label>
            <input
              type="text"
              name="PoName"
              value={reorderData.PoName}
              onChange={handleOnChange}
              className={validation.PONameVal && "border__red"}
              disabled
            />
            {validation.PONameVal && (
              <p className="error-color-red">{validation.PONameVal}</p>
            )}
          </div>
          <div className="input__block">
            <label>{t("Reference Number")}</label>
            <input
              type="text"
              name="referenceNumber"
              value={reorderData.referenceNumber}
              onChange={handleOnChange}
              className={validation.PONameVal && "border__red"}
            />
          </div>

          <div className="input__block">
            <label>{t("Notes")}</label>
            <textarea
              name="notes"
              value={reorderData.notes}
              onChange={handleOnChange}
              className={validation.notesVal && "border__red"}
            ></textarea>
            {validation.notesVal && (
              <p className="error-color-red">{validation.notesVal}</p>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        title={t("Create Worksheet")}
        className="modalconsupld__section"
        centered
        visible={aftervendorselected}
        onOk={handleInsertWorksheetDetails}
        onCancel={handleCancelCreateModal}
        maskClosable={false}
        width={400}
        footer={[
          <Button key="back" onClick={handleCancelCreateModal}>
            {t("Cancel")}
          </Button>,
          <Button key="back" onClick={handleBackOpenVendorModelCreateModal}>
            {t("Back")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={insertLoading}
            onClick={handleInsertWorksheetDetails}
          >
            {t("Create")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="input__block">
                  <label>
                    {t("Worksheet Name")} <span className="mandatory">*</span>
                  </label>
                  <input
                    type="text"
                    name="workSheetName"
                    className={
                      worksheetDetailsVal.workSheetNameVal && "border__red"
                    }
                    value={worksheetDetails.workSheetName}
                    onChange={handleWorksheetOnChange}
                  />
                  {worksheetDetailsVal.workSheetNameVal && (
                    <p className="error-color-red">
                      {worksheetDetailsVal.workSheetNameVal}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={t("Vendor")}
        className="modalconsupld__section"
        centered
        visible={createModalVisible}
        onCancel={handleCancelCreateModal}
        maskClosable={false}
        width={400}
        footer={[
          <Button key="back" onClick={handleCancelCreateModal}>
            {t("Cancel")}
          </Button>,
          <Button
            className={
              filteredVendor.vendorname ? "primary-btn" : "disabled-btn"
            }
            disabled={!filteredVendor.vendorname}
            onClick={handleGetCreateModelOpen}
          >
            {t("Next")}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <Spin spinning={loading}>
                <div className="form__fields border-0 p-0">
                  {vendorData.length > 0 ? (
                    <div class="col-lg-12 col-md-12">
                      <div className="input__block">
                        <label> {t("Vendor")} </label>
                        <Select
                          value={filteredVendor.vendorname}
                          name="vendorname"
                          placeholder={t("Select Vendor")}
                          onChange={(e) =>
                            handleOnChangeVendor(e, "select", "vendorname")
                          }
                          showSearch
                          optionFilterProp="children"
                          className="border__grey"
                        >
                          <Option value=""> {t("Select Vendor")} </Option>
                          {vendorData &&
                            vendorData.map((item, i) => {
                              return (
                                <Option value={item.vendorNameID}>
                                  {item.vendorNameValue}
                                </Option>
                              );
                            })}
                        </Select>
                      </div>
                    </div>
                  ) : (
                    <span>
                      {t("Please Connect the Vendor for more information.")}
                    </span>
                  )}
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </Modal>

      {/* Generate PO Modal */}

      <Modal
        title={t("Purchase Order Summary")}
        centered
        visible={generateOrderModalVisible}
        onOk={handleCreateReOrder}
        onCancel={handleCancel1}
        footer={[
          <Button key="back" onClick={handleCancel1}>
            {t("Cancel")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleCreateReOrder}
            disabled={dataToRender.length === 0}
            loading={submitLoading}
          >
            {t("Create PO")}
          </Button>,
        ]}
        width={1000}
      >
        <div className="form__fields">
          <Spin spinning={historyLoading}>
            <div className="row magento__mapping">
              <div className="col-md-4 mb-4">
                <h5 className="subheading">{t("Shipping Address")} :</h5>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealercompany
                    ? purchaseOrdersDtRetailer.dealercompany
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealername
                    ? purchaseOrdersDtRetailer.dealername
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerAddress
                    ? purchaseOrdersDtRetailer.dealerAddress
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerCity
                    ? purchaseOrdersDtRetailer.dealerCity
                    : "null"}
                  ,{" "}
                  {purchaseOrdersDtRetailer.dealerState
                    ? purchaseOrdersDtRetailer.dealerState
                    : "null"}
                  ,
                  {purchaseOrdersDtRetailer.dealerZIP
                    ? purchaseOrdersDtRetailer.dealerZIP
                    : "null"}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtRetailer.dealerCountry
                    ? purchaseOrdersDtRetailer.dealerCountry
                    : ""}
                </p>
                <p className="mb-0">
                  T:
                  {purchaseOrdersDtRetailer.dealerphone
                    ? purchaseOrdersDtRetailer.dealerphone
                    : ""}
                </p>
                <p className="mb-0">
                  {t("Email")}:{" "}
                  {purchaseOrdersDtRetailer.dealeremail
                    ? purchaseOrdersDtRetailer.dealeremail
                    : ""}
                </p>
              </div>

              <div className="col-md-4 mb-4">
                <h5 className="subheading">{t("Manufacturer Address")} :</h5>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealercompany
                    ? purchaseOrdersDtVendor.dealercompany
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealername
                    ? purchaseOrdersDtVendor.dealername
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerAddress
                    ? purchaseOrdersDtVendor.dealerAddress
                    : ""}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerCity
                    ? purchaseOrdersDtVendor.dealerCity
                    : "null"}
                  ,{" "}
                  {purchaseOrdersDtVendor.dealerState
                    ? purchaseOrdersDtVendor.dealerState
                    : "null"}
                  ,
                  {purchaseOrdersDtVendor.dealerZIP
                    ? purchaseOrdersDtVendor.dealerZIP
                    : "null"}
                </p>
                <p className="mb-0">
                  {purchaseOrdersDtVendor.dealerCountry
                    ? purchaseOrdersDtVendor.dealerCountry
                    : ""}
                </p>
                <p className="mb-0">
                  T:
                  {purchaseOrdersDtVendor.dealerphone
                    ? purchaseOrdersDtVendor.dealerphone
                    : ""}
                </p>
                <p className="mb-0">
                  {t("Email")}:{" "}
                  {purchaseOrdersDtVendor.dealeremail
                    ? purchaseOrdersDtVendor.dealeremail
                    : ""}
                </p>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="input__block">
                    <label> {t("Account Number")}: </label>
                    <input
                      type="text"
                      name="AccNo"
                      value={accNo}
                      className={error && "border__red"}
                      disabled
                    />
                    {error && (
                      <p className="error-color-red">
                        {t("Kindly Add Account Number First")}
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="input__block mb-3">
                    <label>
                      {t("Standard Carrier Alpha")} :{" "}
                      <span className="mandatory">*</span>
                    </label>{" "}
                    <Select
                      value={selectedSCA}
                      autocomplete="off"
                      name="SCAvalue"
                      showSearch
                      placeholder={t("Select SCA")}
                      onChange={(e) =>
                        handleSelectChange(e, "select", "SCAvalue")
                      }
                      className={
                        dataToRender.length === 0 || listerr
                          ? "border__red"
                          : "border__grey"
                      }
                    >
                      {dataToRender.map((item) => (
                        <Option key={item.scaid} value={item.scaid}>
                          {item.scacname}
                        </Option>
                      ))}
                    </Select>
                    {dataToRender.length === 0 && (
                      <p className="error-color-red">
                        {t(
                          "This vendor has not configured any SCA Codes. Please reach out to GemFind for assistance."
                        )}
                      </p>
                    )}
                    {listerr && (
                      <p className="error-color-red">
                        {t("Please Select Standard Carrier Alpha")}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <p>
                  {t("Order Notes")}:{" "}
                  {purchaseOrdersProductPrice.poName
                    ? purchaseOrdersProductPrice.poName
                    : ""}
                </p>
              </div>

              <div className="divForm">
                <Table
                  columns={[
                    {
                      title: t("Item"),
                      dataIndex: "id",
                      key: "id",
                      width: 100,
                    },
                    {
                      title: t("Product Description"),
                      dataIndex: "productDesc",
                      key: "productDesc",
                    },
                    {
                      title: t("SKU"),
                      dataIndex: "retailerSku",
                      key: "retailerSku",
                    },
                    {
                      title: t("Vendor SKU"),
                      dataIndex: "retailerStockNumber",
                      key: "retailerStockNumber",
                    },
                    {
                      title: t("Price"),
                      dataIndex: "strPurchasePrice",
                      key: "strPurchasePrice",
                    },
                    {
                      title: t("Qty"),
                      dataIndex: "quantity",
                      key: "quantity",
                    },
                    {
                      title: t("Subtotal"),
                      dataIndex: "strEXTWholeSalePrice",
                      key: "strEXTWholeSalePrice",
                    },
                  ]}
                  dataSource={purchaseOrdersProductDetails}
                  scroll={{ x: 600, y: 800 }}
                  pagination={false}
                />
                <div className="d-flex justify-content-end">
                  <div className="total__section p-3">
                    <p>
                      <span className="font__bold">
                        {t("Total Item")}:{" "}
                        {purchaseOrdersProductDetails.reduce(
                          (accumulator, currentValue) => {
                            return (
                              accumulator + parseInt(currentValue.quantity)
                            );
                          },
                          0
                        )}
                      </span>
                    </p>
                    <p>
                      <span className="font__bold">{t("SubTotal")}:</span> $
                      {parseFloat(
                        purchaseOrdersProductDetails.reduce(
                          (accumulator, currentValue) => {
                            return (
                              accumulator +
                              parseInt(
                                currentValue.strEXTWholeSalePrice.replace(
                                  /[$,]/g,
                                  ""
                                ),
                                10
                              )
                            );
                          },
                          0
                        )
                      ).toFixed(2)}
                    </p>
                    <p>
                      <span className="font__bold">{t("PO Total")}:</span> $
                      {parseFloat(
                        purchaseOrdersProductDetails.reduce(
                          (accumulator, currentValue) => {
                            return (
                              accumulator +
                              parseInt(
                                currentValue.strEXTWholeSalePrice.replace(
                                  /[$,]/g,
                                  ""
                                ),
                                10
                              )
                            );
                          },
                          0
                        )
                      ).toFixed(2)}
                    </p>
                  </div>
                </div>
                <p>{t("Vendor to provide shipping/insurance info.")}</p>
              </div>
            </div>
          </Spin>
        </div>
      </Modal>
    </div>
  );
};

export default PODashboard;
